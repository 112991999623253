import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import Square from "../../../images/faculties/bau_banner.png"
import { connect } from "react-redux";
import "./news.css"
import NewsSingle from "../news-single/news-single";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { setNewsDetails } from "../../../actions/setactiondetailsection";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from 'react-paginate';


const News = (props) => {

    const [isLoading, setisLoading] = useState(true)
    const [News_s, setNews] = useState(props.newsData.length > 0 ? props.newsData : []);
    const [News2, setNews2] = useState(props.newsData)

    useEffect(() => {
        fetchNews();
    }, [])

    const fetchNews = async () => {
        if (props.newsData.length > 0) {
            setisLoading(false)
        }

        await axios.get(`${serverLink}news/list/active`)
            .then(result => {
                setNews(result.data);
                setNews2(result.data)
                props.setOnNewsDetails(result.data);
                setisLoading(false)
            })
            .catch(e => {
                console.log('NEWS FETCH NETWORK ERROR')
            })
    }

    const handleSearch = (e) => {
        let val = e.target.value.toString().toLowerCase();
        const filtered = News2.length > 0 ?
            News2.filter(x => x.Title.toString().toLowerCase().includes(val) ||
                x.Description.toString().toLowerCase().includes(val))
            : []

        setNews(filtered)
    }

    function PaginatedItems({ itemsPerPage }) {
        const ev = News_s
        const [itemOffset, setItemOffset] = useState(0);

        const endOffset = itemOffset + itemsPerPage;
        const currentItems = ev.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(ev.length / itemsPerPage);

        const handlePageClick = (news) => {
            const newOffset = (news.selected * itemsPerPage) % ev.length;
            setItemOffset(newOffset);
        };

        return (
            <>
                <div className="row col-md-12">
                    <Items currentItems={currentItems} />
                </div>
                <div className="row col-md-12">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        containerClassName="pg_container"
                        pageLinkClassName="pg_page-link"
                        previousLinkClassName="pg_page-link"
                        nextLinkClassName="pg_page-link"
                        activeClassName="pg_active"
                    />
                </div>
            </>
        );
    }

    function Items({ currentItems }) {
        return (
            <>
                {
                    currentItems.length > 0 &&
                    currentItems.map((news, index) => {
                        return <NewsSingle key={index} news={news} />
                    })
                }
            </>
        );
    }


    return <>
        <Banner image={Square} title="Baba Ahmed University News" caption="" />

        {
            isLoading ?
                <div className="container-fluid layout col1">
                    <div className="row">
                        <div className="layout-col col1 layout-12 col-sm-12">
                            <div className="layout-blocks-ucws-text container-fluid roundable block text">
                                <div className="row">
                                    <div className="container">
                                        <div className="row header left">
                                            <div className="col-sm-12">
                                                <h2>
                                                    <div>Loading...</div>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                :
                <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                    <div className="row">
                        <div className="layout-col col1 layout-12 col-sm-12">

                            <div className="layout-blocks-ucws-related-content container-fluid full-width block related-content no-bottom-margin">
                                <div className="row">
                                    <div style={{ padding: '8px', borderRadius: '5px' }}>
                                        <input name="search" placeholder="search news" className="textBox_t" onChange={handleSearch} />
                                    </div>

                                    <PaginatedItems itemsPerPage={10} />
                                    {/* {
                                        News.length > 0 &&
                                        News.map((news, index) => {
                                            return <NewsSingle key={index} news={news} />
                                        })
                                    } */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }

    </>
}

const mapStateToProps = (state) => {
    return {
        newsData: state.BazeNewsDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(News)
