import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import BabaAhmedSquare from "../../../images/faculties/square.jpg";
import HRLine from "../../utils/hrline";
import { Link, NavLink } from "react-router-dom";
import ITRIDVC from "../itridvc";
import Accomplishments from './accomplishments.png'
import { useState } from "react";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { useEffect } from "react";
import { connect } from "react-redux";
import { formatDateAndTime, toTitleCase } from "../../../resources/constants";

const ITRI = (props) => {
    const [isLoading, setIsloading] = useState(true)
    const [Events, setEvents] = useState(props.eventList.length > 0 ? props.eventList : []);
    const [_events, set_Events] = useState([])
    const [Type, setType] = useState('Trainings & Workshops')

    const getRecords = async () => {
        await axios.get(`${serverLink}event/list/active`)
            .then(result => {
                setEvents(result.data);
                setIsloading(false)
            })
            .catch(e => {
                console.log('NEWS FETCH NETWORK ERROR')
            })
    }

    useEffect(() => {
        setShowTrainings(true)
        getRecords();
    }, [""])

    const [showNewsLetter, setShowNewsLetter] = useState(false)
    const [showWebinars, setShowWebinars] = useState(false)
    const [showJournals, setShowJournals] = useState(false)
    const [showTrainings, setShowTrainings] = useState(false);
    const [showCapacity, setShowCapacity] = useState(false);

    const onChangeType = (type) => {
        setType(type)
        setShowNewsLetter(type === "ITRI Newsletter" ? true : false);
        setShowWebinars(type === "Webinar Recordings" ? true : false);
        setShowJournals(type === "Journals" ? true : false);
        setShowTrainings(type === "Trainings & Workshops" ? true : false);
        setShowCapacity(type === "Staff Capacity Development" ? true : false);
    }

    useEffect(() => {
        let trainings = Events.length > 0 ? Events.filter(x =>
            x.Title.toLowerCase().includes("training") ||
            x.Title.toLowerCase().includes("workshop") ||
            x.Title.toLowerCase().includes("seminar") ||
            x.Title.toLowerCase().includes("journals") ||
            x.Title.toLowerCase().includes("webinar") ||
            x.Title.toLowerCase().includes("capacity development") ||
            x.Title.toLowerCase().includes("itr & i") ||
            x.Title.toLowerCase().includes("itri")

        ) : [];
        set_Events(trainings)
    }, [])


    return <>
        <Banner image={require("../images/dvcitri.png")} title="IT, Research and Innovation Office" caption="" disableTitle />

        <div className="container-fluid layout col1" style={{ margin: "20px" }}>
            <div className="row">
                <div className="layout-col col1 layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                        <div className="row">
                            <div className="container text-justify">
                                <div className="row header">
                                    <div className="col-sm-12">
                                        <h2>About Baze ITR & I Office</h2>
                                        {/* <span>The Information Technology, Research, and Innovation (DVC IT, R & I) office was recently created and placed under the stewardship of a newly appointed DVC IT, R & I, Dr. Rislan Abdulazeez Kanya, to move forward Baba Ahmed University's Information Technology, Research, and Innovation (IT, R & I) agenda as the institution progresses into its second decade. The DVC IT, R, & I is responsible for the strategic development, leadership, and management of the IT, Research, and Innovation agenda of Baba Ahmed University. The DVC IT, R & I will also leverage the competitive advantage achieved by Baba Ahmed University through strategic investment in Digital transformation assets. The digital asset at Baba Ahmed University is strategically positioned to actualise the Baze philosophy.</span> */}
                                        <p /><span>The primary mandate of the IT, R & I office will be to adhere to and uphold the Baze philosophy. Since its inception, Baba Ahmed University has successfully enrolled students in various undergraduate and postgraduate degree programs accredited by the National Universities Commission (NUC) and other accreditation or regulatory agencies. Also, over the last ten years, Baze has significantly invested in high-quality infrastructure (buildings, equipment, digital assets, etc.), recruited competent staff at various levels, and rolled out disruptive service delivery. This tri-combination of people, processes, and technologies enabled Baze to deliver significant expectations of its founding members, students, parents, and society.</span>
                                        <p /><span>As Baze progresses into its second decade of excellence and as part of its transformation agenda, the IT, R & I office is ready to exploit all the possibilities of digital transformation beyond building a connected campus for teaching and learning but as an enabler that can make a difference in the research and innovation ecosystem.</span>

                                    </div>
                                </div>

                                <HRLine />

                                <div className="row header">
                                    <div className="col-sm-12 text-justify">
                                        <h2>IT, R & I Office Objectives</h2>
                                        <p>The IT, R & I office is expected to deliver the following major objectives:</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <span
                                                    style={{ fontSize: "60px" }}
                                                    className="icon icomoon ucws-emoticons-robot-line"
                                                    aria-hidden="true" />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="media media-body">
                                                    <h3>Help</h3>
                                                    <p>Help our students and staff create a positive impact on society from the outputs of our teaching, learning, research, innovation, and community service.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <span
                                                    style={{ fontSize: "60px" }}
                                                    className="icon icomoon ucws-business-and-finance-bill-line"
                                                    aria-hidden="true" />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="media media-body">
                                                    <h3>Create</h3>
                                                    <p>Create income for the University to fund research and innovation through grants, donations, and endowments.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <span
                                                    style={{ fontSize: "60px" }}
                                                    className="icon icomoon ucws-food-reservation-line"
                                                    aria-hidden="true" />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="media media-body">
                                                    <h3>Conduct</h3>
                                                    <p>Conduct quality research and development activities that can lead to Academic Entrepreneurship, i.e., patenting, technology licensing, and venture spinoffs, all with the objective of commercialising research and development results.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <span
                                                    style={{ fontSize: "60px" }}
                                                    className="icon icomoon ucws-people-network-line"
                                                    aria-hidden="true" />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="media media-body">
                                                    <h3>Transfer</h3>
                                                    <p>Transfer innovative initiatives to accelerate sustainable development goals (SDGs).</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <HRLine />

                                <div className="row header">
                                    <div className="col-sm-12 text-justify">
                                        <h2>The Baze IT, R & I Office Agenda</h2>
                                        <p>The IT, R & I office agenda will include but not limited to the following key areas:</p>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <span
                                                    style={{ fontSize: "60px" }}
                                                    className="icon icomoon ucws-business-and-finance-cockade-line"
                                                        aria-hidden="true" />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="media media-body">
                                                <h3>Institutional Research:</h3>
                                                    <p>Research is one of the core mandates of any university, and this office will initiate and implement valuable research that will majorly focus on ways to:</p>
                                                    <ul style={{listStyle : "initial"}}>
                                                        <li>Improve quality undergraduate and postgraduate applications and enrolments.</li>
                                                        <li>Reduce dropout rates and improve achievements.</li>
                                                        <li>Improve operational efficiency by evaluating and streamlining business processes.</li>
                                                        <li>Improve quality assurance and control by ensuring necessary checks and validations for procedural activities in the University.</li>
                                                        <li>Improve quality relationships with government, regulators, industries, society, and the environment.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <span
                                                    style={{ fontSize: "60px" }}
                                                    className="icon icomoon ucws-design-and-digital-gradient-line"
                                                        aria-hidden="true"  />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="media media-body">
                                                <h3>Graduate Employability</h3>
                                                    <p>Graduates find it challenging to gain employment due to various existential threats such as global warming and digitalisation; hence, this office will develop a pathway to help our graduates gain a decent and future job.</p>

                                                    <h3>Action Points</h3>
                                                    <p>The main action points to be initiated by the IT, R & I department to implement its agenda and achieve its goals are as follows:</p>
                                                    <ul style={{listStyle : "initial"}}>
                                                        <li>Setup various committees to develop policies.</li>
                                                        <li>Develop measurable KPIs.</li>
                                                        <li>Deploy and operationalize interventions.</li>
                                                        <li>Capacity development.</li>
                                                        <li>Evaluate interventions based on KPIs.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="image-wrapper">
                                            <div>
                                                <picture>
                                                    <img
                                                        src={Accomplishments}
                                                    />
                                                </picture>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ITRIDVC />

                <div className="layout-col col1 layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-banner container-fluid roundable block banner full-width full-bleed-ready graphic">
                        <div className="row" style={{ backgroundColor: "#63656A", color: "#fff", transition: "background-color 500ms ease" }}>
                            <div className="container">
                                <div className="row cta-wrapper">
                                    <div className="cta-content text-center">
                                        <h2>ITR & I Office</h2>
                                        <p>Baba Ahmed University IT, Research & Innovation Office</p>
                                        <div className="btn-wrapper">
                                            <Link to="/itri-office/directorate" className="btn btn-default btn-cta btn-ghost">
                                                Read More
                                            </Link>
                                        </div>
                                        <p>
                                            {""}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="layout-col col1 layout-12 col-md-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <h2>Research and Workshops</h2>
                            <div className="row">
                                <br />
                                <div className="col-md-3">
                                    <div style={{ background: '#f4f4f4', padding: '8px', borderRadius: '5px' }}>
                                        <ul className="tabs">
                                            <NavLink to="#" onClick={() => { onChangeType('Trainings & Workshops') }} >
                                                <li>
                                                    Trainings & Workshops
                                                    <i className="fa fa-angle-right pull-right icons" />
                                                </li>
                                            </NavLink>
                                            <NavLink to="#" onClick={() => { onChangeType('Journals') }} >
                                                <li>
                                                    Journals
                                                    <i className="fa fa-angle-right pull-right icons" />
                                                </li>
                                            </NavLink>
                                            <NavLink to="#" onClick={() => { onChangeType('Staff Capacity Development') }}>
                                                <li>
                                                    Staff Capacity Development
                                                    <i className="fa fa-angle-right pull-right icons" />
                                                </li>
                                            </NavLink>
                                            <NavLink to="#" onClick={() => { onChangeType('Webinar Recordings') }} >
                                                <li>
                                                    Seminar/Webinar Recordings
                                                    <i className="fa fa-angle-right pull-right icons" />
                                                </li>
                                            </NavLink>
                                            <NavLink to="#" style={{ cursor: 'pointer' }} onClick={() => { onChangeType('ITRI Newsletter') }} >
                                                <li>
                                                    ITR & I Newsletter
                                                    <i className="fa fa-angle-right pull-right icons" />
                                                </li>
                                            </NavLink>


                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-9" style={{ paddingTop: '0px' }}>

                                    <div className=" row m-0 search-container" style={{ borderTop: '1px solid black', maxHeight: '490px', overflowY: 'auto' }}>
                                        <div className="row col-md-12">
                                            <span className="bg-primary pull-right">{Type}</span>
                                        </div>

                                        {
                                            showTrainings === true &&
                                            <div>
                                                {
                                                    _events.length > 0 ?
                                                        _events.map((event, i) => {
                                                            return (
                                                                <div className="col-md-12 search-card" key={i}>
                                                                    <h4 className="title ">
                                                                        <Link to={`/event/${event.Slug}`} style={{ backgroundImage: "none" }}>
                                                                            {toTitleCase(event.Title)}
                                                                        </Link>
                                                                    </h4>

                                                                    <p> <i className="fa fa-clock-o" /> {event.StartTime} - {event.EndTime === "NULL" ? parseInt(event.StartTime.split(":")[0]) + 2 + ":00" : event.EndTime}| <i className="fa fa-map-marker" /> {toTitleCase(event.Location)}
                                                                        <div>
                                                                            {formatDateAndTime(event.EventDate, "date")}
                                                                        </div>
                                                                    </p>
                                                                    <br />
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <h5>No Events</h5>
                                                }
                                            </div>
                                        }

                                        {
                                            showCapacity === true &&
                                            <div>
                                                <div className="col-md-12 search-card">
                                                    <h4 className="title ">
                                                        Capacity Development Trainings
                                                    </h4>
                                                    <small className="links">
                                                        <a target={"_blank"} className="links" href={`https://www.youtube.com/@bazeuniversitysocialmedia5022`} >
                                                            Capacity Development Trainings
                                                        </a>
                                                    </small>
                                                    <br />
                                                </div>
                                            </div>
                                        }
                                        {
                                            showJournals === true &&
                                            <div>
                                                <div className="col-md-12 search-card">
                                                    <h4 className="title ">
                                                        Journal of Institutional Research, Big Data Analytics and Innovation
                                                    </h4>
                                                    <small className="links">
                                                        <a target={"_blank"} className="links" href={`https://journal.bazeuniversity.edu.ng/index.php/jirbdai`} >
                                                            Journal of Institutional Research, Big Data Analytics and Innovation (JIRBDAI)
                                                        </a>
                                                    </small>
                                                    <br />
                                                </div>
                                            </div>
                                        }

                                        {
                                            showNewsLetter === true &&
                                            <div>
                                                <div className="col-md-12 search-card">
                                                    <h4 className="title ">
                                                        ITR & I NewsLetter | Vol. 1,  Issue 1
                                                    </h4>
                                                    <small className="links">
                                                        <a target={"_blank"} className="links" href={`https://drive.google.com/drive/folders/1PCV6dxVSz2VPOIaQbw-1l63pRweo3pHH`} >
                                                            ITR & I NewsLetter
                                                        </a>
                                                    </small>
                                                    <br />
                                                </div>
                                                <div className="col-md-12 search-card">
                                                    <h4 className="title ">
                                                        ITR & I NewsLetter | Vol. 1,  Issue 2
                                                    </h4>
                                                    <small className="links">
                                                        <a target={"_blank"} className="links" href={`https://drive.google.com/drive/folders/1eADPwiwY2MEOisT16MXpogrB_jQyJI2f`} >
                                                            ITR & I NewsLetter
                                                        </a>
                                                    </small>
                                                    <br />
                                                </div>
                                                <div className="col-md-12 search-card">
                                                    <h4 className="title ">
                                                        ITR & I NewsLetter | Vol. 3
                                                    </h4>
                                                    <small className="links">
                                                        <a target={"_blank"} className="links" href={`https://drive.google.com/drive/folders/1g61sO-tlLRJc7iXzE9XMSLUnZ7MRRDzU`} >
                                                            ITR & I NewsLetter
                                                        </a>
                                                    </small>
                                                    <br />
                                                </div>
                                            </div>
                                        }
                                        {
                                            showWebinars === true &&
                                            <div className="">
                                                <div className="col-md-12 search-card">
                                                    <h4 className="title ">
                                                        Webinar Recordings
                                                    </h4>
                                                    <small className="links">
                                                        <a target={"_blank"} className="links" href={`https://www.youtube.com/@bazeuniversitysocialmedia5022`} >
                                                            Webinar Recordings
                                                        </a>
                                                    </small>
                                                    <br />
                                                </div>
                                                <div className="col-md-12 search-card">
                                                    <h4 className="title ">
                                                        Second ITR & I Week
                                                    </h4>
                                                    <small className="links">
                                                        <a target={"_blank"} className="links" href={`https://www.youtube.com/shorts/_tRxAvn2bD0`} >
                                                            Click to watch recording
                                                        </a>
                                                    </small>
                                                    <br />
                                                </div>
                                                <div className="col-md-12 search-card">
                                                    <h4 className="title ">
                                                        First ITR & I Week
                                                    </h4>
                                                    <small className="links">
                                                        <a target={"_blank"} className="links" href={`https://www.youtube.com/watch?v=RAus-cTX5jE&list=PLZg2nCHAAcqiuZgqdi-zh6Eu17gnspqEP`} >
                                                            Click to watch recording
                                                        </a>
                                                    </small>
                                                    <br />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </>

}

const mapStateToProps = (state) => {
    return {
        newsList: state.BazeNewsDetails,
        eventList: state.BazeEventDetails
    };
};

export default connect(mapStateToProps, null)(ITRI)
