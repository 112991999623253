import React, { useState } from 'react'
import './utils/style.css'
import "./utils/notice-pop-up.css"
import { useFetcher } from 'react-router-dom'
import { useEffect } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { LawClinicData, gallery_images_data, } from './clinic_data'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Banner from "../utils/banner/banner";
import BabaAhmedSquare from "../../images/faculties/square.jpg";
import { MuiFbPhotoGrid } from "mui-fb-photo-grid";
import Card from '../utils/card/card'
import LawHandBook from './images/Baze Law Clinic HANDBOOK.pdf'
import { Link } from 'react-router-dom';



export default function LawClinicLandingPage() {
    useEffect(() => {
    }, [])

    const data = [
        { name:   <Link to="https://drive.google.com/drive/u/2/folders/1rU5Lkj5zUaAtMLsoYa4yiS-xiuEN7nUx" style={{ color: '#2A2727', textDecoration: 'none' }}>
                    Ethics and Professional Responsibility
            </Link>},
        { name: <Link to="https://drive.google.com/drive/u/2/folders/15ebHTcd_k4orFsDA__uMSMEESEYVfoF9" style={{ color: '#2A2727', textDecoration: 'none' }}>
                    Social Justice and Public Interest
            </Link>
          },
        { name: <Link to="https://drive.google.com/drive/u/2/folders/1GTU5FBjbPwW_yYaU1i_TmA-Fb9uCnGZj" style={{ color: '#2A2727', textDecoration: 'none' }}>
                    Pre-trial detention
            </Link>
        },

    ];
    const data2 = [
        { name:
                <Link to="https://drive.google.com/drive/u/2/folders/1GiWq6-6TJFkYxGYe5XcIkNMcuJx4kXhP" style={{ color: '#2A2727', textDecoration: 'none' }}>
                    Migration and Trafficked Persons
    </Link>
        },
        { name:
                <Link to="https://drive.google.com/drive/u/2/folders/1qgezLDg7vVDilWCX646laqDSmEu6jhD6" style={{ color: '#2A2727', textDecoration: 'none' }}>
                    International Humanitarian
    </Link>
        },
        { name:

                <Link to="https://drive.google.com/drive/u/2/folders/1g5J86A4mUvd_-ve3B0GpiNLgJ44KH9NR" style={{ color: '#2A2727', textDecoration: 'none' }}>
                    Alternative Dispute Resolution
    </Link>
        },
    ];
    let gallery_images = [];
    gallery_images_data.map((x => {
        gallery_images.push(
            {
                title: "Clinic Gallery",
                img: x.images,
                imgThumbnail: x.images
            }
        )
    }))
    const getLawHandBook = () => {
        window.open(LawHandBook, '_blank')
    }
    return (

        <div className="body-wrapper">
            <>
                <Banner image={BabaAhmedSquare} title="Welcome to Baze Law Clinic" caption="" />

                <section className="about" style={{ marginTop: "-110px", marginBottom: "-120px" }}>
                    <div className='col-md-6'>
                        <div className="content-block">
                            <h2>
                                Welcome <span className="alternate">Address</span>
                            </h2>
                            <div className="description-two">
                                <p>
                                    The Clinic is a vehicle for the study of law through the application of theory to practice. Although it is designed to provide a valuable service to the community, its primary objective is educational. These dual aims do not normally result in conflict but should our professional obligations to the client, the court or the profession conflict with our focus on education, our professional obligations will override.
                                </p>
                                <p>
                                    Through ‘doing law’ and reflecting on that ‘doing’ you should have the opportunity to thoroughly research the law in key practice areas. You should see how a real case progress and will begin to develop the skills that are relevant to your legal studies – skills that you will need to appreciate the role of (let alone become) a practicing lawyer. As a result, we believe that this experience will enable you to more critically evaluate the professional, ethical and contextual issues involved in the workings of the law and the justice system.
                                </p>
                                <p>
                                    “Clinical legal education" refers to programs that provide professionals-in-training with practical and skills-oriented instruction under the supervision of a skilled practitioner. The Clinic aids in the study of law by putting it more into practice, as law is concerned and focused more on the theoretical aspect. This theoretical aspect can be well achieved through research as the ‘doing’, which is what the clinic aims at emphasizing.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='row' style={{ marginRight: "20px" }}>
                            <div className="content-block">
                                <h2>
                                    About the <span className="alternate">Law Clinic</span>
                                </h2>
                                <div className="description-two">
                                    <p>
                                        Baze Law Clinic provides pro bono legal services to indigent members of the public. The Clinic delivers a range of services, including free legal advice, referrals and community outreaches and street law. Each unit of the clinic is annexed to a classroom component to consolidate the learning experience in the classroom with practical and meaningful real- world experience throughout their study at the Faculty of Law Baba Ahmed University. Students can also participate in clinic activities for interest.
                                    </p>
                                    <p>
                                        We work collaboratively through partnership with governmental and non-governmental organizations and practitioners help us deliver transformative justice and build the next generation of legal practitioners. Our partners bring on board a wide range of expertise and experience in…. to improve the quality of experiential learning provided through the law clinic. The students primarily provide the services to real clients facing real legal challenges under the supervision of qualified lawyers including law teachers, private and public service legal practitioners and judicial officers.
                                    </p>
                                    <p>
                                        Through participation in clinic activities, students develop knowledge, skills and values. The law clinic expedites the attainment of the goals of legal education at Baba Ahmed University as students gain skills and values not accessible in the traditional classroom setting and are encouraged to think and act like lawyers.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sponsors section " style={{ marginTop: "-160px" }}>
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-12 col-md-12">
                                <div className="section-title">
                                    <h2>
                                        The Clinic Vision, Motto and Mission <span className="alternate"></span>
                                    </h2>
                                </div>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="block text-center">
                                    {/* Sponsors image list */}
                                    <ul className="list-inline sponsors-list">
                                        <li className="list-inline-item">
                                            <div className="image-block text-center">
                                                <h3>Our Vision</h3>
                                                Providing access to justice for all.
                                            </div>
                                        </li>
                                        <li className="list-inline-item">
                                            <div className="image-block text-center">
                                                <h3>Our Motto</h3>
                                                Access to justice for all.

                                            </div>
                                        </li>
                                        <li className="list-inline-item">
                                            <div className="image-block text-center" style={{ width: "100%" }}>
                                                <h3>Our Mission</h3>
                                                We provide legal assistance to people who cannot afford to pay for legal service; Engage in social justice an dpublic interest initiatives; and providing experimental learning oppurtunities to law students for life long commitment to professionalism and ethical standard.
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="layout-col col1 layout-12 col-sm-12" style={{ marginTop: "-40px" }}>
                    <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                        <div className="row">
                            <div className="container text-justify">
                                {/* <HRLine /> */}
                                <div className="row header">
                                    <div className="col-sm-12 text-center text-justify">
                                        <h2 className='text-center'>The Clinic Supervisors</h2>
                                    </div>
                                </div>

                                <div className="row">
                                    {
                                        LawClinicData.length > 0 && LawClinicData.slice(0).map((item, index) => {
                                            return (
                                                <div className="col-md-6" style={{ marginTop: "10px" }} key={index}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel2a-content"
                                                            id="panel2a-header"
                                                        >
                                                            <div className="hr_card_flex_container">
                                                                <img className="img-circle" src={item.image} style={{ width: "80px" }} />
                                                                <span style={{ marginLeft: "20px" }}><h4>{item.name}</h4> <h5>{item.position}</h5> </span>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <p dangerouslySetInnerHTML={{ __html: item.body }} />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            )
                                        })
                                    }

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <section className="about" style={{ marginTop: "-110px" }}>
                    <div className='col-md-12'>
                        <div className='row' style={{ marginRight: "20px" }}>
                            {
                                <section className="sponsors section " style={{ marginTop: "-40px" }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="section-title">
                                                    <h2>
                                                        The Clinic<span className="alternate"> Unit</span>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="block text-center">
                                                    {/* Sponsors image list */}
                                                    <ul className="list-inline sponsors-list">

                                                        {
                                                            data.map((item, index) => {
                                                                return (
                                                                    <li key={index} className="list-inline-item">
                                                                        <div className="image-block text-center">
                                                                            {item.name} <br />
                                                                            Law Clinic
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }

                                                            )
                                                        }



                                                    </ul>
                                                </div>

                                                <div className="block text-center">
                                                    {/* Sponsors image list */}
                                                    <ul className="list-inline sponsors-list">
                                                        {
                                                            data2.map((item, index) => {
                                                                return (
                                                                    <li key={index} className="list-inline-item">
                                                                        <div className="image-block text-center">
                                                                            {item.name} <br />
                                                                            Law Clinic
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }

                                                            )
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            }
                        </div>
                    </div>
                    {/* <div className='col-md-6'>
                        <div className='row' style={{ marginRight: "20px" }}>
                            <div className="content-block">
                                <h2>
                                    About The <span className="alternate">Event</span>
                                </h2>
                                <div className="description-two">
                                    <p>
                                        The Baba Ahmed University International Conference and Exhibition on Sustainable Entrepreneurship and Leadership Development is an event organized in collaboration with the Society for Economic Empowerment and Entrepreneurship Development, scheduled to hold within the Baba Ahmed University Entrepreneurship and Digital Innovation Week. The event is designed to be a brainstorming lab that seeks to redefine the approach to sustainable entrepreneurship and quality leadership development, for broad based productivity across all spheres of human, societal and national endeavours, while ensuring that economic issues and ground realities will be
                                        crafted and reflected in the new way of thinking and doing business. It serves as a forum and platform for advancing discussions and exchange of information on research, development and application to issues related to driving a productive economy, through entrepreneurship and quality leadership.
                                    </p>
                                    <p>
                                        The conference presents a unique forum for academics, professionals, industry experts and policy makers, across diverse  and cultural backgrounds, to hone and discuss contemporary issues redefining the sustainability of entrepreneurship and
                                        quality leadership development, in the face of digital innovations in promoting an inclusive society for sustainable development, in achieving the United Nation’s SDG Global Agenda.
                                    </p>
                                </div>
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <a href="https://bazeuniversity.edu.ng/" target='_blank' className="btn btn-main-md" style={{ color: "white" }}>
                                            <img src={require("./images/logo.png")} width={"30px"} /> &nbsp;
                                            Baba Ahmed University
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="www.seeedev.org" className="btn btn-transparent-md" >
                                            <img src={require("./images/SEED.jpg")} width={"30px"} /> &nbsp;
                                            SEED
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.un.org/en/" target='_blank' className="btn btn-transparent-md">
                                            <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/UN_emblem_blue.svg/1024px-UN_emblem_blue.svg.png' width={"30px"} /> &nbsp;
                                            UN-ESC
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </section>

                <section className="section pricing" style={{ marginTop: "-210px" }}>
                    <div className="container">
                        <div className="content-block">
                            <h2>
                                Focus Areas and Services Provided by the Clinic <span className="alternate"></span>
                            </h2>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="pricing-item">
                                <div className="pricing-heading">
                                    {/* Title */}
                                    <div className="title" >
                                        <h3>Focus Areas </h3>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul className="feature-list m-0 p-0">
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Women’s rights<br />
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Child rights
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Pre-trial detention
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Environmental Rights
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Torture and police brutality
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Human trafficking
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Poverty
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Access to justice
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Social justice issues
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Public interest issues
                                            </p>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            {/* Pricing Item */}
                            <div className="pricing-item">
                                <div className="pricing-heading">
                                    {/* Title */}
                                    <div className="title">
                                        <h3>Services Provided</h3>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    {/* Feature List */}
                                    <ul className="feature-list m-0 p-0">
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Client Counseling and Client referrals<br />
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Drafting legislation
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Submitting amicus curiae briefs to courts
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Conducting legal research
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Public Interest Advocacy and litigation
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Public Interest Advocacy and litigation
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Community Outreaches, Street law
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Human rights education, monitoring and reporting, and
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Court Case Monitoring
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span className="fa fa-check-circle available" />
                                                Freedom of Information requests and Legislative Monitoring
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <hr className="dotted light-grey" />
                                    <h2>The Clinic Gallery</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid layout col2-50-50 ">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="layout-col col2-50 layout-7 col-md-7">
                                    <div className="layout-blocks-ucws-image container-fluid roundable block image">
                                        <div className="row">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="image-wrapper" style={{ width: "100%", borderRadius: '5px', minHeight: '30%', maxHeight: '30%' }}>
                                                            <MuiFbPhotoGrid
                                                                images={gallery_images} // require
                                                                reactModalStyle={{ overlay: { zIndex: 200 } }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <span
                                                style={{ fontSize: "60px" }}
                                                className="icon icomoon ucws-home-and-building-desk-drawer-line"
                                                aria-hidden="true" />
                                        </div>
                                        <div className="col-md-10">
                                            <h3>Address</h3>
                                            <p>Plot 686, Cadastral Zone C 00, <br />
                                                Jabi Airport Road Bypass (Ring Road), <br />
                                                Behind National Judicial Institute,  <br />
                                                ABUJA, FCT, <br />
                                                Nigeria</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-5">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <span
                                                style={{ fontSize: "60px" }}
                                                className="icon icomoon ucws-user-interface-email-83-line"
                                                aria-hidden="true" />
                                        </div>
                                        <div className="col-md-10">
                                            <h3>Contact</h3>
                                            <p>
                                                <i className="fa fa-phone" /> <a style={{ backgroundImage: "none" }} href="tel:09159498598">09159498598</a> <br />
                                                <i className="fa fa-facebook" /> <a style={{ backgroundImage: "none" }} href="facebook.com/BazeLawClinic">@BazeLawClinic</a> <br />
                                                <i className="fa fa-twitter" /> <a style={{ backgroundImage: "none" }} href="twitter.com/BazeLawClinic">@BazeLawClinic</a> <br />
                                                <i className="fa fa-instagram" /> <a style={{ backgroundImage: "none" }} href="instagram.com/bazeuni_lawclinic">@bazeuni_lawclinic</a> <br />
                                                <i className="fa fa-linkedin" /> <a style={{ backgroundImage: "none" }} href="linkedin.com/baze law clinic">BAZE LAW CLINIC ABUJA</a>
                                                <br />
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-5">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <span
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <h3>Resources</h3>
                                            <a href='' onClick={getLawHandBook}> Click here to download Baze Law Clinic Handbook</a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <br /> <br />
            </>

        </div>
    )
}
