import React, { Component } from "react";
import "./homepage.css";
import HomeSlider from "./homepage_components/slider/slider";
import axios from "axios";
import { serverLink } from "../../resources/url";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import NewsSection from "../news/newssection";
import EventSection from "../events/eventsection/eventsection";
import { Link } from "react-router-dom";
import CallToAction from "./homepage_components/call_to_action/calltoaction";
import ProChancellor from "../../images/pc_new.png";
import VCChancellor from "../../images/vc_2_no_bg_1.png";
import PresidentOne from "../../images/presidents/grande.jpg";
import PresidentTwo from "../../images/presidents/philomena.jpg";
import PresidentThree from "../../images/presidents/david.jpg";
import PresidentFour from "../../images/presidents/chimdi.jpg";
import PresidentFive from "../../images/presidents/rabi.jpg";
import PresidentSix from "../../images/presidents/lawsa.jpg";
import BazeAnthem from "../../images/audio/antthem.mp3";
import BazeFocus from "../../images/panorama/focus.jpg";
import BazePanorama from "../../images/panorama/panorama.jpg";
import BazeNews from "../../images/panorama/bazenews.jpg";
import BazeInaugural from "../../images/panorama/inaugural.jpg";

import Engineering1 from "./eng_2.png"
import MGT1 from "./mgt_5.png"
import MGT2 from "./mgt_2.jpeg"
import MSS3 from "./mss_3.png"
import LAW1 from "./law_3.png"
import COMP1 from "./comp_1.png"
import COMP2 from "./comp_2.jpeg"
import FCAC3 from "./fcas_3.png"
import NURS2 from "./nurs_2.jpeg"
import MED2 from "./med_2.jpeg"
import POSTG1 from "./postg_1.png"
import FOUNDATION1 from "./foundation_1.png"
import EnvironmentalLogo from "../../images/faculties/enviromental.jpg";
import * as DOMPurify from 'dompurify';


import ITRINewsletter1 from '../footer/ResearchandInnoationNewsletter.pdf'
import ITRINewsletter2 from '../footer/research_and_innovation_newsletter_2.pdf'
import ITRI_newsletterImage from '../../images/ITRI_newsletter.png'

import {
    setAlumniFeedbackDetails,
    setGalleryDetails,
    setHomepageDetails,
    setManagementListDetails,
    setRunningModules, setStaffListDetails
} from "../../actions/setactiondetailsection";
import { connect } from "react-redux";
import { MuiFbPhotoGrid } from 'mui-fb-photo-grid';
import VirtualTour from "../utils/virtualtour";
import ICEEventPopUp from "../ice-seld-event/notice-pop-up";

class Homepage extends Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.state = {
            isLoading: typeof this.props.homepageData.slider === "undefined",
            boardSlider: ProChancellor,
            boardSliderSelected: 1,
            presidentSlider: PresidentOne,
            presidentSliderSelected: 1,
            //typeof this.props.homepageData.trimesterList !== "undefined" ? this.props.homepageData.trimesterList :
            trimesterList: [],
            triList: [],
            facultyCount: this.props.facultyList.length,
            alumniSlider: this.props.alumniFeedback.length > 0 ? this.props.alumniFeedback[0] : [],
            alumniSliderSelected: 0,
            events: []
        }

    }
    // callPopUp = () => {

    //     if (window.location.href.includes("#")) {
    //         window.location.href = window.location.href + "ice-event"
    //     } else {
    //         window.location.href = window.location.href + "#ice-event";
    //     }
    //     window.sessionStorage.setItem("pop_up", (new Date().getTime() / 1000))
    // }

    componentDidMount() {
        // const last_pop_up = window.sessionStorage.getItem("pop_up")
        // const time_now = (new Date().getTime() / 1000);
        // //shows pop up after 1 hour from last viewed
        // if ((time_now - last_pop_up) > 3600) {
        //     this.callPopUp();
        // }

        this._isMounted = true;
        if (this._isMounted) {
            this.getData();
            setInterval(() => {
                if (this.state.boardSliderSelected === 1) {
                    this.setState({
                        boardSliderSelected: 2,
                        boardSlider: ProChancellor
                    })
                }
                else {
                    this.setState({
                        boardSliderSelected: 1,
                        boardSlider: ProChancellor
                    })
                }

                if (this.state.alumniSliderSelected < this.props.alumniFeedback.length - 1) {
                    this.setState({
                        alumniSliderSelected: this.state.alumniSliderSelected + 1,
                        alumniSlider: this.props.alumniFeedback[this.state.alumniSliderSelected + 1]
                    })
                }
                else {
                    this.setState({
                        alumniSliderSelected: 0,
                        alumniSlider: this.props.alumniFeedback[0]
                    })
                }

                switch (this.state.presidentSliderSelected) {
                    case 1:
                        this.setState({
                            presidentSliderSelected: 2,
                            presidentSlider: PresidentTwo
                        })
                        break;
                    case 2:
                        this.setState({
                            presidentSliderSelected: 3,
                            presidentSlider: PresidentThree
                        })
                        break;
                    case 3:
                        this.setState({
                            presidentSliderSelected: 4,
                            presidentSlider: PresidentFour
                        })
                        break;
                    case 4:
                        this.setState({
                            presidentSliderSelected: 5,
                            presidentSlider: PresidentFive
                        })
                        break;
                    case 5:
                        this.setState({
                            presidentSliderSelected: 6,
                            presidentSlider: PresidentSix
                        })
                        break;
                    case 6:
                        this.setState({
                            presidentSliderSelected: 1,
                            presidentSlider: PresidentOne
                        })
                        break;
                    default:
                        this.setState({
                            presidentSliderSelected: 1,
                            presidentSlider: PresidentOne
                        })
                        break;

                }

            }, 5000);
        }

        axios.get(`${serverLink}resources/academic/calender`)
            .then(data => {
                if (data.data.length > 0) {
                    this.setState({
                        triList: data.data
                    })
                }
            })
            .catch(error => {
                console.log("ERROR FETCHING THE ACADEMIC CALENDER")
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getData() {
        let homepageData = {};
        await axios.get(`${serverLink}slider/homepage`)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    homepageData['slider'] = data
                    this._isMounted && this.props.setOnHomepageDetails(homepageData);
                }
            })
            .catch(err => {
                console.log('NETWORK ERROR FETCHING SLIDERS', err)
            });

        await axios.get(`${serverLink}event/homepage_events`)
            .then(data => {
                homepageData['events'] = data.data;
                this.setState({
                    events: data.data
                })
                this._isMounted && this.props.setOnHomepageDetails(homepageData);
            })
            .catch(error => {
                console.log("ERROR FETCHING THE HOMEPAGE EVENTS")
            });


        await axios.get(`${serverLink}news/homepage_news`)
            .then(data => {
                homepageData['news'] = data.data;
                this._isMounted && this.props.setOnHomepageDetails(homepageData);
            })
            .catch(error => {
                console.log("ERROR FETCHING THE HOMEPAGE NEWS")
            });

        await axios.get(`${serverLink}gallery/list/all`)
            .then(data => {
                this._isMounted && this.props.setOnGalleryDetails(data.data);
            })
            .catch(error => {
                console.log("ERROR FETCHING THE HOMEPAGE NEWS")
            });

        await axios.get(`${serverLink}student/alumni/feedback`)
            .then(data => {
                this._isMounted && this.props.setOnAlumniFeedbackDetails(data.data.map(obj => {
                    return { ...obj, InsertedDate: new Date(obj.InsertedDate) };
                }).sort(
                    (objA, objB) => Number(objB.InsertedDate) - Number(objA.InsertedDate),
                ));
            })
            .catch(error => {
                console.log("ERROR FETCHING THE HOMEPAGE NEWS")
            });

        await axios.get(`${serverLink}general/homepage_student_counter`)
            .then(result => {
                console.log(result.data.StudentCount)
                homepageData['studentCount'] = result.data.StudentCount
                this._isMounted && this.props.setOnHomepageDetails(homepageData);
                this._isMounted && this.setState({ isLoading: false });
            })
            .catch(err => {
                console.log('NETWORK ERROR FETCHING STUDENT COUNT', err)
            });

        await axios.get(`${serverLink}general/admission_dateline`)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    homepageData['trimesterList'] = data
                    this._isMounted && this.props.setOnHomepageDetails(homepageData);
                    this._isMounted && this.setState({ trimesterList: data });
                }
            })
            .catch(err => {
                console.log('NETWORK ERROR FETCHING ADMISSION DATELINE', err)
            });

        await axios.get(`${serverLink}leadership/list/active`)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    this._isMounted && this.props.setOnLeadershipDetails(data);
                }
            })
            .catch(err => {
                console.log('NETWORK ERROR FETCHING ADMISSION DATELINE', err)
            });

        await axios.get(`${serverLink}course/running_modules`)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    this._isMounted && this.props.setOnRunningModules(data)
                }
            })
            .catch(err => {
                console.log('NETWROK ERROR FETCHING RUNNING MODULES', err)
            });

        await axios.get(`${serverLink}staff/website/staff_list`)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    this._isMounted && this.props.setOnStaffListDetails(data)
                }
            })
            .catch(err => {
                console.log('ERROR FETCHING STAFF LIST')
            })
    }


    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            const date_date = user_date.getDate();

            return `${date_date < 10 ? '0' + date_date : date_date} ${monthNames[user_date.getMonth()]}, ${user_date.getFullYear()}`
        } else {
            return '--'
        }
    };

    formatTwoWeekPriorDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            user_date.setDate(user_date.getDate() - 14)
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            const date_date = user_date.getDate();

            return `${date_date < 10 ? '0' + date_date : date_date} ${monthNames[user_date.getMonth()]}, ${user_date.getFullYear()}`
        } else {
            return '--'
        }
    };


    render() {
        let gallery_images = [];

        if (this.props.galleryData.length > 0) {
            this.props.galleryData.map((gallery, index) => {
                if (index < 8) {

                    gallery_images.push(
                        {
                            title: gallery.ImageTitle, // require
                            img: `${serverLink}public/uploads/gallery/${gallery.ImagePath}`, // require
                            imgThumbnail:`${serverLink}public/uploads/gallery/${gallery.ImagePath}`, // optional
                        }
                    )
                }
            })
        }



        return (
            this.state.isLoading ?
                <Loader
                    type="ThreeDots"
                    color="#63656A"
                    height={200}
                    width={200}
                    className="text-center"
                />
                :
                <>
                    {/*Slider*/}
                    <ICEEventPopUp />

                    <HomeSlider sliders={this.props.homepageData.slider ?? []} />

                    {/*Call to action*/}
                    <CallToAction />

                    <div className="container-fluid roundable block" style={{ paddingTop: "20px" }}>
                        <div className="row">
                            <div className="container">
                                <div className="row news-items light-grey-accents">
                                    <div className="col-sm-12 col-md-6 news-item news-items__main">
                                        <div className="row header">
                                            <div className="col-sm-12">
                                                <h2>DISCOVER <span style={{ color: "#8b8b8b" }}>BABA AHMED UNIVERSITY</span></h2>
                                                <span style={{ fontWeight: "normal", fontStyle: "italic", textTransform: "capitalize", fontSize: "2.0rem" }}>Why We Standout</span>
                                            </div>
                                        </div>
                                        <div className="news-item" style={{ marginTop: "10px" }}>
                                            <div className="item-info discover-section">
                                                <p>Baba Ahmed university delivers quality by having experienced International staff, superb teaching equipment, overseas external examiners, and first-rate buildings to guarantee standards.</p>
                                                <p>Baba Ahmed university aims to provide university education to International standards in Nigeria at about half the cost of sending a student to study abroad.</p>

                                                <a href="https://issuu.com/bazeuniversity/docs/baze_university_brochure_2021_edition_" target="_blank" rel="noreferrer" style={{ backgroundImage: "none" }}>
                                                    {/*<button className="primary bordered-dark button block">BROCHURE</button>*/}
                                                </a>
                                                <Link to="/about/about-baze" style={{ marginLeft: "5px", backgroundImage: "none" }}>
                                                    <button className="primary bordered-dark button block">READ MORE</button>
                                                </Link>
                                                {/*<h4 style={{ marginTop: "20px" }}>Baba Ahmed University Anthem</h4>*/}
                                                {/*<audio controls loop style={{ width: "100%" }}>*/}
                                                {/*    <source src={BazeAnthem} type="audio/mp3" />*/}
                                                {/*</audio>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6 news-items__second">
                                        <div className="row">
                                            <div className="col-sm-12 news-item">
                                                <div className="w3-content w3-section" style={{ maxWidth: "500px" }}>
                                                    <img className="mySlides w3-animate-fading2" id="PCSlider"
                                                        src={this.state.boardSlider} alt="" style={{ width: "100%" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row divider link-section-divider">
                                    <div className="col-sm-12">
                                        <hr className="light-grey" />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="container-fluid roundable block" style={{ paddingTop: "20px" }}>
                        <div className="row" >
                            <div className="container" style={{ backgroundColor: 'rgb(220,220,220)', paddingTop: '30px', borderTopLeftRadius: '15px', borderBottomRightRadius: '15px' }}>

                                <div className="row news-items light-grey-accents">
                                    <div className="container-fluid roundable block" >
                                        <div className="text-center" >
                                            <div className="row"  >
                                                <div className="col-xs-6 col-sm-3 sm-margin">
                                                    <div className="">
                                                        <i className="fa fa-group icons_i icon_i_blue" />
                                                    </div>
                                                    <div className="counter">
                                                        <span className="timer icon_i_blue">{this.props.homepageData.studentCount ?? 0}+</span>
                                                    </div>
                                                    <p className="icons_text icon_i_blue">Students &amp; Alumni</p>
                                                </div>
                                                <div className="col-xs-6 col-sm-3 sm-margin">
                                                    <div className="">
                                                        <i className="fa fa-book fa-3x icons_i icon_i_green" />
                                                    </div>
                                                    <div className="counter">
                                                        <span className="timer icon_i_green">100%</span>
                                                    </div>
                                                    <p className="icons_text icon_i_green">Quality Placement</p>
                                                </div>
                                                <div className="col-xs-6 col-sm-3 ">
                                                    <div className="">
                                                        <i className="fa fa-university fa-3x icons_i icon_i_red" />
                                                    </div>
                                                    <div className="counter">
                                                        <span className="timer icon_i_red">100%</span>
                                                    </div>
                                                    <p className="icons_text icon_i_red">International Standard</p>
                                                </div>
                                                <div className="col-xs-6 col-sm-3 ">
                                                    <div className="">
                                                        <i className="fa fa-graduation-cap fa-3x icons_i" />
                                                    </div>
                                                    <div className="counter">
                                                        <span className="timer">{parseInt(this.state.facultyCount)}</span>
                                                    </div>
                                                    <p className="icons_text">Faculties</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <EventSection page="home" events={this.state.events} />

                    {/*Faculties*/}
                    <div className="container-fluid roundable block">
                        <div className="row">
                            <div className="container">
                                <div className="row news-items light-grey-accents">
                                    <div className="col-sm-12 col-md-12 news-item news-items__main">
                                        <div className="row header">
                                            <div className="col-sm-12">
                                                <h2><i className="fa fa-graduation-cap" /> <span style={{ color: "#8b8b8b" }}>Faculties</span></h2>
                                            </div>
                                        </div>
                                        <div className="row divider link-section-divider">
                                            <div className="col-sm-12">
                                                <hr className="light-grey" />
                                            </div>
                                        </div>

                                        <div className="news-item" style={{marginTop: "10px", display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", gap: "20px", }}>
                                            <div className="col-md-4 col-xs-12" style={{ paddingBottom: "20px", textAlign: "center" }}>
                                                <Link to="/faculty/faculty-of-science-and-computing">
                                                    <img className="image-zoom" src={FCAC3} alt="Faculty of Science & Computing" style={{ maxWidth: "100%", height: "auto" }}/>
                                                </Link>
                                            </div>

                                            <div className="col-md-4 col-xs-12" style={{ paddingBottom: "20px", textAlign: "center" }}>
                                                <Link to="/faculty/faculty-of-management-and-social-sciences">
                                                    <img className="image-zoom" src={MSS3} alt="Faculty of Management and Social Sciences" style={{ maxWidth: "100%", height: "auto" }}/>
                                                </Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="row divider link-section-divider">
                                    <div className="col-sm-12">
                                        <hr className="light-grey" />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    {/*Faculties End*/}

                    {/*Academic Calender section*/}
                    <div className="container-fluid roundable block">
                        <div className="row">
                            <div className="container">

                                <div className="row news-items light-grey-accents">
                                    {/*<div className="col-sm-12 col-md-8 news-item news-items__main">*/}
                                    {/*    <div className="row header">*/}
                                    {/*        <div className="col-sm-12">*/}
                                    {/*            <h2>Academic <span style={{ color: "#8b8b8b" }}>Calendar</span></h2>*/}
                                    {/*            <span style={{ fontWeight: "normal", fontStyle: "italic", textTransform: "capitalize", fontSize: "1.5rem" }}>The University Runs 2 Semesters And 1 Summer Session </span>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                        {/*<div className="row divider link-section-divider">*/}
                                        {/*    <div className="col-sm-12">*/}
                                        {/*        <hr className="light-grey" />*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="news-item" style={{ marginTop: "10px" }}>*/}
                                        {/*    <div className="item-info discover-section">*/}
                                        {/*        <div className="row">*/}
                                        {/*            {*/}
                                        {/*                this.state.triList.length > 0 ?*/}
                                        {/*                    this.state.triList.slice(0, 4).map((data, index) => {*/}
                                        {/*                        return (*/}
                                        {/*                            <div key={index} className="col-xs-12 col-md-6"*/}
                                        {/*                                style={{ paddingTop: "10px", paddingBottom: "50px" }}>*/}
                                        {/*                                <div className="card bg-light mb-3">*/}
                                        {/*                                    <div className="card-header">*/}
                                        {/*                                        <i className="fa fa-calendar-check-o" />&nbsp;*/}
                                        {/*                                        {*/}
                                        {/*                                            data.SchoolSemester === "24C" ?*/}
                                        {/*                                                <Link to={`/academics/academic-calender?semester=${data.SchoolSemester}`} style={{ fontFamily: 'arial', color:"white" }} >SEMESTER {data.SchoolSemester}</Link> :*/}
                                        {/*                                                <span style={{ fontFamily: 'arial' }} >SEMESTER {data.SchoolSemester}</span>*/}
                                        {/*                                        }*/}
                                        {/*                                    </div>*/}
                                        {/*                                    <div className="card-body">*/}
                                        {/*                                        <p className="card-text">{data.Description}</p>*/}
                                        {/*                                        <p style={{ fontSize: "14px" }}><strong><i className="fa fa-calendar-minus-o" /> Application Deadline: </strong> {this.formatDate(data.DateLine)}</p>*/}
                                        {/*                                        <p style={{ fontSize: "14px" }}><strong><i className="fa fa-calendar" /> Semester Start Date: </strong> {this.formatDate(data.StartDate)}</p>*/}
                                        {/*                                    </div>*/}
                                        {/*                                </div>*/}
                                        {/*                            </div>*/}
                                        {/*                        )*/}

                                        {/*                    }) : <div className="col-md-12">Fetching academic calender</div>*/}
                                        {/*            }*/}

                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}

                                    {/*//Students Presidents section*/}
                                    {/*<div className="col-sm-12 col-md-4 news-items__second">*/}
                                    {/*    <div className="row header text-center">*/}
                                    {/*        <div className="col-sm-12">*/}
                                    {/*            <h2>Student <span style={{ color: "#8b8b8b" }}>Presidents</span></h2>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="row divider link-section-divider" style={{ paddingTop: "31px" }}>*/}
                                    {/*        <div className="col-sm-12">*/}
                                    {/*            <hr className="light-grey" />*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}

                                    {/*    <div className="row">*/}
                                    {/*        <div className="col-sm-12 news-item">*/}
                                    {/*            <div className="w3-content w3-section" style={{ maxWidth: "500px" }}>*/}
                                    {/*                <img loading="lazy" className="mySlides w3-animate-fading2" id="PCSlider" src={this.state.presidentSlider} alt="" style={{ width: "100%" }} />*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*// End Students Presidents section*/}

                                </div>

                                <div className="row divider link-section-divider">
                                    <div className="col-sm-12">
                                        <hr className="light-grey" />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    {/* End Academic Calender section*/}

                    <NewsSection news={this.props.homepageData.news} />


                    {/*Virtual tour*/}
                    {/* <VirtualTour /> */}
                    {/*Virtual tour*/}

                    {/*<div className="container-fluid roundable block">*/}
                    {/*    <div className="row">*/}
                    {/*        <div className="container">*/}

                    {/*            <div className="row news-items light-grey-accents">*/}
                    {/*                <div className="col-sm-12 col-md-8 news-item news-items__main">*/}
                    {/*                    <div className="row header">*/}
                    {/*                        <div className="col-sm-12">*/}
                    {/*                            <h2>Baba Ahmed University <span style={{ color: "#8b8b8b" }}>in Pictures</span></h2>*/}
                    {/*                            <span style={{ fontWeight: "normal", fontStyle: "italic", textTransform: "capitalize", fontSize: "1.5rem" }}>Some Activities From Our Campus </span>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="row divider link-section-divider">*/}
                    {/*                        <div className="col-sm-12">*/}
                    {/*                            <hr className="light-grey" />*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="news-item" style={{ marginTop: "10px" }}>*/}
                    {/*                        <div className="item-info discover-section">*/}
                    {/*                            <div className="row">*/}
                    {/*                                <MuiFbPhotoGrid*/}
                    {/*                                    images={gallery_images} // require*/}
                    {/*                                    reactModalStyle={{ overlay: { zIndex: 2000 } }}*/}
                    {/*                                />*/}

                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}

                    {/*                <div className="col-sm-12 col-md-4 news-items__second">*/}
                    {/*                    <div className="row header text-center">*/}
                    {/*                        <div className="col-sm-12">*/}
                    {/*                            <h2>Alumni</h2>*/}
                    {/*                            <span style={{ fontWeight: "normal", fontStyle: "italic", textTransform: "capitalize", fontSize: "1.5rem" }}></span>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="row divider link-section-divider">*/}
                    {/*                        <div className="col-sm-12">*/}
                    {/*                            <hr className="light-grey" />*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}

                    {/*                    <div className="row">*/}
                    {/*                        <div className="col-sm-12 news-item">*/}
                    {/*                            <div className="w3-content w3-section" style={{ maxWidth: "500px" }}>*/}
                    {/*                                {*/}
                    {/*                                    this.state.alumniSlider !== undefined &&*/}
                    {/*                                    <div>*/}
                    {/*                                        <img loading="lazy" className="mySlides w3-animate-fading2" id="PCSlider"*/}
                    {/*                                            src={this.state.alumniSlider.ImagePath !== undefined &&*/}
                    {/*                                                this.state.alumniSlider.ImagePath.includes("simplefileupload") ? this.state.alumniSlider.ImagePath : `${serverLink}public/uploads/alumni/${this.state.alumniSlider.ImagePath}`}*/}
                    {/*                                            alt=""*/}
                    {/*                                            style={{ width: "100%", borderRadius: '5px' }}*/}
                    {/*                                        />*/}
                    {/*                                        <h4 className="text-center"><i>{this.state.alumniSlider.Title}</i></h4>*/}
                    {/*                                        <blockquote dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${this.state.alumniSlider.Description} <br/> - <b> ${this.state.alumniSlider.StudentName}</b>`) }} style={{ fontSize: 13 }} />*/}
                    {/*                                    </div>*/}
                    {/*                                }*/}

                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="row divider link-section-divider">*/}
                    {/*                <div className="col-sm-12">*/}
                    {/*                    <hr className="light-grey" />*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*Baze Panorama */}
                    <div className="container-fluid roundable block">
                        <div className="row">
                            <div className="container">

                                <div className="row news-items light-grey-accents">
                                    {/*<div className="col-sm-12 col-md-12 news-item news-items__main">*/}
                                    {/*    <div className="row header">*/}
                                    {/*        <div className="col-sm-12">*/}
                                    {/*            <h2>Resources <span style={{ color: "#8b8b8b" }}> </span></h2>*/}
                                    {/*            <span style={{ fontWeight: "normal", fontStyle: "italic", textTransform: "capitalize", fontSize: "1.5rem" }}>Latest Happenings Around Campus </span>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="row divider link-section-divider">*/}
                                    {/*        <div className="col-sm-12">*/}
                                    {/*            <hr className="light-grey" />*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="news-item" style={{ marginTop: "10px" }}>*/}
                                    {/*        <div className="item-info discover-section">*/}
                                    {/*            <div className="row">*/}
                                    {/*                <div className="col-md-1 col-xs-12" style={{ paddingBottom: "20px" }}>*/}

                                    {/*                </div>*/}
                                    {/*                <div className="col-md-2 col-xs-12" style={{ paddingBottom: "20px" }}>*/}
                                    {/*                    <a href="https://issuu.com/bazeuniversity/docs/baze_focus_2018" target="_blank">*/}
                                    {/*                        <img className="img-thumbnail" src={BazeFocus} alt="Baba Ahmed University Focus" />*/}
                                    {/*                        <span style={{ position: 'absolute', bottom: '55px', right: '2px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', fontWeight: 'bold' }}>Focus</span>*/}
                                    {/*                    </a>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="col-md-2 col-xs-12" style={{ paddingBottom: "20px" }}>*/}
                                    {/*                    <a href="https://issuu.com/bazeuniversity" target="_blank">*/}
                                    {/*                        <img className="img-thumbnail" src={BazePanorama} alt="Baba Ahmed University Panorama" />*/}
                                    {/*                        <span style={{ position: 'absolute', bottom: '55px', right: '2px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', fontWeight: 'bold' }}>Panaroma</span>*/}
                                    {/*                    </a>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="col-md-2 col-xs-12" style={{ paddingBottom: "20px" }}>*/}
                                    {/*                    <a href="https://issuu.com/bazeuniversity" target={"_blank"}>*/}
                                    {/*                        <img className="img-thumbnail" src={BazeNews} alt="Baba Ahmed University News" />*/}
                                    {/*                        <span style={{ position: 'absolute', bottom: '55px', right: '2px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', fontWeight: 'bold' }}>News</span>*/}
                                    {/*                    </a>*/}

                                    {/*                </div>*/}
                                    {/*                <div className="col-md-2 col-xs-12" style={{ paddingBottom: "20px" }}>*/}
                                    {/*                    <a href="https://issuu.com/bazeuniversity" target="_blank">*/}
                                    {/*                        <img className="img-thumbnail" src={BazeInaugural} alt="Baba Ahmed University Inaugural" />*/}
                                    {/*                        <span style={{ position: 'absolute', bottom: '55px', right: '2px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', fontWeight: 'bold' }}>Inaugural Lecture</span>*/}
                                    {/*                    </a>*/}
                                    {/*                </div>*/}

                                    {/*                <div className="col-md-1 col-xs-12" style={{ paddingBottom: "20px" }}>*/}

                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="row">*/}
                                    {/*                <div className="col-md-4 col-xs-12" style={{ paddingBottom: "20px" }}>*/}

                                    {/*                </div>*/}

                                    {/*                /!* <div className="col-md-4 col-xs-12"  style={{ paddingBottom: "20px" }}>*/}
                                    {/*                    <a href={`https://drive.google.com/drive/folders/1g61sO-tlLRJc7iXzE9XMSLUnZ7MRRDzU`} target="_blank">*/}
                                    {/*                        <img className="img-thumbnail" src={ITRI_newsletterImage} alt="Baba Ahmed University Inaugural" width={'300px'} />*/}
                                    {/*                        <span style={{ position: 'absolute', bottom: '55px', right: '2px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', fontWeight: 'bold' }}>1st ITRI Newsletter</span>*/}
                                    {/*                    </a>*/}
                                    {/*                </div> *!/*/}
                                    {/*                /!* <div className="col-md-4 col-xs-12" style={{ paddingBottom: "20px" }}>*/}
                                    {/*                    <a href={ITRINewsletter2} target="_blank">*/}
                                    {/*                        <img className="img-thumbnail" src={ITRI_newsletterImage} alt="Baba Ahmed University Inaugural" />*/}
                                    {/*                        <span style={{ position: 'absolute', bottom: '55px', right: '2px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', fontWeight: 'bold' }}>2nd ITRI Newsletter</span>*/}

                                    {/*                    </a>*/}
                                    {/*                </div> *!/*/}
                                    {/*                <div className="col-md-4 col-xs-12" style={{ paddingBottom: "20px" }}>*/}

                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>

                                <div className="row divider link-section-divider">
                                    <div className="col-sm-12">
                                        <hr className="light-grey" />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    {/*Baze Panorama */}


                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        facultyList: state.BazeFacultyListDetails,
        homepageData: state.BazeHomepageDetails,
        galleryData: state.BazeGalleryDetails,
        alumniFeedback: state.BazeAlumniFeedbackDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHomepageDetails: (p) => {
            dispatch(setHomepageDetails(p));
        },
        setOnRunningModules: (p) => {
            dispatch(setRunningModules(p))
        },
        setOnGalleryDetails: (p) => {
            dispatch(setGalleryDetails(p));
        },
        setOnAlumniFeedbackDetails: (p) => {
            dispatch(setAlumniFeedbackDetails(p));
        },
        setOnLeadershipDetails: (p) => {
            dispatch(setManagementListDetails(p));
        },
        setOnStaffListDetails: (p) => {
            dispatch(setStaffListDetails(p));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
