import React, { Component } from "react";
import { connect } from "react-redux";
import "./factandfigure.css";
import Square from "../../../images/faculties/square.jpg";
import Research from "../../../images/general/research.jpg";
import Banner from "../../utils/banner/banner";
import Card from "../../utils/card/card";
import { setGalleryDetails, setHomepageDetails } from "../../../actions/setactiondetailsection";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { MuiFbPhotoGrid } from "mui-fb-photo-grid";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";

class FactAndFigure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Counts: [],
            loading: true
        }
    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        let homepageData = {};
        await axios.get(`${serverLink}general/about_us_counts`)
            .then(result => {
                console.log(result)
                this.setState({ Counts: [result.data] })
            })
            .catch(err => {
                console.log('NETWORK ERROR FETCHING STUDENT COUNT', err)
            });

        await axios.get(`${serverLink}general/homepage_student_counter`)
            .then(result => {
                homepageData['studentCount'] = result.data.StudentCount
                this.props.setOnHomepageDetails(homepageData);
            })
            .catch(err => {
                console.log('NETWORK ERROR FETCHING STUDENT COUNT', err)
            });

        await axios.get(`${serverLink}gallery/list/all`)
            .then(data => {
                this.props.setOnGalleryDetails(data.data);
                this.setState({ loading: false })
            })
            .catch(error => {
                console.log("ERROR FETCHING THE HOMEPAGE NEWS")
            });

    }
    render() {
        const student_count = typeof this.props.homepageData.studentCount !== "undefined" ? this.props.homepageData.studentCount : 0;

        let gallery_images = [];

        if (this.props.galleryData.length > 0) {
            this.props.galleryData.map((gallery, index) => {
                gallery_images.push(
                    {
                        title: gallery.ImageTitle, // require
                        img: gallery.ImagePath !== undefined ? gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath : `${serverLink}public/uploads/gallery/${gallery.ImagePath}` : '', // require
                        imgThumbnail: gallery.ImagePath !== undefined ? gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath : `${serverLink}public/uploads/gallery/${gallery.ImagePath}` : '', // optional
                    }
                )
            })
        }

        return this.state.Counts.length === 0 ? (
            <Loader
                type="ThreeDots"
                color="#63656A"
                height={200}
                width={200}
                className="text-center"
            />
        ) : <>
            <Banner image={require("./FACTS AND FIGURES.png")} title="Facts and Figures" caption="Baba Ahmed University in Numbers" />

            <div className="layout-col layout-12 col-sm-12">
                <div className="layout-blocks-ucws-text container-fluid roundable block text">
                    <div className="row" style={{ paddingTop: '30px' }}>
                        <div className="container">
                            <div className="row header left">
                                <div className="col-sm-12">
                                    <h2>
                                    Our University
                                    </h2>
                                </div>
                            </div>
                            <div className="row left">
                                <div className="col-sm-12 one-col block_quate">
                                    <p>Baba Ahmed University delivers quality through experienced international staff, superb teaching equipment, overseas external examiners, and first-rate buildings to guarantee standards. Baba Ahmed University aims to provide university education to British standards in Nigeria at about half the cost of sending a student to study abroad</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 min-height-fix">
                                <div className="rounded-box grey-back" style={{ minHeight: 0 }}>
                                    <Card image="fa fa-calendar" icon={true} title="Founded in 2011" description="" button="" btn_link="" />
                                    <Card image="fa fa-building" icon={true} title={`${parseInt(this.props.facultyList.length) - 2} Faculties`} description="" button="" btn_link="" />
                                    <Card image="fa-solid fa-users" icon={true} title={`${student_count}+ Students`} description="" button="" btn_link="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <hr className="dotted light-grey" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid layout col2-50-50">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="layout-col col2-50 layout-6 col-md-6">
                                <div className="layout-blocks-ucws-image container-fluid roundable block image">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="image-wrapper">
                                                        <MuiFbPhotoGrid
                                                            images={gallery_images} // require
                                                            reactModalStyle={{ overlay: { zIndex: 2000 } }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="layout-col col2-50 layout-6 col-md-6">
                                <div className="layout-blocks-ucws-text container-fluid roundable block text">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row left">
                                                <div className="col-sm-12 one-col">
                                                    {/* <h4><strong>Mo shùile togam suas</strong></h4>

                                                    <p>Univeristy motto (in Gaelic) –&nbsp;<em>I will lift up my
                                                        eyes</em></p>

                                                    <p>&nbsp;</p> */}

                                                    <h4><strong> {this.state.Counts.length > 0 ?
                                                        this.state.Counts[0].academic.length : 0}+ &nbsp;
                                                        Academic Staff</strong></h4>

                                                    <p>actively engaged in research, scholarship and teaching</p>

                                                    <p>&nbsp;</p>

                                                    <h4><strong>{this.state.Counts.length > 0 ?
                                                        this.state.Counts[0].nonacademic.length : 0}+ Non-Academic Staff</strong></h4>

                                                    <p>focused on operating the institution, administration and delivering to meet our core mandate</p>

                                                    <p>&nbsp;</p>

                                                    <h4>{this.state.Counts.length > 0 ?
                                                        this.state.Counts[0].alumni.length : 0}+ alumni</h4>

                                                    <p>Live all around the globe and work in highly reputable organizations</p>

                                                    <p>&nbsp;</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <hr className="dotted light-grey" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid layout col1">
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">

                        <div className="layout-blocks-ucws-text container-fluid roundable block text">
                            <div className="row">
                                <div className="container">
                                    <div className="row header left">
                                        <div className="col-sm-12">
                                            <h2>
                                            Research and Scholarship
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="container-fluid layout col2-50-50">
                <div className="row">
                    <div className="container">
                        <div className="row">


                            <div className="layout-col col2-50 layout-6 col-md-6">


                                <div className="layout-blocks-ucws-text container-fluid roundable block text">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row left">
                                                <div className="col-sm-12 one-col">
                                                    <p><strong>Our research addresses six focal areas in the areas of the global atmosphere:</strong>
                                                    </p>

                                                    <ul style={{listStyle : "initial"}}>
                                                        <li>Energy innovations for today and tomorrow</li>
                                                        <li>Human dynamics in a changing world</li>
                                                        <li>Engineering solutions for health</li>
                                                        <li>New Earth-space technologies</li>
                                                        <li>Infections, inflammation and chronic diseases</li>
                                                        <li>Brain and mental health</li>
                                                    </ul>

                                                    <p>&nbsp;</p>

                                                    <p>&nbsp;</p>

                                                    <p>
                                                        <Link to="/itri-office">
                                                            Learn more about our research
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="layout-col col2-50 layout-6 col-md-6">


                                <div className="layout-blocks-ucws-image container-fluid roundable block image">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="image-wrapper">
                                                        <div>
                                                            <img className="img-responsive"
                                                                loading="lazy"
                                                                src={Research}
                                                                style={{ height: '800px' }}
                                                                typeof="foaf:Image" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        facultyList: state.BazeFacultyListDetails,
        homepageData: state.BazeHomepageDetails,
        galleryData: state.BazeGalleryDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHomepageDetails: (p) => {
            dispatch(setHomepageDetails(p));
        },
        setOnGalleryDetails: (p) => {
            dispatch(setGalleryDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FactAndFigure);

