import React from 'react'
import './utils/style.css'
import ICEEventPopUp from './notice-pop-up'
import "./utils/notice-pop-up.css"
import { useFetcher } from 'react-router-dom'
import { useEffect } from 'react'

export default function ICELandingPage() {
    useEffect(() => {
        // document.getElementsByClassName("pop_up_button").click()
    }, [])

    const callItem = () => {
        window.location.href = window.location.href + "#ice-event"
    }
    return (
        <div className="body-wrapper">
            <>

                <section className="banner bg-banner-one overlay-dark-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* Content Block */}
                                <div className="block">
                                    {/* Coundown Timer */}
                                    <div className="timer" />
                                    <h1>International Conference and Exhibition On Sustainable Entrepreneurship And Leadership Development (ICE-SELD)</h1>
                                    <h2>Baze Univrsity 2023</h2>
                                    <h6>Tuesday 13th – Friday 16th June, 2023</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ICEEventPopUp />

                <section className="section about" style={{ marginTop: "-110px" }}>
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-12 col-md-12 align-self-center">
                                <div className="content-block">
                                    <h2>
                                        Theme <span className="alternate">:</span>
                                    </h2>
                                    <div className="description-one-theme">
                                        <p>
                                            Redefining Entrepreneurship and Quality Leadership for a Productive Economy
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about" style={{ marginTop: "-200px" }}>
                        <div className='col-md-6'>
                            <div className="image-block">
                                <img
                                    src={require(`./images/organizers.jpg`)}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row' style={{ marginRight: "20px" }}>
                                <div className="content-block">
                                    <h2>
                                        About The <span className="alternate">Event</span>
                                    </h2>
                                    <div className="description-two">
                                        <p>
                                            The Baba Ahmed University International Conference and Exhibition on Sustainable Entrepreneurship and Leadership Development is an event organized in collaboration with the Society for Economic Empowerment and Entrepreneurship Development, scheduled to hold within the Baba Ahmed University Entrepreneurship and Digital Innovation Week. The event is designed to be a brainstorming lab that seeks to redefine the approach to sustainable entrepreneurship and quality leadership development, for broad based productivity across all spheres of human, societal and national endeavours, while ensuring that economic issues and ground realities will be
                                            crafted and reflected in the new way of thinking and doing business. It serves as a forum and platform for advancing discussions and exchange of information on research, development and application to issues related to driving a productive economy, through entrepreneurship and quality leadership.
                                        </p>
                                        <p>
                                            The conference presents a unique forum for academics, professionals, industry experts and policy makers, across diverse  and cultural backgrounds, to hone and discuss contemporary issues redefining the sustainability of entrepreneurship and
                                            quality leadership development, in the face of digital innovations in promoting an inclusive society for sustainable development, in achieving the United Nation’s SDG Global Agenda.
                                        </p>
                                    </div>
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <a href="https://bazeuniversity.edu.ng/" target='_blank' className="btn btn-main-md" style={{ color: "white" }}>
                                                <img src={require("./images/logo.png")} width={"30px"} /> &nbsp;
                                                Baba Ahmed University
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="www.seeedev.org" className="btn btn-transparent-md" >
                                                <img src={require("./images/SEED.jpg")} width={"30px"} /> &nbsp;
                                                SEED
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="https://www.un.org/en/" target='_blank' className="btn btn-transparent-md">
                                                <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/UN_emblem_blue.svg/1024px-UN_emblem_blue.svg.png' width={"30px"} /> &nbsp;
                                                UN-ESC
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                </section>


                {/* <section className="section about" style={{ marginTop: "-200px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 align-self-center">
                                <div className="content-block">
                                    <h2>
                                        About The <span className="alternate">Event</span>
                                    </h2>
                                    <div className="description-two">
                                        <p>
                                            The Baba Ahmed University International Conference and Exhibition on Sustainable Entrepreneurship and Leadership Development is an event organized in collaboration with the Society for Economic Empowerment and Entrepreneurship Development, scheduled to hold within the Baba Ahmed University Entrepreneurship and Digital Innovation Week. The event is designed to be a brainstorming lab that seeks to redefine the approach to sustainable entrepreneurship and quality leadership development, for broad based productivity across all spheres of human, societal and national endeavours, while ensuring that economic issues and ground realities will be
                                            crafted and reflected in the new way of thinking and doing business. It serves as a forum and platform for advancing discussions and exchange of information on research, development and application to issues related to driving a productive economy, through entrepreneurship and quality leadership.
                                        </p>
                                        <p>
                                            The conference presents a unique forum for academics, professionals, industry experts and policy makers, across diverse  and cultural backgrounds, to hone and discuss contemporary issues redefining the sustainability of entrepreneurship and
                                            quality leadership development, in the face of digital innovations in promoting an inclusive society for sustainable development, in achieving the United Nation’s SDG Global Agenda.
                                        </p>
                                    </div>
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <a href="https://bazeuniversity.edu.ng/" target='_blank' className="btn btn-main-md" style={{ color: "white" }}>
                                                <img src='https://bazeuniversity.edu.ng/static/media/logo.385bef27.png' width={"30px"} /> &nbsp;
                                                Baba Ahmed University
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="#" className="btn btn-transparent-md" >
                                                <img src={require("./images/SEED.jpg")} width={"30px"} /> &nbsp;
                                                SEED
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="https://www.un.org/en/" target='_blank' className="btn btn-transparent-md">
                                                <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/UN_emblem_blue.svg/1024px-UN_emblem_blue.svg.png' width={"30px"} /> &nbsp;
                                                UN-ESC
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="section about" style={{ marginTop: "-200px" }}>
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-12 col-md-12 align-self-center">
                                <div className="content-block">
                                    <h2>
                                        Sub-themes <span className=""></span>
                                    </h2>
                                    <div className="description-one" >
                                        <p>
                                            Articles for presentation at the conference are invited from various disciplines,
                                            addressing entrepreneurship and leadership development across board, theoretically
                                            or empirically, through a descriptive or critical approach, on any of the following sub-
                                            themes:
                                        </p>
                                    </div>
                                    <div className="image-block">
                                        <img

                                            src={require(`./images/tracks.png`)}
                                            alt=""
                                        />
                                    </div>
                                    <div className="description-one" style={{ backgroundColor: "#0d5a5e", color: "white", padding: "10px" }}>
                                        <p style={{ color: "white" }}>
                                            In addition to the above listed sub-themes, papers advancing entrepreneurship and
                                            leadership in other disciplines will be accommodated to advance diverse perspectives
                                            to the central discourse.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="section speakers bg-speaker overlay-lighter">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Section Title */}
                                <div className="section-title white">
                                    <h3>
                                        Who is <span className="alternate">Speaking?</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                {/* Speaker 1 */}
                                <div className="speaker-item">
                                    <div className="image">
                                        <img
                                            src={require(`./images/adetokumbo-1.jpg`)}
                                            alt="speaker"
                                            style={{width:"100%"}}
                                            className="img-fluid"
                                        />
                                        <div className="primary-overlay" />
                                    </div>
                                    <div className="content text-center">
                                        <h5>
                                            <a href="#">Prince Adetokunbo A. K	(CON) </a>
                                        </h5>
                                        <p>Keynote Speaker</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                {/* Speaker 2 */}
                                <div className="speaker-item">
                                    <div className="image">
                                        <img
                                            src={require(`./images/dandogo.jpg`)}
                                            alt="speaker"
                                            className="img-fluid"
                                            style={{width:"100%", }}
                                        />
                                        <div className="primary-overlay" />
                                    </div>
                                    <div className="content text-center">
                                        <h5>
                                            <a href="#">Prof. Kabiru I. Dandago</a>
                                        </h5>
                                        <p>Lead Paper Presenter</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                {/* Speaker 2 */}
                                <div className="speaker-item">
                                    <div className="image">
                                        <img
                                        style={{width:"100%", height : "270px"}}
                                            src={require(`./images/aruwa.png`)}
                                            alt="speaker"
                                            className="img-fluid"
                                        />
                                        <div className="primary-overlay" />
                                    </div>
                                    <div className="content text-center">
                                        <h5>
                                            <a href="#">Prof. Suleiman Aruwa</a>
                                        </h5>
                                        <p>Lead Paper Presenter</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                {/* Speaker 2 */}
                                <div className="speaker-item">
                                    <div className="image">
                                        <img
                                            src={require(`./images/osagwu.jpg`)}
                                            alt="speaker"
                                            className="img-fluid"
                                            style={{width:"100%",  height : "270px"}}
                                        />
                                        <div className="primary-overlay" />
                                    </div>
                                    <div className="content text-center">
                                        <h5>
                                            <a href="#">Prof. Linus Osuagwu</a>
                                        </h5>
                                        <p>Lead Paper Presenter</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="section pricing" style={{ marginTop: "0px" }}>
                    <div className="container">
                        <div className="content-block">
                            <h2>
                                Registration and Submission of Papers <span className="alternate"></span>
                            </h2>
                        </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="pricing-item">
                                    <div className="pricing-heading">
                                        {/* Title */}
                                        <div className="title" >
                                            <h3>Registration</h3>
                                        </div>
                                    </div>
                                    <div className="pricing-body-2">
                                        <p className='text-justify'>
                                            All participants and paper presenters must be registered to participate in the
                                            conference or exhibitions.
                                            Papers for presentation at the conference must be submitted on or before 5 th June,
                                            2023.
                                            Papers should be submitted as an attachment, with evidence of payment to:<br />
                                            <strong> <a href='mailto: entshp.conference@bazeuniversity.edu.ng'>entshp.conference@bazeuniversity.edu.ng</a></strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6">
                                {/* Pricing Item */}
                                <div className="pricing-item">
                                    <div className="pricing-heading">
                                        {/* Title */}
                                        <div className="title">
                                            <h3>Publication Opportunities</h3>
                                        </div>
                                        {/* Price */}
                                        <div className="price">
                                            <p>High quality papers accepted and presented during the conference will be considered
                                                for publication in a special issue of any of the following Journals, subject to payment of
                                                review and publication fees. Reviewed and accepted papers will be published in any of
                                                the following entrepreneurship, management and interdisciplinary journals:</p>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        {/* Feature List */}
                                        <ul className="feature-list m-0 p-0">
                                            <li>
                                                <p>
                                                    <span className="fa fa-check-circle available" />
                                                    Baba Ahmed University Journal of Interdisciplinary Studies: <br />
                                                    <strong>ISSN 2971 - 7124 (BUJEIS-Special Edition) (Google Indexed).</strong>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="fa fa-check-circle available" />
                                                    Baba Ahmed University Journal of Management Sciences &nbsp;<strong>(BUJMASS)</strong>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="fa fa-check-circle available" />
                                                    Journal of Economic Research and Entrepreneurship Development (JERED). <strong>ISSN: 2449-0962</strong>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="fa fa-check-circle available" />
                                                    Journal of African Enterprise and Finance Development (JAEFD). <strong>ISSN: 2714-3481</strong>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="fa fa-check-circle available" />
                                                    International Journal of Leadership and Management Review (IJLMR). <strong>ISSN: 2714-3465</strong>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="fa fa-check-circle available" />
                                                    International Journal of Economic Resources and Sustainable Development
                                                    (IJERSD) <strong>ISSN:2714-3473</strong>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12'>
                                <div className="pricing-item">
                                    <div className="pricing-heading">
                                        {/* Title */}
                                        <div className="title" >
                                            <h3>For Whom</h3>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <p className='text-justify'>
                                            The BAZE-SEEED ICEESED 2023 is for all Academics, Entrepreneurs, Captains of
                                            Industries, NGO, BMOs, Management Practitioners, Financial Experts and Institutions,
                                            Security Agencies, Policy Makers, International Organisations, Investors, Consultants,
                                            etc.
                                        </p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </section>

                <section className="about" style={{ marginTop: "-150px" }}>
                        <div className='col-md-8'>
                            <div className="image-block" style={{ justifyContent: "center" }}>
                                <img src={require("./images/registration.jpg")} />
                            </div>
                            <div className="table">
                                <table className='event-table' cellSpacing={0} style={{ marginLeft: "10px", paddingLeft: "20px" }}>
                                    <tbody>
                                        <tr>
                                            <th colSpan={4}>
                                                <strong><h4>Please Note :</h4></strong>
                                            </th>
                                        </tr>
                                        <tr style={{ backgroundColor: "#0d5a5e" }}>
                                            <th >
                                                i.  Fee covers conference materials, tea break, lunch, conference proceedings and certificate of participation<br />
                                                ii.  Payment at the event day attract 10% surcharge/extra fee, <br />
                                                iii. LDC = Least Developed Countries/African Countries; DC =Developed Countries; National = Nigerians
                                            </th>
                                        </tr>
                                        <tr style={{ backgroundColor: "#0d5a5e" }}>
                                            <th >
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <strong><h4>Payments (Option A)</h4> All payments in respect of the conference and exhibition should be made to:</strong>
                                                        <div className='card' style={{ backgroundColor: "#0d5a5e", color: "white", padding: "10px" }}>
                                                            Account Name: 	Baba Ahmed University Entrepreneurship Club<br />
                                                            Account Number:	1016679309 <br />
                                                            Bank Name:		Zenith Bank

                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <strong><h4>Payments (Option B)</h4> For Foreign Participants (Domiciliary Account):</strong>
                                                        <div className='card' style={{ backgroundColor: "#0d5a5e", color: "white", padding: "10px" }}>
                                                            Account Name: 	Baba Ahmed University Entrepreneurship Club <br />
                                                            Account Number:	5074062909<br />
                                                            Bank Name:		Zenith Bank


                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="content-block">
                                <h2>
                                    Registration and Participation Fee <span className=""></span>
                                </h2>
                            </div>
                            <div style={{maxWidth : "100%", overflowX : "auto"}}>
                                <table className='event-table' cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <th style={{ width: "150px" }}></th>
                                            <th colSpan={2}>On Site</th>
                                            <th colSpan={2}>Virtual</th>
                                        </tr>
                                        <tr style={{ backgroundColor: "#0d5a5e" }}>
                                            <th style={{ backgroundColor: "#0d5a5e", color: "white" }}></th>
                                            <th style={{ backgroundColor: "#0d5a5e", color: "white" }}>Early Bird 5th June, 2023</th>
                                            <th style={{ backgroundColor: "#0d5a5e", color: "white" }}>Normal </th>
                                            <th style={{ backgroundColor: "#0d5a5e", color: "white" }}>Early Bird 5th June, 2023</th>
                                            <th style={{ backgroundColor: "#0d5a5e", color: "white" }}>Normal</th>

                                        </tr>

                                        <tr>
                                            <th colSpan={5} style={{ textAlign: "center", backgroundColor: "white", fontWeight: "bolder" }}>Academic (Individual)</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                National
                                            </td>
                                            <td>N36,000</td>
                                            <td>N40,000</td>
                                            <td>N20,000</td>
                                            <td>N25,000 </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Africa/LDC
                                            </td>
                                            <td>USD 50</td>
                                            <td>USD 150</td>
                                            <td>USD 50</td>
                                            <td>USD 100</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                DC
                                            </td>
                                            <td>USD 150</td>
                                            <td>USD 200</td>
                                            <td>USD 100</td>
                                            <td>USD 150</td>
                                        </tr>

                                        <tr>
                                            <th colSpan={5} style={{ textAlign: "center", backgroundColor: "white", fontWeight: "bolder" }}>Organization/Institution</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                National
                                            </td>
                                            <td>N300,000</td>
                                            <td>N350,000</td>
                                            <td>N200,000</td>
                                            <td>N250,000 </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Africa/LDC
                                            </td>
                                            <td>USD 500</td>
                                            <td>USD 600</td>
                                            <td>USD 350</td>
                                            <td>USD 400</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                DC
                                            </td>
                                            <td>USD 150</td>
                                            <td>USD 200</td>
                                            <td>USD 100</td>
                                            <td>USD 150</td>
                                        </tr>

                                        <tr>
                                            <th colSpan={5} style={{ textAlign: "center", backgroundColor: "white", fontWeight: "bolder" }}>NGO/BMO</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                National
                                            </td>
                                            <td>N20,000</td>
                                            <td>N25,000</td>
                                            <td>N15,000</td>
                                            <td>N20,000 </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Africa/LDC
                                            </td>
                                            <td>USD 150</td>
                                            <td>USD 180</td>
                                            <td>USD 100</td>
                                            <td>USD 120</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                DC/Multi-laterals/IGO
                                            </td>
                                            <td>USD 2,000</td>
                                            <td>USD 2,400</td>
                                            <td>USD 2,000</td>
                                            <td>USD 2,400</td>
                                        </tr>

                                        <tr>
                                            <th colSpan={5} style={{ textAlign: "center", backgroundColor: "white", fontWeight: "bolder" }}>Students</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                National
                                            </td>
                                            <td>N15,000</td>
                                            <td>N20,000</td>
                                            <td>N10,000</td>
                                            <td>N15,000 </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Africa/LDC
                                            </td>
                                            <td>USD 30</td>
                                            <td>USD 35</td>
                                            <td>USD 20</td>
                                            <td>USD 25</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                DC
                                            </td>
                                            <td>USD 100</td>
                                            <td>USD 120</td>
                                            <td>USD 50</td>
                                            <td>USD 60</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                </section>


                <section className="sponsors section " style={{ marginTop: "-100px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="section-title">
                                    <h2>
                                        Conference Coordinators <span className="alternate"></span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="block text-center">
                                    {/* Sponsors image list */}
                                    <ul className="list-inline sponsors-list">
                                        <li className="list-inline-item">
                                            <div className="image-block text-center">
                                                Dr. Michael Elikwu<br />
                                                Baba Ahmed University Abuja<br />
                                                +234(0)7085524075

                                            </div>
                                        </li>
                                        <li className="list-inline-item">
                                            <div className="image-block text-center">
                                                Dr. Taoofeek Owoseni<br />
                                                Baba Ahmed University Abuja<br />
                                                +234(0)8023002378

                                            </div>
                                        </li>
                                        <li className="list-inline-item">
                                            <div className="image-block text-center">
                                                Samuel Ogbe <br />
                                                Michael Okpara University of Agriculture <br />
                                                Umudike, Abia State. <br />
                                                +234(0)8035803813

                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="block text-center">
                                    {/* Sponsors image list */}
                                    <ul className="list-inline sponsors-list">

                                        <li className="list-inline-item">
                                            <div className="image-block text-center">
                                                Dr. Momoh Mustapha <br />
                                                University of Maiduguri, <br />
                                                +234(0)8067209220

                                            </div>
                                        </li>
                                        <li className="list-inline-item">
                                            <div className="image-block text-center">
                                                Associate Prof. Yusuf Muhammad <br />
                                                Nigeria Police Academy, Wudil, Kano State. <br />
                                                +234(0)8036693791

                                            </div>
                                        </li>
                                        <li className="list-inline-item">
                                            <div className="image-block text-center">
                                                Dr. Joy Akahome<br />
                                                Federal University, <br />
                                                Otuoke, Bayelsa State <br />
                                                +234(0)8032379048

                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cta-subscribe bg-subscribe overlay-dark-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mr-auto">
                                <div className="content">
                                    <h3>
                                        For further information or sponsorship,  <span className="alternate">please call</span>
                                    </h3>
                                    <p>
                                        Dr. Michael Elikwu <br />
                                        CFIS, Baba Ahmed University, Kano. <br />
                                        07085524075

                                    </p>
                                    <br />
                                    <p>
                                        Patrick Anucha - <br />
                                        Society for Economic Empowerment and Entrepreneurship Development
                                        641 Jereton Mariri/Mobolaji Johnson Street,
                                        Apo Zone E, Garki II, Abuja – Nigeria <br />
                                        Email to: <a href='mailto: edlconfab@gmail.com' style={{ color: "white", textDecoration: "underline" }}>edlconfab@gmail.com</a> <br />
                                        Website: <a href='www.seeedev.org' target='_blank' style={{ color: "white", textDecoration: "underline" }} >www.seeedev.org</a> <br />
                                        Tel: +2348079978892, +2349071886777
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br /> <br /><br /><br /><br />
            </>

        </div>
    )
}
