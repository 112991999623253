import React, { Component } from "react";
import RislanImage from "../../images/people/rislan.png";
import ITRIOffice from './itri.jpg'
import { Link } from "react-router-dom";
import HRLine from "../utils/hrline";
import ITRIVC from './itri/itri_vc.jpeg'

class ITRIDVC extends Component {

    render() {
        return <div className="layout-col col1 layout-12 col-sm-12">
            <div className="container-fluid layout col2-50-50">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="image-wrapper">
                                    <div>
                                        <picture>
                                            <img
                                                style={{
                                                    width: '100%',
                                                    height: '200px !important',
                                                    borderRadius: '10px',
                                                    marginTop:'25px'
                                                }}
                                                loading="lazy"
                                                height={'420px'}
                                                src={ITRIVC}
                                                alt="Dr. Rislana Abdulazeez Kanya"
                                                title="Dr. Rislana Abdulazeez Kanya"
                                                typeof="foaf:Image" />
                                        </picture>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-7">
                                <div className="col-sm-12 one-col text-justify">
                                    <h3>Dr. Rislana Abdulazeez Kanya</h3>
                                    <p><strong>Deputy Vice-Chancellor, IT, Research & Innovation</strong></p>
                                    <p>
                                        Dr Rislan Abdulazeez Kanya Graduated with B.Sc. (Hons) in Computing and Information Systems and Ph.D. in Computing (ICT for Development - ICT4D) from the University of Portsmouth and a Master of Business Administration from the University of Wales. Rislan has diversified experience in the field of Information Systems, Digital Transformation, Change Management and Business Process Re-engineering. <br />
                                        A resourceful and focused ICT specialist with considerable exposure in Enterprise Architecture, Project/Programme Management, ICT4D, Higher Education Planning, Administration, and Evaluation. Rislan has practical experience with Higher Education Innovation, Data and Funding Analytics, Design and Implementation of Campus and Large Networks, Enterprise Resource Planning (ERP) and many more. He had earlier worked as an instructor for CTech USA certification programs at Informatics Institute, Computer Training Centre Manager in Jigawa State and Jigawa Savings and Loans Bank as an Information Systems Officer, Head of Accounts/Operations for some years before joining Baba Ahmed University Abuja as a pioneer staff and Director of IT Services. <br />
                                    </p>
                                    {/* <p>Has strong diversified experience in the field of Computing, Information Systems, Strategy, Innovation and Business Process Re-engineering. A resourceful and focused ICT specialist, with considerable exposure in Enterprise Architecture, Project and Programme Management, ICT sectoral Application (eGovernment, eLearning, eBusiness, etc) Implementation and Strategy</p>

                                                    <p>Rislan has much interest on how ICT can be used in the area of sustainable development (ICT4D), capability enhancement (Economic empowerment/poverty reduction), Big data analytics in disruptive innovation. Rislan has coached and mentored more than 2000 young and vulnerable youth on how the can use the power of the computer, the Internet, and the social media to improve their capabilities, choices and functioning. Rislan during his PhD research developed a hybrid framework on how to reduce ICT4D project failure and improve on ICT4D project champion.</p> */}
                                </div>
                            </div>
                            <p style={{ marginTop: '-50px !important', textAlign: 'justify' }} >
                                Rislan has much interest in how ICTs can be used in sustainable development goals (ICT4SDGs), capability enhancement (Economic empowerment/poverty reduction), Big Data Analytics in disruptive innovation. Rislan has coached and mentored more than 2000 young and vulnerable youth on how they can exploit the power of computers, the Internet, and social media to improve their capabilities, choices and functionings. Rislan, during his PhD research, developed a hybrid framework on how to reduce ICT4D project failure and improve on ICT4D project champion.
                                <br />
                                Rislan was involved in many United Nations multi-stakeholders programs targeting the attainment of SDGs.
                            </p>
                            <strong>
                                <p>
                                    <Link to="/academics/staff/abdulazeez-kanya-rislana">
                                        DVC's website
                                    </Link>

                                </p>
                            </strong>

                            <HRLine />
                        </div>
                    </div>
                </div>

            </div>
        </div>;
    }
}
export default ITRIDVC;