
import React from "react";
import Banner from "../utils/banner/banner";
import Square from "./img/FUNDING.png";
import HRLine from "../utils/hrline";

function ResearchFunding() {
    return <>
        <Banner image={Square} title="Student Support Unit" caption="" disableTitle />
        <div className="container-fluid layout col1" style={{ margin: "20px" }}>
            <div className="row">

                <div className="container-fluid layout col2-50-50">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="layout-col col1 layout-12 col-sm-12">
                                    <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                        <div className="row">
                                            <div className="container text-justify">
                                                <div className="row header">
                                                    <div className="col-sm-12" style={{ backgroundColor: '#f5f5f5', padding: '30px', borderTopLeftRadius: '5px', borderBottomRightRadius: '20px' }}>
                                                        <h2>Exploring Funding Options for Research at Baba Ahmed University</h2>
                                                        <span>
                                                            <p>
                                                                Welcome to Baba Ahmed University's comprehensive guide on funding options for research endeavors. Conducting impactful research requires not only dedication and expertise but also adequate financial support. At Baba Ahmed University, we understand the importance of fostering a vibrant research culture, and we are committed to assisting our researchers in securing the funding they need to push the boundaries of knowledge and innovation.
                                                            </p>
                                                        </span>
                                                        <br />
                                                        <h2>Why Funding Matters</h2>
                                                        <p>
                                                            Funding is the lifeblood of research. It provides researchers with the resources necessary to pursue groundbreaking studies, explore new ideas, and contribute to the advancement of various fields. Adequate funding can enable researchers to access cutting-edge equipment, gather valuable data, collaborate with experts, and disseminate their findings to a wider audience. At Baba Ahmed University, we believe that by offering a variety of funding options, we empower our researchers to make meaningful contributions that drive positive change.
                                                        </p>
                                                    </div>
                                                </div>

                                                <HRLine />
                                                <div className="row">
                                                    <h2>Funding Options at Baba Ahmed University</h2>
                                                    <div className="col-md-12">
                                                        <ul>
                                                            <li>
                                                                <b>Internal Grants and Scholarships</b>
                                                                <p>
                                                                    Baba Ahmed University offers a range of internal grants and scholarships specifically designed to support research initiatives. These opportunities are open to both faculty members and students, encouraging collaboration and knowledge exchange across disciplines. Whether you're a junior researcher exploring a new concept or a seasoned academic pushing the boundaries of your field, our internal grants and scholarships provide a stepping stone for your research ambitions.

                                                                </p>
                                                            </li>
                                                            <li>
                                                                <b>External Research Grants</b>
                                                                <p>
                                                                    Baba Ahmed University actively encourages researchers to seek external funding from government agencies, foundations, and other organizations. Our dedicated research support office provides guidance on identifying suitable funding opportunities, preparing grant proposals, and navigating the application process. We are committed to helping our researchers secure competitive external grants that align with their research objectives.

                                                                </p>
                                                            </li>
                                                            <li>
                                                                <b>Industry Partnerships</b>
                                                                <p>
                                                                    Collaborating with industry partners can provide a valuable source of funding for research projects. Baba Ahmed University fosters strong connections with businesses and industries that share an interest in advancing knowledge and innovation. By establishing mutually beneficial partnerships, researchers can access resources, expertise, and financial support from the private sector.
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <b>International Collaborations</b>
                                                                <p>
                                                                    Participating in international research collaborations opens doors to a wider array of funding options. Baba Ahmed University encourages researchers to engage with global partners, facilitating cross-border projects that can attract funding from international agencies and institutions. Our international relations team is here to support researchers in forming and maintaining these valuable collaborations.
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <b>Crowdfunding and Public Engagement</b>
                                                                <p>
                                                                    Innovative research often captures the imagination of the public. Baba Ahmed University encourages researchers to explore crowdfunding platforms and public engagement initiatives as alternative funding sources. Sharing your research vision with a broader audience can lead to direct support from individuals who are passionate about your work.
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <HRLine />
                                                    <div className="col-sm-12" style={{ marginTop: '20px' }} >
                                                        <div className="col-sm-12 one-col block_quate text-justify">
                                                            <h2>Navigating the Funding Landscape</h2>
                                                            <p>
                                                                At Baba Ahmed University, we recognize that navigating the funding landscape can be a complex and daunting task. That's why we offer comprehensive training and workshops to equip researchers with the skills they need to secure funding effectively. From grant writing workshops to seminars on building successful partnerships, we provide the resources and knowledge necessary to thrive in the competitive world of research funding.

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <HRLine />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </>
}

export default ResearchFunding;
