import React from "react";
import Banner from "../../utils/banner/banner";
import education from "../../../images/general/baze-education.jpeg";
import {Link} from "react-router-dom";

function LearningGoals () {
    return <>
        <Banner image={education} title="Learning Goals" caption=""/>
        <div className="container-fluid layout col1" style={{margin: "20px"}}>
            <div className="row">
                <div className="layout-col layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-text container-fluid roundable block text">
                        <div className="row">
                            <div className="container">
                                <div className="row left">
                                    <div className="col-sm-9 one-col ">
                                        <h2>Our Learning Goals: Skills for a lifetime</h2>
                                        <p style={{textAlign: 'justify'}}>Baba Ahmed university mission is to establish and maintain a most suitable academic environment synergizing world-class human capital and best technology for creating and imparting knowledge to develop and modernize the Nigerian society.</p>
                                        <p style={{textAlign: 'justify', marginTop: '20px'}}>Our faculty, students, alumni, and staff strive toward these objectives in a context of freedom with responsibility. We foster initiative, integrity, and excellence, in an environment of collegiality, civility, and responsible stewardship. As the land-grant university in Nigeria, we apply the results of our endeavors in service to our alumni, the community, the state, the nation, and the world.</p>
                                        <p style={{textAlign: 'justify', marginTop: '20px'}}>Within this context of great diversity, a Baba University education comprises formal and informal learning experiences in the classroom, on campus, and beyond. Through the multiple dimensions of students' lives, Baba Ahmed University provides an academic community that aims to help them achieve a variety of goals.</p>

                                        <div className="col-md-12 " style={{backgroundColor: '#eeeeee', marginTop: '40px'}}>
                                            <h1 className="text-center"><i className="fa fa-angle-double-left"/>  Learning Goals <i className="fa fa-angle-double-right"/>  </h1>
                                            <div className="col-md-6" style={{marginTop: '20px'}}>
                                                <p>
                                                    <b> <i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/> Disciplinary Knowledge:</b>
                                                    Demonstrate a systematic or coherent understanding of an academic field of study
                                                </p>

                                                <p style={{marginTop: '20px'}}>
                                                    <b><i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/> Critical Thinking:</b>
                                                    Apply analytic thought to a body of knowledge; evaluate arguments; identify relevant assumptions or implications; formulate coherent arguments
                                                </p>

                                                <p style={{marginTop: '20px'}}>
                                                    <b>  <i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/> Information Literacy: </b>
                                                    Access, evaluate, and use a variety of relevant information sources
                                                </p>

                                                <p style={{marginTop: '20px'}}>
                                                    <b><i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/> Engagement in the Process of Discovery or Creation:</b>
                                                    For example, demonstrate the ability to work productively in a laboratory setting, studio, library, or field environment
                                                </p>

                                            </div>
                                            <div className="col-md-6"  style={{marginTop: '20px'}}>
                                                <p>
                                                    <b> <i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/> Self-Directed Learning:</b>
                                                    Work independently; identify appropriate resources; take initiative; manage a project through to completion
                                                </p>
                                                <p style={{marginTop: '20px'}}>
                                                    <b><i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/> Communication Skills:</b>
                                                    Express ideas clearly in writing; speak articulately; communicate with others using media as appropriate; work effectively with others
                                                </p>

                                                <p style={{marginTop: '20px'}}>
                                                    <b> <i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}} /> Scientific and Quantitative Reasoning: </b>
                                                    Demonstrate the ability to understand cause and effect relationships; define problems; use symbolic thought; apply scientific principles; solve problems with no single correct answer                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div style={{ background: '#f4f4f4', padding: '8px', borderRadius: '5px' }}>
                                            <h3 className="nav-top">Academics</h3>
                                            <ul className="tabs">
                                                <Link to={"/academics/baze-education"}>
                                                    <li>
                                                        The Baba Ahmed university Education
                                                        <i className="fa fa-angle-right pull-right icons" />
                                                    </li>
                                                </Link>
                                                <Link to={"/academics/learning-goals"}>
                                                    <li>
                                                        Learning Goals
                                                        <i className="fa fa-angle-right pull-right icons" />
                                                    </li>
                                                </Link>
                                                <Link to={"/academics/academic-registry"}>
                                                    <li>
                                                        Academic Registry
                                                        <i className="fa fa-angle-right pull-right icons" />
                                                    </li>
                                                </Link>
                                                <Link to={"/academics/academic-calender"}>
                                                    <li>
                                                        Academic Calendar
                                                        <i className="fa fa-angle-right pull-right icons" />
                                                    </li>
                                                </Link>
                                                {/*<Link to={"/librarian-office"}>*/}
                                                {/*    <li>*/}
                                                {/*        Library*/}
                                                {/*        <i className="fa fa-angle-right pull-right icons" />*/}
                                                {/*    </li>*/}
                                                {/*</Link>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
}

export default LearningGoals;
