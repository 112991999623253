import React, { useState } from "react";
import Banner from "../../utils/banner/banner";
import "../../admission/application-guide/application-guide.css"
import calenderBanner from '../academic-calender/calender.jpeg'
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { useEffect } from "react";
import { formatDate, formatDateAndTime } from "../../../resources/constants";

const AcademicCalender = () => {
    const [academicData, setAcademicData] = useState([])
    const params = new URL(document.location).searchParams;
    let semester = params.get("semester")

    const getData = () => {
        axios.get(`${serverLink}resources/website/academic/calender?semester=${semester}`)
            .then(res => {
                if (res.data.length > 0) {
                    setAcademicData(res.data)
                }
            })
            .catch(error => {
                console.log("ERROR FETCHING THE ACADEMIC CALENDER")
            });
    }

    useEffect(() => {
        getData();
    }, [semester])

    useEffect(() => {
        semester = params.get("semester")
    }, [document.location])
    return <>
        <Banner image={calenderBanner} title={`${new Date().getFullYear()} Academic Calendar`} caption="" />

        <div className="container-fluid layout col1" style={{ margin: "10px" }}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="layout-blocks-ucws-checklist container-fluid roundable block checklist">


                        <div className="layout-col col1 layout-12 col-sm-12">
                            <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                <div className="row" style={{ marginTop: '50px' }}>
                                    <h2>  Academic Calendar</h2>
                                    <p>
                                        Baba Ahmed University academic calendar is divided into three semesters. Students typically register minimum of 15 credit units and maximum of 24 credit units per semester. A credit unit typically includes lectures, interactive sessions, laboratory/workshops, seminars and field trips.
                                    </p>
                                    <div className="col-md-9">
                                        <div className="container-fluid layout col1">
                                            <div className="text-justify">
                                                {
                                                    academicData.length > 0 ?
                                                        <div className="table-responsive" style={{ marginTop: "20px" }}>
                                                            <table className={"table table-bordered"}>
                                                                <thead>
                                                                    <tr className="text-center">
                                                                        {
                                                                            academicData.length > 0 &&
                                                                            <td colSpan={3}>
                                                                                <h4>
                                                                                    {academicData[0].Semester} Academic Calendar
                                                                                </h4>
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        academicData.length > 0 ?
                                                                            academicData.map((x, i) => {
                                                                                return (
                                                                                    <tr key={i}>
                                                                                        <td>{x.ItemName}</td>
                                                                                        <td>{formatDateAndTime(formatDate(x.StartDate), "date")}</td>
                                                                                        {/* <td>{formatDateAndTime(formatDate(x.EndDate), "date")}</td> */}
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            :
                                                                            <tr>
                                                                                <td colSpan={3}><i><b>loadin academic calender...</b></i></td>
                                                                            </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        : <div className={"text-center alert alert-danger"}>Coming Soon</div>

                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div style={{ background: '#eeeeee', padding: '8px', borderRadius: '5px', paddingLeft: '15px' }}>
                                            <h4>Please Note: </h4>
                                            <h5>All dates are subject to change</h5>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
            <div className="row">
                <div className="row">
                    <div className="col-sm-12">
                        <hr className="solid light-grey" />
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid layout col1">
            <div className="row">
                <div className="layout-col col1 layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-info-shim container-fluid roundable block info-shim">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="more-info-line">
                                            <div className="more-info-icon">
                                                <span className="icon icomoon ucws-info-shim-info light-grey"
                                                    aria-hidden="true"></span> Did you know Baba Ahmed University Admission system is always open?
                                                <a href="https://portal.babaahmeduniversity.edu.ng/admission"
                                                    target="_blank" rel="noopener"> Apply here</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default AcademicCalender;
