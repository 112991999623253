
import React from "react";
import Banner from "../utils/banner/banner";
import Square from "../../images/faculties/square.jpg";
import Strategy from "../../images/dir_dapps_2.jpeg";

function StartegyAndSpecialDuties() {
    return <>
        <Banner image={Square} title="Strategy and Special Duties" caption="" />
        <div className="container-fluid layout col1" style={{ margin: "20px" }}>
            <div className="row">
                <div className="container-fluid layout col2-50-50">
                    <div className="row">
                        <div className="container">
                            <div className="row">

                                <div className="layout-col col2-50 layout-6 col-md-6">
                                    <div className="layout-blocks-ucws-text container-fluid roundable block text">
                                        <div className="row">
                                            <div className="container">
                                                <div className="row left" >
                                                    <div className="col-sm-12 one-col block_quate text-justify">
                                                        {/* <h2>Introduction</h2> */}
                                                        <p>The Directorate of Strategy and Special Duties (DSSD) at Baba Ahmed University was established by Management in July 2017 to strengthen the strong foundation of the Baze Brand as a key player in the provision of private tertiary education in Nigeria. The Directorate provides a unique delivery platform for initiatives and interventions that address peculiar challenges in implementing plans, university growth, etc. <br />
                                                        </p><br />
                                                        <h5>Dr. Rukayyatu Gurin</h5>
                                                        <span>Director, Academic Planning and Strategy</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="layout-col col2-50 layout-6 col-md-6">
                                    <div className="layout-blocks-ucws-image container-fluid roundable block image">
                                        <div className="row">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="image-wrapper" style={{ float: 'right' }}>
                                                            <div>
                                                                <img
                                                                    height={'400px'}
                                                                    width="100%"
                                                                    loading="lazy"
                                                                    src={Strategy}
                                                                    typeof="foaf:Image"
                                                                    style={{  borderRadius: '10px' }}
                                                                     />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="layout-col layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-text container-fluid roundable block text">
                        <div className="row">
                            <div className="container">
                                <div className="row left">
                                    <div className="col-sm-12 one-col block_quate text-justify">
                                        <h2>Function</h2>
                                        <p>The Directorate is primarily responsible for proposing policies, strategies and programmes in the areas listed in the two boxes above. <br />
                                        </p>
                                    </div>
                                </div>
                                <hr />
                                <br />
                                <h3>Directorate Staff</h3>
                                <div className="table table-responsive">
                                    <table className="table table-responsive table-hover " >
                                        <thead>
                                            <th>SN</th>
                                            <th>Name</th>
                                            <th>Position</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Associate Prof. Jamila Shu'ara</td>
                                                <td>Director</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Ja'afar Ahmad</td>
                                                <td>Asst. Registrar</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Idika Chike Eze</td>
                                                <td>Academic Planning Officer</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Josephine Orinya</td>
                                                <td>Admin. Officer 1</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Ozumba Azumpta O.</td>
                                                <td>Admin. Officer 2</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <br />
                                <h3>Duties</h3>
                                <ul style={{listStyle : "initial"}}>
                                    <li>
                                        Plan strategies on broad areas of operations and propose strategic plans
                                    </li>
                                    <li>Monitor the implementation of strategic plans</li>
                                    <li>Formulate policies and propose initiatives for structured growth in students’ enrolment within specific periods of consecutive academic years</li>
                                    <li>Liaise with Departments and Faculties to package road shows for focal audience on the Baba Ahmed University brand</li>
                                    <li>Institute strategies to market brand and grow followership (Baze Ambassadors, Achievers Awards, Recognitions, Incentives, etc.)</li>
                                    <li>Develop and manage strategic partnerships/collaborations local and international</li>
                                    <li>Central coordination pool for endowments in all Faculties</li>
                                    <li>Coordination of SIWES</li>
                                    <li>Establish Baze Alumni Office and support all related activities</li>
                                    <li>Central Coordination of Baba Ahmed University consult</li>
                                    <li>University Outreach, Media and Publicity</li>
                                    <li>Set up operational guidelines for the Baze Foundation etc.</li>
                                    <li>Coordinate the University Outreach Unit;</li>
                                    <li>Set up operational guidelines for Baze Corporate Social Responsibility & Baze Foundation;</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="layout-col layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-text container-fluid roundable block text">
                        <div className="row">
                            <div className="container">
                                <div className="row left">
                                    <div className="col-sm-12 one-col block_quate text-justify">
                                        <h2>Contact Us</h2>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>Address:</h4>
                                                <address>
                                                    Suite 67, 2nd Floor, <br />Faculty of Environmental Sciences, <br />Baba Ahmed University, Kano.
                                                </address>

                                            </div>
                                            <div className="col-md-6">
                                                <h4>Connect with us:</h4>
                                                <div>
                                                    <span>Email: <a target={"_blank"} href="mailto:dssd@bazeuniversity.edu.ng" >dssd@bazeuniversity.edu.ng</a></span>
                                                </div>
                                                <div>
                                                    <span>Fcebook: <a target={"_blank"} href="https://www.facebook.com/BazeUniversity" >https://www.facebook.com/BazeUniversity</a></span>
                                                </div>
                                                <div>
                                                    <span>Twitter: <a target={"_blank"} href="https://twitter.com/Baze_University" >https://twitter.com/Baze_University</a></span>
                                                </div>
                                                <div>
                                                    <span>Instagram: <a target={"_blank"} href="https://www.instagram.com/Official_BazeUniversity/" >https://www.instagram.com/Official_BazeUniversity/</a></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>



    </>
}

export default StartegyAndSpecialDuties;
