import React from "react";
import Banner from "../../utils/banner/banner";
import Square from "../../../images/faculties/bau_banner.png";
import TheBrim from "../../../images/general/brim.jpg";
import WSauce from "../../../images/general/wsaucelocation.jpg";
import TuckShop from "../../../images/general/tuck_shop.png";

function Feeding() {
    return <>
        <Banner image={Square} title="Feeding" caption="" />
        <div className="container-fluid layout col1" style={{ margin: "20px" }}>
            <div className="row">
                <div className="layout-col layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-text container-fluid roundable block text">
                        <div className="row">
                            <div className="container">
                                <div className="row left">
                                    <div className="col-sm-12 one-col block_quate text-justify">
                                        <h2>Getting your Meals</h2>
                                        <p>The meals arrangement is solely-based on the students’ preference. The school however has made a provision for students and parents that want to regulate the feeding cost in an effective manner. Students can pay a certain amount (there are no limitations) and be given a feeding booklet. Using the slips in this booklet, they can buy food from the eating areas that use this method as a form of payment. This reduces the need to carry around cash whilst ensuring that students are properly fed and satisfied.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        {/*<div className="container-fluid layout col2-50-50">*/}
        {/*    <div className="row">*/}
        {/*        <div className="container">*/}
        {/*            <div className="row">*/}
        {/*                <div className="layout-col col2-50 layout-6 col-md-6">*/}
        {/*                    <div className="layout-blocks-ucws-text container-fluid roundable block text">*/}
        {/*                        <div className="row">*/}
        {/*                            <div className="container">*/}
        {/*                                <div className="row left">*/}
        {/*                                    <div className="col-sm-12 one-col text-justify">*/}
        {/*                                        <h3>The Brim</h3>*/}
        {/*                                        <p>The Brim was the university’s first open cafeteria. Since its inception, the Brim has been dedicated to serving the most delightful array of dishes to ensure that their customers have an enjoyable moment at the university. Ranging from rice dishes to traditional swallows, the Brim has proven to be a connoisseur of well-made food. It also includes an outdoor arena where students can relax and enjoy their meals as well as a shawarma shack.</p>*/}
        {/*                                    </div>*/}
        {/*                                </div>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}

        {/*                <div className="layout-col col2-50 layout-6 col-md-6">*/}
        {/*                    <div className="layout-blocks-ucws-image container-fluid roundable block image">*/}
        {/*                        <div className="row">*/}
        {/*                            <div className="container">*/}
        {/*                                <div className="row">*/}
        {/*                                    <div className="col-sm-12">*/}
        {/*                                        <div className="image-wrapper">*/}
        {/*                                            <div>*/}
        {/*                                                <img*/}
        {/*                                                    style={{ borderRadius: '10px' }}*/}
        {/*                                                    height={250}*/}
        {/*                                                    width="100%"*/}
        {/*                                                    loading="lazy"*/}
        {/*                                                    src={TheBrim}*/}
        {/*                                                    typeof="foaf:Image" />*/}

        {/*                                            </div>*/}
        {/*                                        </div>*/}
        {/*                                    </div>*/}
        {/*                                </div>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}


        {/*                </div>*/}

        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div className="container-fluid layout col2-50-50">*/}
        {/*    <div className="row">*/}
        {/*        <div className="container">*/}
        {/*            <div className="row">*/}
        {/*                <div className="layout-col col2-50 layout-6 col-md-6">*/}
        {/*                    <div className="layout-blocks-ucws-image container-fluid roundable block image">*/}
        {/*                        <div className="row">*/}
        {/*                            <div className="container">*/}
        {/*                                <div className="row">*/}
        {/*                                    <div className="col-sm-12">*/}
        {/*                                        <div className="image-wrapper">*/}
        {/*                                            <div>*/}
        {/*                                                <img*/}
        {/*                                                    style={{ borderRadius: '10px' }}*/}
        {/*                                                    height={250}*/}
        {/*                                                    width="100%"*/}
        {/*                                                    loading="lazy"*/}
        {/*                                                    src={WSauce}*/}
        {/*                                                    alt="WSauce at Baba Ahmed University"*/}
        {/*                                                    title="WSauce at Baba Ahmed University"*/}
        {/*                                                    typeof="foaf:Image" />*/}

        {/*                                            </div>*/}
        {/*                                        </div>*/}
        {/*                                    </div>*/}
        {/*                                </div>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}


        {/*                </div>*/}

        {/*                <div className="layout-col col2-50 layout-6 col-md-6">*/}
        {/*                    <div className="layout-blocks-ucws-text container-fluid roundable block text">*/}
        {/*                        <div className="row">*/}
        {/*                            <div className="container">*/}
        {/*                                <div className="row left">*/}
        {/*                                    <div className="col-sm-12 one-col text-justify">*/}
        {/*                                        <h3>Grilled food</h3>*/}
        {/*                                        <p>Grilled food is always a delight to partake in and WSauce provides just the right amount of beautifully grilled meals that will leave you pandering for extra helpings. Their exhaustive menu of grilled meats, Shawarma, and even noodles are popular amongst the students of the university.</p>*/}
        {/*                                    </div>*/}
        {/*                                </div>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}

        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div className="container-fluid layout col2-50-50">*/}
        {/*    <div className="row">*/}
        {/*        <div className="container">*/}
        {/*            <div className="row">*/}
        {/*                <div className="layout-col col2-50 layout-6 col-md-6">*/}
        {/*                    <div className="layout-blocks-ucws-text container-fluid roundable block text">*/}
        {/*                        <div className="row">*/}
        {/*                            <div className="container">*/}
        {/*                                <div className="row left">*/}
        {/*                                    <div className="col-sm-12 one-col text-justify">*/}
        {/*                                        <h3>The Tuck Shop</h3>*/}
        {/*                                        <p>The Tuck Shop offers a range of delicacies including grilled awesomeness such as burgers and hotdogs served with a side of crispy fries. Other appetizing meals include wings (barbecued or crispy) as well as corndogs. The meals served in this center are carefully and deliciously made and has made the center a connoisseur of gourmet food.</p>*/}
        {/*                                    </div>*/}
        {/*                                </div>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}

        {/*                <div className="layout-col col2-50 layout-6 col-md-6">*/}
        {/*                    <div className="layout-blocks-ucws-image container-fluid roundable block image">*/}
        {/*                        <div className="row">*/}
        {/*                            <div className="container">*/}
        {/*                                <div className="row">*/}
        {/*                                    <div className="col-sm-12">*/}
        {/*                                        <div className="image-wrapper">*/}
        {/*                                            <div>*/}
        {/*                                                <img*/}
        {/*                                                    style={{ borderRadius: '10px' }}*/}
        {/*                                                    height={250}*/}
        {/*                                                    width="100%"*/}
        {/*                                                    loading="lazy"*/}
        {/*                                                    src={TuckShop}*/}
        {/*                                                    typeof="foaf:Image" />*/}

        {/*                                            </div>*/}
        {/*                                        </div>*/}
        {/*                                    </div>*/}
        {/*                                </div>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}


        {/*                </div>*/}

        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </>
}

export default Feeding;
