import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import Square from "../../../images/faculties/bau_banner.png"
import { connect } from "react-redux";
import "./events.css"
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { setEventsDetails } from "../../../actions/setactiondetailsection";
import EventSection from "../eventsection/eventsection";
import { useState } from "react";
import { useEffect } from "react";

const Events = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [eventData, seteventData] = useState(props.eventData.length > 0 ? props.eventData : [])

    useEffect(() => {
        fetchEvents();
    }, [isLoading])

    const fetchEvents = async() => {
        if (props.eventData.length > 0) {
                setIsLoading(false)
        }

        await axios.get(`${serverLink}event/list/active`)
            .then(result => {
                props.setOnEventDetails(result.data);
                seteventData(result.data)
                setIsLoading(false)
            })
            .catch(e => {
                console.log('NEWS FETCH NETWORK ERROR')
            })
    }

        return <>
            <Banner image={Square} title="Baba Ahmed University Events" caption="" />

            {
                isLoading ?
                    <div className="container-fluid layout col1">
                        <div className="row">
                            <div className="layout-col col1 layout-12 col-sm-12">
                                <div className="layout-blocks-ucws-text container-fluid roundable block text">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row header left">
                                                <div className="col-sm-12">
                                                    <h2>
                                                        <div>Loading...</div>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    :
                    <div style={{ margin: "20px" }}>
                        <EventSection page="events" events={eventData} />
                    </div>

            }

        </>


}

const mapStateToProps = (state) => {
    return {
        eventData: state.BazeEventDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnEventDetails: (p) => {
            dispatch(setEventsDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Events)
