import React from 'react'
import Banner from '../../utils/banner/banner'
import { isMobile } from 'react-device-detect'

export default function PeaceAndAntiConflict() {

    const centerStaff = [
        {
            name: "Mohammad Kabir Yusuf",
            rank: "Associate Professor",
            area: "Sociology and Criminology",
            highest: "PhD",
            institution : "Baba Ahmed University",
            profile: "https://bazeuniversity.edu.ng/academics/staff/kabir-yusuf-phd-muhammad"
        },
        {
            name: "Dr. Dr. Russell Smandych",
            rank: "Professor",
            area: "Sociology and Criminology",
            highest: "PhD",
            institution : "University of Manitoba",
            profile: ""
        },
        {
            name: "Temitope Oriola",
            rank: "Senior Research Fellow",
            area: "Sociology and Criminology",
            highest: "PhD",
            institution : "University of Alberta",
            profile: ""
        },
        {
            name: "Maame Peterson",
            rank: "Research Fellow",
            area: "Peace and Conflict",
            highest: "Masters",
            institution : "African Institute for Development Policy (AFIDEP)",
            profile: ""
        },
        {
            name: "Okam",
            rank: "Research Fellow",
            area: "Videography",
            highest: "Masters",
            institution : "Baba Ahmed University",
            profile: "https://bazeuniversity.edu.ng/academics/staff/okam-ira-a-aron"
        },
    ]
    return (
        <div>
            <Banner image={require("../images/peace_1.png")} title="CCECC" caption="" disableTitle />

            <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right mt-5" style={{ marginTop: "30px" }}>
                <div className="row">
                    <div className="col-sm-12 ">
                        <div className={isMobile ? "col-md-12" : "col-md-9"}>
                            <p>
                                Peace and Anti-Conflict Research Center is a newly established research centre at Baba Ahmed University, Kano Nigeria. The centre started its activities in 2020 and officiated in June 2021 after signing a sub-agreement MoU between Baba Ahmed University of the University of Manitoba which provided funding for the activities. The centre was established with the sole aim of carrying out the day-to-day administrative and technical functions that will facilitate the Social Sciences and Humanities Research Council, Canada’s funded research “Reframing Media Studies of Crime, Insurgencies, and Counterterrorism in Nigeria: Toward a New Multi-Disciplinary Criminology-Mass Communication Stakeholder Approach.” The centre belongs to the Journalism and Entrepreneurship academic disciplines.
                                <br/><br/><br/>

                                The centre has 3 Permanent Research Fellows, 1 Research Personnel, and 1 Technical Staff. The head of the center is Dr. Mohammad Kabir Yusuf who has a PhD in Sociology and Criminology and is an Associate Professor.

                            </p>
                        </div>
                        {
                            !isMobile &&
                            <div className="col-md-3">
                                <img
                                    style={{ borderRadius: '10px', maxHeight: "300px" }}
                                    className="img-responsive-top"
                                    loading="lazy"
                                    src={"https://portal.bazeuniversity.edu.ng/staff/portal/users/account/profile_pix/202211132303046162513.jpg"}
                                    alt={'kabir'}
                                    title={'kabir'}
                                    typeof="foaf:Image" />
                                <div className='row text-center'>
                                    <h4>Dr. Mohammad Kabir Yusuf</h4>
                                    <h5>Director, Center for Peace and Anti-Conflict Research</h5>
                                </div>
                            </div>
                        }
                        <div className='col-md-12'>
                            <iframe width="100%" height="450"
                                src="https://www.youtube.com/embed/oNZ7roMdBdU">
                            </iframe>
                        </div>

                        <div className="col-lg-12 mt-5" style={{ marginTop: "30px" }}>
                            <div className="layout-col col1 layout-12 col-sm-12">
                                <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                    <h3 className='text-left'>Other Staff of the Centre</h3>

                                    <div className="table-responsive" style={{ marginTop: "30px" }}>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <td>Name</td>
                                                    <td>Rank</td>
                                                    <td>Institution</td>
                                                    <td>Highest Qualification</td>
                                                    <td>Area of Specialization</td>
                                                    <td>Profile Link</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    centerStaff.length > 0 &&
                                                    centerStaff.map((x, y) => {
                                                        return (
                                                            <tr key={y}>
                                                                <td>{x.name}</td>
                                                                <td>{x.rank}</td>
                                                                <td>{x.institution}</td>
                                                                <td>{x.highest}</td>
                                                                <td>{x.area}</td>
                                                                <td><a href={x.profile} target='_blank'>{x.profile}</a></td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className='col-md-12' style={{ marginTop: "40px" }}>
                            <div className='row'>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-10 text-center">
                                            <h3><i className='fa fa-envelope-o' />&nbsp;Email</h3>
                                            <p>muhammad.kabir@bazeuniversity.edu.ng</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-10 text-center">
                                            <h3><i className='fa fa-mobile' />&nbsp;Telephone</h3>
                                            <p>+2348038881245 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-10 text-center">
                                            <h3><i className='fa fa-link' />&nbsp;Website</h3>
                                            <a href='/center-for-peace-and-anti-conflict-journalism'>tinyurl.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
