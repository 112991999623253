import React from "react";
import Banner from "../utils/banner/banner";
import HRLine from "../../components/utils/hrline";
import ResearchBanner from "./img/RESEARCH AT BAZE UNIVERSITY.png";
import { ResearchAreasData, ResearchersData } from "./researchers_";
import '../../index.css';
import { connect } from "react-redux";
import { shuffle } from "../../resources/constants";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';





const ResearhAreaSingle = ({ data, index, staffList }) => {
    const nav = useNavigate()

    return (
        <div className="row">
            <div className="col-md-9 col-sm-12" style={{ marginTop: "30px" }}>
                <h4><strong>{index + 1}	{data.dept}</strong></h4>
                <p style={{ textAlign: "justify" }}>
                    {
                        data.researches.map((j, i) => {
                            return (
                                <ol type="a" key={i}>
                                    <li>
                                        <strong>{j.title}</strong>
                                        <ul>
                                            <li><p>{j.brief}</p></li>
                                        </ul>
                                    </li>

                                </ol>
                            )
                        })
                    }
                </p>

                <HRLine />
                <div className="row">
                    {
                        shuffle(staffList.filter(x => x.FacultyCode === data.fac)).slice(0, 5).map((staff, st_index) => {
                            return (
                                <>
                                    <img key={st_index}
                                        style={{
                                            borderRadius: "50%",
                                            maxWidth: isMobile ? "20%" : "5%",
                                            maxHeight: isMobile ? "20%" : "5%",
                                            objectFit: "cover"
                                        }}
                                        title={staff.staff_name}
                                        onClick={() => { nav(`/academics/staff/${staff.Slug}`) }}
                                        src={
                                            staff.image === null || staff.image === '' || staff.image === undefined || staff.image === "undefined" ?
                                                require("./img/noimage.png")
                                                :
                                                staff.image.includes("simplefileupload") ?
                                                    staff.image :
                                                    staff.image.includes("portal.babaahmeduniversity") ?
                                                        staff.image
                                                        : `https://portal.babaahmeduniversity.edu.ng/staff/${staff.image}`
                                        } />
                                </>
                            )
                        })
                    }
                    <div>
                        <Link to={`/academics/staff-list`}>Find More Researchers</Link>
                    </div>
                </div>
            </div>
            <div className="col-md-3 col-sm-12" style={{ marginBottom: "20px", marginTop: "30px" }}>
                <div className="image-wrapper" style={{ float: 'right' }}>
                    <div>
                        <img
                            height={'300px'}
                            width="100%"
                            loading="lazy"
                            src={data.image}
                            typeof="foaf:Image"
                            style={{ borderRadius: '10px' }}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}
function ResearchAreas(props) {
    return (
        <>
            {
                !props.hideBanner &&
                <Banner image={ResearchBanner} title="Research Areas" caption="" disableTitle />
            }
            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <h2>Research Areas at Baba Ahmed University</h2>
                            <HRLine />
                            {
                                ResearchAreasData.map((x, i) => {
                                    return (
                                        <ResearhAreaSingle data={x} index={i} key={i} staffList={props.staffList} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        staffList: state.BazeStaffListDetails,
        facultyList: state.BazeFacultyListDetails
    };
};

export default connect(mapStateToProps, null)(ResearchAreas);
