import React from 'react'
import Banner from '../../utils/banner/banner'
import { isMobile } from 'react-device-detect'
import './ai-blockchain.css';
import { Link } from 'react-router-dom';
import { ResearchersData } from './members';

export default function AIBlockChain() {
    return (
        <div>
            <Banner image={require("../images/blockchain_1.png")} title="CCECC" caption="" disableTitle />

            <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right mt-5" style={{ marginTop: "30px" }}>
                <div className="row">
                    <div className="col-sm-12 ">
                        <div className={isMobile ? "col-md-12" : "col-md-12"}>
                            <p>
                                Blockchain and Artificial Intelligence are emerging technologies that offer the possibility of re-engineering economic models and enabling the creation of markets and products that were previously unavailable or unprofitable across emerging markets. Cognizant of Blockchain and Artificial Intelligence's substantial potential benefits for society, but also wary of the risks, emerging market institutions, companies and governments are taking this technology seriously. It is evident that to foster innovation within these transformative technologies, educational institutions must play and become supporters of these technologies with the hope of using it to provide students, ordinary citizens and economies with technological advantage and a boost to growth. <br />
                            </p>
                            <p>
                                To improve the innovative capability of Baba Ahmed University and create job opportunities that will lead to spin-off companies/start-ups. This centre aims to improve the teaching and learning outcomes of students by providing an industry-informed curriculum to instil students with industry skill sets which will, in turn, enhance the employability of graduates. To jointly utilise innovations developed within the Blockchain and Artificial Intelligence laboratory to seek and execute projects from the private, public and non-Governmental sectors. This centre was established in 2021, under the Computing and Information Technology Faculty.  The centre has an established Artificial Intelligence (AI) and Blockchain Laboratory. After completion, the centre awards certificates as academic qualifications.
                            </p>
                            <p>The centre has 3 Research Personnel, 2 Technical Staff and 2 Administrative staff.
                                The head of the centre is Mr Calistus Igwilo who has a Masters degree in Bioengineering and is a Lecturer and Research Fellow.
                            </p>
                        </div>
                        {/*{*/}
                        {/*    !isMobile &&*/}
                        {/*    // <div className="col-md-3">*/}
                        {/*    //     <img*/}
                        {/*    //         style={{ borderRadius: '10px', maxHeight: "300px" }}*/}
                        {/*    //         className="img-responsive-top"*/}
                        {/*    //         loading="lazy"*/}
                        {/*    //         src={require('../images/itri_vc.jpeg')}*/}
                        {/*    //         alt={'Dr. Rislan Kanya'}*/}
                        {/*    //         title={'Dr. Rislan Kanya'}*/}
                        {/*    //         typeof="foaf:Image" />*/}
                        {/*    //     <div className='row text-center'>*/}
                        {/*    //         <h4>Dr. Rislan A. Kanya</h4>*/}
                        {/*    //         <h5>Co-Founder, Artificial Intelligence and Blockchain Technology Centre</h5>*/}
                        {/*    //     </div>*/}
                        {/*    // </div>*/}
                        {/*}*/}
                        <div className='col-md-12'>
                            <iframe width="100%" height="450"
                                src="https://www.youtube.com/embed/c0m6yaGlZh4">
                            </iframe>
                        </div>

                        <div className="col-lg-12 mt-5" style={{ marginTop: "30px" }}>
                            <div className="layout-col col1 layout-12 col-sm-12">
                                <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                    <h3 className='text-left'>Other Staff of the Centre</h3>

                                    <div className="table-responsive" style={{ marginTop: "30px" }}>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <td>Name</td>
                                                    <td>Rank</td>
                                                    <td>Highest Qualification</td>
                                                    <td>Area of Specialization</td>
                                                    <td>Profile Link</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    ResearchersData.length > 0 &&
                                                    ResearchersData.map((x, y) => {
                                                        return (
                                                            <tr key={y}>
                                                                <td>{x.name}</td>
                                                                <td>{x.rank}</td>
                                                                <td>{x.highest}</td>
                                                                <td>{x.area}</td>
                                                                <td><a href={x.profile} target='_blank'>{x.profile}</a></td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className='col-md-12' style={{ marginTop: "40px" }}>
                            <div className='row'>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-10 text-center">
                                            <h3><i className='fa fa-envelope-o' />&nbsp;Email</h3>
                                            <p>domineum@bazeuniversity.edu.ng</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-10 text-center">
                                            <h3><i className='fa fa-mobile' />&nbsp;Telephone</h3>
                                            <p>08033417102</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-10 text-center">
                                            <h3>MOU</h3>
                                            <a href='https://tinyurl.com/domineum'>tinyurl.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
