import React from "react";
import Banner from "../utils/banner/banner";
import ResearchBanner from "./img/bau_banner.png";

import './researchers.css'
import { ResearchersData } from "./researchers_";
import HRLine from "../utils/hrline";

export default function UniResearhers() {

    return (
        <>
            <Banner image={ResearchBanner} title="University Research Committee Members" caption="" disableTitle />

            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <h2>The University Research Committee </h2>
                                <HRLine />
                                {/* <div className="col-md-4 col-sm-12 mt-5" style={{ marginTop: "20px" }}>
                                    <div className="image-wrapper" style={{ float: 'left' }}>
                                        <div>
                                            <img
                                                height={'400px'}
                                                width="100%"
                                                loading="lazy"
                                                src={require("./img/rislan.png")}
                                                typeof="foaf:Image"
                                                style={{ borderRadius: '10px' }}
                                            />
                                            <div>
                                                <strong style={{ textAlign: "center" }}>
                                                    <p>
                                                        Dr. Rislan Kanya<br />
                                                        DVC IT, Research and Innovation
                                                    </p>
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="col-md-12 col-sm-12">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div className="media media-body">
                                                <p style={{ textAlign: "justify" }}>
                                                    Baba Ahmed University Research is a clear direction and emphasis on strategic research (basic and applied) tailored towards societal needs. Group and individual research projects will be funded through internal and external resources. In general, research will focus on advancing national development in all aspects of human endeavours and governance. Research activities will be geared toward the realisation of the University's philosophy, goals, and mission as detailed in its strategic plan. <br />

                                                    Baba Ahmed University will also classify research according to sectorial components of development. Research policies and trajectories will deliberately connect to each sector of knowledge and its application to improve the standard and quality of life of the people. Potential thematic areas of focus will include:<br />

                                                    It is recognised that Baba Ahmed University may at this time not develop academic provisions covering every aspect of each of these sectors. Instead, the focus will be on applying knowledge from the University’s chosen areas of specialisation to problems of relevance to these sectors. Overall, the University has the following specific objectives concerning research:<br />

                                                    a) To become recognised as a centre of research excellence in the University’s key disciplines both within Nigeria and Internationally.<br />

                                                    b) To provide quality undergraduate and postgraduate teaching – including the supervision of research students –underpinned by staff research of international quality.<br />

                                                    c) To contribute to the economic, social, cultural, and environmental development of Nigeria and the region by conducting and publishing relevant, high-quality research.<br />

                                                    d) To provide a suitable environment, equipment and tools that facilitate research and innovation in the University.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="layout-blocks-ucws-checklist container-fluid roundable block checklist">
                            <div className="row">
                                <h2>Committee Members</h2>
                                <HRLine />
                                <div className="col-lg-12 mt-5" style={{ marginTop: "30px" }}>
                                    {/*<div className="layout-col col1 layout-12 col-sm-12">*/}
                                    {/*    <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">*/}
                                    {/*        <div className="row">*/}
                                    {/*            {*/}
                                    {/*                ResearchersData.length > 0 ?*/}
                                    {/*                    ResearchersData.map((x, y) => {*/}
                                    {/*                        return (*/}
                                    {/*                            <div className="col-md-6" style={{ marginBottom: '30px' }}>*/}
                                    {/*                                <div className="fv_container">*/}
                                    {/*                                    <div className="row">*/}

                                    {/*                                        <div className="col-md-6 col-sm-12">*/}

                                    {/*                                            <img src={x.image} />*/}
                                    {/*                                        </div>*/}
                                    {/*                                        <div className="col-md-6 col-sm-12">*/}
                                    {/*                                            <p className="fv_information" style={{ marginTop: "20px" }}>*/}
                                    {/*                                                <div> {x.name}</div>*/}
                                    {/*                                                <div><strong>Faculty : </strong>{x.faculty}</div>*/}
                                    {/*                                                <div><strong>Rank : </strong>{x.rank}</div>*/}

                                    {/*                                                <div><a target="_blank" href={x.profile} >View Profile</a></div>*/}
                                    {/*                                            </p>*/}

                                    {/*                                        </div>*/}
                                    {/*                                    </div>*/}
                                    {/*                                </div>*/}
                                    {/*                            </div>*/}
                                    {/*                        )*/}
                                    {/*                    })*/}
                                    {/*                    : <></>*/}
                                    {/*            }*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}