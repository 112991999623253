import React, { Component, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Homepage from "../homepage/homepage";
import NotFound from "../notfound/notfound";
import AboutBaze from "../about/about-baze/aboutbaze";
import FactAndFigure from "../about/fact-and-figure/factandfigure";
import Gallery from "../about/gallery/gallery";
import OurCampus from "../about/our-campus/ourcampus";
import ITRI from "../office/itri/itri";
import ITRIDirectorate from "../office/itri/directorate";
import LeadershipBoard from "../about/leadership/leadershipboard";
import LeadershipManagement from "../about/leadership/leadershipmanagement";
import LeadershipSenate from "../about/leadership/leadershipsenate";
import ContactUs from "../about/contact-us/contactus";

import ContactTel from "../about/contact-us/contact-tel";
import News from "../news/news/news";
import NewsPage from "../news/news-page/news-page";
import BazeInThePress from "../news/baze-in-the-press/baze-in-the-press";
import Events from "../events/events/events";
import EventSingle from "../events/event-single/event-single";
import ApplicationGuide from "../admission/application-guide/application-guide";
import TuitionFees from "../admission/tuition-fees/tuition-fees";
import Accommodation from "../admission/accommodation/accommodation";
import Feeding from "../admission/feeding/feeding";
import CourseFinder from "../admission/course-finder/course-finder";
import CourseDetail from "../admission/course-detail/course-detail";
import Faculty from "../faculties/faculty/faculty";
import AcademicCalender from "../resources/academic-calender/academic-calender";
import DocumentsHandbook from "../resources/documents-handbook/deocument-handbook";
import IntercomDirectory from "../resources/intercom/intercom";
import StaffListAcadAndNonAcad from "../resources/staff-list/staff-list";
import StudentThesis from "../resources/student-thesis/student-thesis";
import StaffProfile from "../resources/staff-list/staff-profile";
import Libray from "../library/library";
import BazeEducation from "../academics/baze-education/baze-education";
import LearningGoals from "../academics/learning-goal/learning-goals";
import AcademicRegistry from "../academics/academic-registry/academic-registry";
import Publications from "../resources/staff-publication/staff-publication";
import ShortCourses from "../short-courses/short-courses";
import ShortCoursesLogin from "../short-courses/login";
import ShortCoursesDashboard from "../short-courses/dashboard";
import axios from "axios";
import { serverLink } from "../../resources/url";
import Loader from "react-loader-spinner";
import {
    setAlumniFeedbackDetails,
    setCourseListDetails,
    setCoursePageDetails,
    setEventsDetails,
    setFacultyDetails,
    setFacultyListDetails, setGalleryDetails,
    setHomepageDetails, setManagementListDetails,
    setNewsDetails,
    setRunningModules,
    setStaffListDetails,
    setStaffPublicationsAction
} from "../../actions/setactiondetailsection";
import { connect } from "react-redux";
import ViceChancelloroffice from "../office/vicechancellor/vicechancelloroffice";
import SecurityDirectorate from "../directorates/security-directorate";
import StartegyAndSpecialDuties from "../directorates/strategy-and-specialduties";
import StudentAffairs from "../directorates/student-affairs";
import DeputyViceChancellorAdmin from "../office/dvc-admin/dvc-admin";
import RegistrarOffice from "../office/registrar/registrar";
import Search from "../search/search";
import WorkShops from "../worshops/workshop";
import Survey from "../forms/feedback";
import { browserName, isMobile } from 'react-device-detect';
import ICELandingPage from "../ice-seld-event/landing";
import HumanResourcesOffice from "../office/human-resources/human-resource-office";
import ResearchAtBaze from "../research/research-at-baze";
import ColaborateWithUs from "../research/colaborate-with-us";
import ResearchCentres from "../research/research-centres";
import LawClinicLandingPage from "../law-clinic/landing";
import ResearchAreas from "../research/research-areas";
import ResearchFacility from "../research/research-facilities";
import UniResearhers from "../research/researchers";
import ResearchNews from "../research/research-news";
import { dynamicSort } from "../../resources/constants";
import CleanEnergy from "../centers/clean-energy/clean-energy";

import AIBlockChain from "../centers/ai-blockchain/ai-blockchain";
import CleanEnergyDetails from "../centers/clean-energy/details-page";
import PeaceAndAntiConflict from "../centers/peace-and-conflict/peace-and-conflict";
import FoundationAndInterDisciplinary from "../centers/foundation/foundation";
import BigData from "../centers/bigdata/big-data";
import ResearchFunding from "../research/research-funding";
import ResearchCareerDevelopment from "../research/research-career-development";
import ClubsLanding from "../clubs-and-societies/landing";
import ClubsDetails from "../clubs-and-societies/clubs-details";
import ClubsNewsAndEventsDetails from "../clubs-and-societies/clubs-news-events";
import StudentSiwes from "../admission/siwes/siwes";
import Emailunsubscribe from "../email-unsubscribe/emailunsubscribe";
function PageRoutes(props) {
    const [isLoading, setIsLoading] = useState(true);

    const getLocationData = async () => {
        await axios.get('https://geolocation-db.com/json/').then(async (result) => {
            await getVisitorData(result.data);
        }).catch((e) => {
            console.log("Error Getting User Details")
        })

    }

    const getVisitorData = async (locData) => {
        let sendData = {
            IPAddress: locData.IPv4,
            Country: locData.country_name,
            lat: locData.latitude,
            lng: locData.longitude,
            IsMobileRequest: isMobile ? "1" : "0",
            UserAgent: browserName,
        }

        await axios.post(`${serverLink}general/website/visit-hit`, sendData).then(data => {

        }).catch(err => {
            console.log("Error Adding User Details")
        });
    };

    const getFacultyList = async () => {
        if (props.courseDataList.length > 0) {
            setIsLoading(false)
        }

        try {
            let homepageData = {};
            await axios.get(`${serverLink}slider/homepage`)
                .then(result => {
                    const data = result.data;
                    if (data.length > 0) {
                        homepageData['slider'] = data
                        props.setOnHomepageDetails(homepageData);
                    }
                })
                .catch(err => {
                    console.log('NETWORK ERROR FETCHING SLIDERS', err)
                });

            await axios.get(`${serverLink}event/homepage_events`)
                .then(data => {
                    homepageData['events'] = data.data;
                    props.setOnHomepageDetails(homepageData);
                })
                .catch(error => {
                    console.log("ERROR FETCHING THE HOMEPAGE EVENTS")
                });


            await axios.get(`${serverLink}news/homepage_news`)
                .then(data => {
                    homepageData['news'] = data.data;
                    props.setOnHomepageDetails(homepageData);
                })
                .catch(error => {
                    console.log("ERROR FETCHING THE HOMEPAGE NEWS")
                });

            await axios.get(`${serverLink}gallery/list/all`)
                .then(data => {
                    props.setOnGalleryDetails(data.data);
                })
                .catch(error => {
                    console.log("ERROR FETCHING THE HOMEPAGE NEWS")
                });

            await axios.get(`${serverLink}student/alumni/feedback`)
                .then(data => {
                    props.setOnAlumniFeedbackDetails(data.data.map(obj => {
                        return { ...obj, InsertedDate: new Date(obj.InsertedDate) };
                    }).sort(
                        (objA, objB) => Number(objB.InsertedDate) - Number(objA.InsertedDate),
                    ));
                })
                .catch(error => {
                    console.log("ERROR FETCHING THE HOMEPAGE NEWS")
                });


            await axios.get(`${serverLink}general/admission_dateline`)
                .then(result => {
                    const data = result.data;
                    if (data.length > 0) {
                        homepageData['trimesterList'] = data
                        props.setOnHomepageDetails(homepageData);
                    }
                })
                .catch(err => {
                    console.log('NETWORK ERROR FETCHING ADMISSION DATELINE', err)
                });

            await axios.get(`${serverLink}leadership/list/active`)
                .then(result => {
                    const data = result.data;
                    if (data.length > 0) {
                        props.setOnLeadershipDetails(data);
                    }
                })
                .catch(err => {
                    console.log('NETWORK ERROR FETCHING ADMISSION DATELINE', err)
                });

            await axios.get(`${serverLink}course/running_modules`)
                .then(result => {
                    const data = result.data;
                    if (data.length > 0) {
                        props.setOnRunningModules(data)
                    }
                })
                .catch(err => {
                    console.log('NETWROK ERROR FETCHING RUNNING MODULES', err)
                });

            await axios.get(`${serverLink}general/homepage_student_counter`)
                .then(result => {
                    homepageData['studentCount'] = result.data.StudentCount
                    props.setOnHomepageDetails(homepageData);
                })
                .catch(err => {
                    console.log('NETWORK ERROR FETCHING STUDENT COUNT', err)
                });


            axios.get(`${serverLink}faculty/list/academics`)
                .then(result => {
                    const dataset = result.data;
                    if (dataset.length > 0) {
                        props.setOnFacultyListDetails(dataset);
                    }
                })
                .catch(error => {
                    console.log(error)
                })

            await axios.get(`${serverLink}faculty/details`)
                .then((result) => {
                    const data = result.data
                    console.log(data)
                    if (data.length > 0) {
                        props.SetOnFacultyDetails(data);
                    }
                })
                .catch(error => {
                    console.log(error)
                })

            axios.get(`${serverLink}course/list/view/all`)
                .then(result => {
                    props.setOnCourseListDetails(result.data);
                })
                .catch(error => {
                    console.log("SERVER NETWORK ERROR");
                })

            axios.get(`${serverLink}course/content/all`)
                .then(result => {
                    props.setOnCourseDataDetails(result.data);
                    setIsLoading(false)
                })
                .catch(error => {
                    console.log("SERVER NETWORK ERROR");
                })

            await axios.get(`${serverLink}news/list/active`)
                .then(result => {
                    props.setOnNewsDetails(result.data);
                })
                .catch(e => {
                    console.log('NEWS FETCH NETWORK ERROR')
                });

            await axios.get(`${serverLink}event/list/active`)
                .then(result => {
                    props.setOnEventDetails(result.data);
                })
                .catch(e => {
                    console.log('NEWS FETCH NETWORK ERROR')
                });

            await axios.get(`${serverLink}resources/pubs-publication/data`)
                .then(result => {
                    if (result.data.length > 0) {
                        props.setOnStaffPublications(result.data)
                    }
                })

            await axios.get(`${serverLink}staff/website/staff_list`)
                .then(result => {
                    props.setOnStaffListDetails(result.data)
                })
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        getLocationData();
        getFacultyList();
    }, []);

    return (
        <main id="content" style={{ paddingTop: "133px" }}>
            <div className="loading" />
            {
                isLoading ?
                    <Loader
                        type="ThreeDots"
                        color="#63656A"
                        height={200}
                        width={200}
                        className="text-center"
                    /> :
                    <Routes>
                        <Route exact path="/" element={<Homepage />} />

                        {/*General Routes*/}
                        <Route exact path="/pro-chancellor-office" element="" />
                        <Route exact path="/vice-chancellor-office" element={<ViceChancelloroffice />} />
                        <Route exact path="/human-resources-office" element={<HumanResourcesOffice />} />
                        <Route exact path="/dvc-admin-office" element={<DeputyViceChancellorAdmin />} />
                        <Route exact path="/itri-office" element={<ITRI />} />
                        <Route exact path="/itri-office/directorate" element={<ITRIDirectorate />} />
                        <Route exact path="/registrar-office" element={<RegistrarOffice />} />
                        <Route exact path="/librarian-office" element={<Libray />} />

                        {/*About Routes*/}
                        <Route exact path="/about/about-baze" element={<AboutBaze />} />
                        <Route exact path="/about/facts-and-figures" element={<FactAndFigure />} />
                        <Route exact path="/gallery" element={<Gallery />} />
                        <Route exact path="/about/leadership" element={<LeadershipManagement />} />
                        <Route exact path="/about/our-campus" element={<OurCampus />} />
                        <Route exact path="/about/our-mandate" element="" />
                        <Route exact path="/about/our-people" element="" />
                        <Route exact path="/leadership/board" element={<LeadershipBoard />} />
                        <Route exact path="/leadership/management" element={<LeadershipManagement />} />
                        <Route exact path="/leadership/senate" element={<LeadershipSenate />} />
                        <Route exact path="/about/email-unsubscribe" element={<Emailunsubscribe />} />

                        {/*Admission Routes*/}
                        <Route exact path="/admission/course-finder" element={<CourseFinder />} />
                        <Route exact path="/admission/application-guide" element={<ApplicationGuide />} />
                        <Route exact path="/admission/tuition-fees" element={<TuitionFees />} />
                        <Route exact path="/admission/accommodation" element={<Accommodation />} />
                        <Route exact path="/admission/feeding" element={<Feeding />} />
                        <Route exact path="/admission/undergraduate/:slug" element={<CourseDetail />} />
                        <Route exact path="/admission/postgraduate/:slug" element={<CourseDetail />} />

                        {/*Resources Routes*/}
                        <Route exact path="/resources/student-thesis" element={<StudentThesis />} />
                        <Route exact path="/resources/staff-publication" element={<Publications />} />
                        <Route exact path="/resources/documents-and-handbooks" element={<DocumentsHandbook />} />
                        <Route exact path="/resources/intercom-directory" element={<IntercomDirectory />} />
                        <Route exact path="/resources/past-exam-questions" element="" />
                        <Route exact path="/resources/workshops" element={<WorkShops />} />

                        {/*Academics Routes*/}
                        <Route exact path="/academics/academic-calender" element={<AcademicCalender />} />
                        <Route exact path="/academics/academic-registry" element={<AcademicRegistry />} />
                        <Route exact path="/academics/staff-list" element={<StaffListAcadAndNonAcad />} />
                        <Route exact path="/academics/staff/:slug" element={<StaffProfile />} />
                        <Route exact path="/academics/baze-education" element={<BazeEducation />} />
                        <Route exact path="/academics/learning-goals" element={<LearningGoals />} />
                        <Route exact path="/academics/short-courses" element={<ShortCourses />} />
                        <Route exact path="/academics/short-courses/login" element={<ShortCoursesLogin />} />
                        <Route exact path="/academics/short-courses/dashboard" element={<ShortCoursesDashboard />} />

                        {/*Faculties Routes*/}
                        <Route exact path="/faculty/:slug" element={<Faculty />} />

                        {/*Contact Routes*/}
                        <Route exact path="/contact/contact-us" element={<ContactUs />} />

                        <Route exact path="/contact-telephone" element={<ContactTel />} />


                        {/*Event Routes*/}
                        <Route exact path="/events/" element={<Events />} />
                        <Route exact path="/event/:slug" element={<EventSingle />} />

                        {/*News Routes*/}
                        <Route exact path="/news/" element={<News />} />
                        <Route exact path="/news/:slug" element={<NewsPage />} />
                        <Route exact path="/news/baze-in-the-press" element={<BazeInThePress />} />

                        {/*Staff Routes*/}
                        <Route exact path="/faculty-and-staff/" element="" />

                        {/*Footer Routes*/}
                        <Route exact path="/digital-privacy-statement" element="" />
                        <Route exact path="/privacy-policy" element="" />
                        <Route exact path="/website-feedback" element="" />


                        {/* Directorates */}
                        <Route exact path="/security" element={<SecurityDirectorate />} />
                        <Route exact path="/strategy-and-specia-duties" element={<StartegyAndSpecialDuties />} />
                        <Route exact path="/student-support" element={<StudentAffairs />} />
                        <Route exact path="/search/:slug" element={<Search />} />

                        <Route exact path="/survey" element={<Survey />} />

                        <Route exact path="/international-conference-and-exhibition-on-sustainable-entrepreneurship-and-leadership-development" element={<ICELandingPage />} />
                        <Route exact path="/law-clinic" element={<LawClinicLandingPage />} />


                        <Route path="*" element={<NotFound />} />
                        {/*Research */}
                        <Route exact path="/research-at-baze" element={<ResearchAtBaze />} />
                        <Route exact path="/colaborate-with-us" element={<ColaborateWithUs />} />
                        <Route exact path="/research-centres" element={<ResearchCentres />} />
                        <Route exact path="/researchers" element={<UniResearhers />} />
                        <Route exact path="/research-areas" element={<ResearchAreas />} />
                        <Route exact path="/research-facility" element={<ResearchFacility />} />
                        <Route exact path="/research-news" element={<ResearchNews />} />

                        <Route exact path="/center-for-peace-and-anti-conflict-journalism" element={<PeaceAndAntiConflict />} />
                        <Route exact path="/centre-for-foundation-and-interdisciplinary-studies" element={<FoundationAndInterDisciplinary />} />
                        <Route exact path="/center-for-clean-energy-and-climate-change" element={<CleanEnergy />} />
                        <Route exact path="/center-for-clean-energy-and-climate-change/center-leadership" element={<CleanEnergyDetails />} />
                        <Route exact path="/center-for-clean-energy-and-climate-change/ongoing-projects" element={<CleanEnergyDetails />} />
                        <Route exact path="/center-for-clean-energy-and-climate-change/journals" element={<CleanEnergyDetails />} />
                        <Route exact path="/center-for-clean-energy-and-climate-change/capacity-development" element={<CleanEnergyDetails />} />
                        <Route exact path="/center-for-clean-energy-and-climate-change/investment-and-business-promotion" element={<CleanEnergyDetails />} />
                        <Route exact path="/center-for-clean-energy-and-climate-change/knowledge-management-and-awareness-raising" element={<CleanEnergyDetails />} />





                        <Route exact path="/artificial-intelligence-and-blockchain-technology-centre" element={<AIBlockChain />} />
                        <Route exact path="/center-for-big-data-analysis-egovernance-innovation" element={<BigData />} />


                        <Route exact path="/research-funding" element={<ResearchFunding />} />
                        <Route exact path="/research/career-development" element={<ResearchCareerDevelopment />} />



                        <Route exact path="/clubs-and-societies" element={<ClubsLanding />} />
                        <Route exact path="/clubs-and-societies/:slug" element={<ClubsDetails />} />
                        <Route exact path="/:society/news/:slug" element={<ClubsNewsAndEventsDetails />} />
                        <Route exact path="/:society/event/:slug" element={<ClubsNewsAndEventsDetails />} />
                        <Route exact path="/student-industrial-work-experience" element={<StudentSiwes />} />
                    </Routes>
            }

        </main>
    )
}

const mapStateToProps = (state) => {
    return {
        courseDataList: state.BazeCoursePageDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnFacultyListDetails: (p) => {
            dispatch(setFacultyListDetails(p));
        },
        SetOnFacultyDetails: (p) => {
            dispatch(setFacultyDetails(p))
        },
        setOnCourseListDetails: (p) => {
            dispatch(setCourseListDetails(p))
        },
        setOnCourseDataDetails: (p) => {
            dispatch(setCoursePageDetails(p))
        },
        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
        setOnEventDetails: (p) => {
            dispatch(setEventsDetails(p));
        },
        setOnStaffListDetails: (p) => {
            dispatch(setStaffListDetails(p));
        },
        setOnStaffPublications: (p) => {
            dispatch(setStaffPublicationsAction(p));
        },
        setOnHomepageDetails: (p) => {
            dispatch(setHomepageDetails(p));
        },
        setOnRunningModules: (p) => {
            dispatch(setRunningModules(p))
        },
        setOnGalleryDetails: (p) => {
            dispatch(setGalleryDetails(p));
        },
        setOnAlumniFeedbackDetails: (p) => {
            dispatch(setAlumniFeedbackDetails(p));
        },
        setOnLeadershipDetails: (p) => {
            dispatch(setManagementListDetails(p));
        },

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageRoutes);
