import React, { Component } from "react";
import "./aboutbaze.css";
import Banner from "../../utils/banner/banner";
import Square from "../../../images/faculties/bau_banner.png"
import Card from "../../utils/card/card";
import { connect } from "react-redux";
import GlobeImage from "../../../images/general/globe.png";
import IdeaImage from "../../../images/general/idea.png";
import CampusImage from "../../../images/general/campus_aerial_view.png";
import CampusImage2 from "./baze_map.png";
import VirtualTour from "../../utils/virtualtour";
import Googlemap from "../../utils/googlemap";


class AboutBaze extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let faculty_list = "";
        if (this.props.facultyList.length > 0) {
            this.props.facultyList.map((faculty, index) => {
                return faculty_list += this.props.facultyList.length !== (index + 1) ? `${faculty.FacultyName}, ` : `and ${faculty.FacultyName}`
            })
        }

        return <>
            <Banner image={Square} title="About Baba Ahmed University" caption="" />

            <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 min-height-fix">
                                <div className="col-sm-12 min-height-fix">
                                    <div>
                                        <Card title="About Us" description="Baba-Ahmed University is a reputable Institution in the ancient city of Kano, Nigeria. The University was established in 2022 with the vision to nurture an academic institution that appreciates, attains and promotes excellence, professionalism and services as crucial factors for the economic and technological transformation of Nigeria.
                                        The University aims to train the required human capital to achieve this vision and improve access to university education for the ever-increasing number of qualified candidates seeking placements in universities across Nigeria.
                                        The University is well equipped with the state of the arts facilities, conducive and multicultural learning environment committed to providing quality education hence the motto – Value by knowledge. With its diverse range of programmes, the University is an attractive option for students all around the world.
                                        At the moment, the University offers a wide range of undergraduate programmes across two faculties, which are: Faculty of Science & Computing | B.Sc. Biochemistry, B.Sc. Computer Science, B.Sc. Software Engineering. And Faculty of Social & Management Sciences |B.Sc. Accounting, B.Sc. Business Administration, B.Sc. Entrepreneurship, B.Sc. Economics, B.Sc. International Relations, B.Sc. Political Science, B.Sc. Public Administration, B.Sc. Sociology. " button="" btn_link="" />                                        <Card title="Our Campus" description={`The main Campus is at phase 1, No 306, Sharada, Kano 700234, Kano. Academic activities commenced in the Academic and Administrative building, the Social Area and the Sports Complex. Baba Ahmed began in September 2022 with a Foundation class and two Faculties:  Faculty of Management and Social Sciences | Faculty of Sciences and Computing. There are several specialities within these Faculties`} button="" btn_link="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 min-height-fix">
                                <div>
                                    <Card title="Our Mission" description="To enhance the sustainable well-being of society through the training of expert human capital imbued with knowledge and commitment to valuable services." button="" btn_link="" />
                                    <Card title="Our Vision" description="To be the foremost institution for knowledge, innovation and service that adds value to society, addresses challenges and proffers solutions to all segments of human endeavour." button="" btn_link="" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 min-height-fix">
                                <div className="rounded-box grey-back">
                                    <Card image={IdeaImage} title="Technology-Based" description="Technology is fast revolutionising the world and we aim to be a part of the revolution. Our interest in the subject is reflected in our recent hosting of the event that focused on the importance of drone technology in development programmes." button="" btn_link="" />
                                    <Card image={GlobeImage} title="Diversity" description="Diversification of thought and processes is important and we tailor our curriculum to portray this quality. Our students have the knowledge-base and corresponding skills required to excel everywhere regardless of geography, cultural orientation." button="" btn_link="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Virtual tour*/}
            {/* <VirtualTour /> */}
            {/*Virtual tour*/}

            {/*<div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right">*/}
            {/*    <div className="row">*/}
            {/*        <div className="">*/}
            {/*            <div className="row">*/}
            {/*                <div className="col-sm-12 ">*/}
            {/*                    <div className="col-md-4">*/}
            {/*                        <h2>An Aerial View of Our Campus</h2>*/}
            {/*                        <p>The aerial view showcases our main facilities and infrastructure that are designed and constructed to ensure that students acquire quality education in an environment that is conducive and comfortable for their advancement in knowledge and thought. With facilities ranging from sports and recreations, malls to religious centres, our students have all of their needs met without stepping outside the campus grounds.</p>*/}
            {/*                        <p>In addition to our sound and first-rate buildings, we have employed and acquired highly experienced individuals that will ensure that these facilities are put to the best and most productive use. These individuals - ranging from the academic staff (who are visionaries of the education system) to the security guards and cleaning services - are dedicated to the provision of the best services available to guarantee that standards set forth by the University's board are exceedingly met.</p>*/}
            {/*                        /!* <p><a className="btn btn-default btn-ghost" target="_blank"*/}
            {/*                            href="https://portal.babaahmeduniversity.edu.ng/tour/index.html" role="button">Campus tour</a></p> *!/*/}
            {/*                    </div>*/}
            {/*                    <div className="col-md-8">*/}
            {/*                        <Googlemap height={600} />*/}
            {/*                    </div>*/}
            {/*                  */}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </>

    }

}
const mapStateToProps = (state) => {
    return {
        facultyList: state.BazeFacultyListDetails
    };
};
export default connect(mapStateToProps, null)(AboutBaze);
