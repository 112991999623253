import React, { useState } from "react";
import "./emailunsubscribe.css"
import Banner from "../utils/banner/banner";
import Square from "../../images/faculties/bau_banner.png"
import { connect } from "react-redux";
import { EmailTemplates, sendEmailAdm, showAlert } from "../../resources/constants";


const EmailUnsubscribe = () => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
    })

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        })
    }

    const unsubscribe = async (e) => {
        e.preventDefault();
        if (formData.email.trim() === "") {
            showAlert("error", "please enter email address", "error")
            return false;
        }
        setLoading(true);
        try {
            const template = EmailTemplates('4', "")
            sendEmailAdm(formData.email, template.title, template.subject, template.body, "", "")
            showAlert("success", "Mail sent successfully", "success");
        } catch (e) {
            console.log(e)
            setLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }

    return (
        <>
            <Banner image={Square} title="Email Unsubscribe Request" caption="" />

            <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 min-height-fix">
                                <div className="rounded-box grey-back">
                                    <form onSubmit={unsubscribe} className="col-md-12">
                                        <div className="col-md-6">
                                            <label htmlFor="email">Email Address</label>
                                            <input
                                                className="form-control col-md-6"
                                                type="email"
                                                id="email"
                                                name="email"
                                                required
                                                onChange={onEdit}
                                                value={formData.email}
                                                style={{ display: 'block', marginTop: '-10px' }}
                                            />
                                        </div>
                                        <button type="submit" className="col-md-6 btn btn-primary" style={{ display: 'block', marginTop: '30px' }}>Send</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        facultyList: state.BazeFacultyListDetails
    };
};
export default connect(mapStateToProps, null)(EmailUnsubscribe);
