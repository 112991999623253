import React, { useState } from "react";
import Banner from "../../utils/banner/banner";
import "../../admission/application-guide/application-guide.css"
import { InterComList } from "./intercomList";
import 'react-data-table-component-extensions/dist/index.css';
import { serverLink } from "../../../resources/url";
import axios from "axios";
import '../staff-list/stafflist.css'
import Intercombanner from '../intercom/Intercom_banner.webp'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { toTitleCase } from "../../../resources/constants";
import { useEffect } from "react";

const IntercomDirectory = () => {

    const [interCom, setInterCom] = useState(InterComList);
    const [dt, setDt] = useState([])

    const cols = [
        {
            name: "S/N",
            cell: (row, index) => (
                <span>
                    {index + 1}
                </span>
            ),
            width: '8%'
        },
        {
            name: "Extension No",
            selector: row => `${toTitleCase(row.ExtensionNo)}`,
            sortable: true,
            width: '15%'
        },
        {
            name: "Office",
            selector: row => `${toTitleCase(row.Office)}`,
            sortable: true,
            width: '30%'
        },
        {
            name: "Room No.",
            selector: row => `${toTitleCase(row.RoomNo)}`,
            sortable: true,
            width: '12%'
        },
        {
            name: "Block",
            selector: row => `${toTitleCase(row.Block)}`,
            sortable: true,
            width: '35%'
        }
    ]



    const getIntercomList = async () => {
        await axios.get(`${serverLink}resources/intercom/list`)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    setInterCom(data)
                    setDt(data)
                }
            })
    }
    // const handleSearch = (e) => {
    //     const filtered = InterComList.filter(x =>
    //         x.Block.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()) ||
    //         x.ExtensionNo.toString().toLocaleLowerCase().includes(e.target.value.toString().toLocaleLowerCase()) ||
    //         x.Office.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()) ||
    //         x.RoomNo.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())
    //     )
    //     setDt(filtered)
    // }


    useEffect(() => {
        getIntercomList()
    }, [])

    const columns = cols;
    const data = dt.sort();
    const tableData = {
        columns,
        data,
        print: false,
        exportHeaders: true,
        filterPlaceholder: 'Search',
    };

    return <>
        <Banner image={Intercombanner} title="Intercom Directory" caption="" />

        <div className="container-fluid layout col1" style={{ margin: "10px" }}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="layout-blocks-ucws-checklist container-fluid roundable block checklist">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="layout-col col1 layout-12 col-sm-12">
                                            <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="text-justify">
                                                            <div className="table-responsive">
                                                                {/* <div style={{ background: '#f2f2f0', padding: '8px', borderRadius: '5px' }}>
                                                                    <input name="search" placeholder="search" className="textBox" onChange={handleSearch} />
                                                                </div> */}


                                                                <DataTableExtensions id="tt"
                                                                    {...tableData}
                                                                >
                                                                    <DataTable
                                                                        noHeader
                                                                        defaultSortField="id"
                                                                        defaultSortAsc={false}
                                                                        pagination={false}
                                                                        highlightOnHover />
                                                                </DataTableExtensions>
                                                                {/* <table className="table table-bordered">
                                                                    <tbody>
                                                                        <tr style={{ background: 'grey', color: 'white' }}>
                                                                            <td><h5>S/N</h5></td>
                                                                            <td><h5>Extension No</h5></td>
                                                                            <td><h5>Office Location</h5></td>
                                                                            <td><h5>Room No</h5></td>
                                                                            <td><h5>Block</h5></td>
                                                                        </tr>
                                                                        {InterComList.length > 0 ?
                                                                            <>
                                                                                {interCom.map((x, y) => {
                                                                                    return (
                                                                                        <tr key={y}>
                                                                                            <td><h5>{y + 1}</h5></td>
                                                                                            <td><h5>{x.ExtensionNo}</h5></td>
                                                                                            <td><h5>{x.Office}</h5></td>
                                                                                            <td><h5>{x.RoomNo}</h5></td>
                                                                                            <td><h5>{x.Block}</h5></td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                            : <></>}
                                                                    </tbody>
                                                                </table> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <hr className="solid light-grey" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid layout col1">
            <div className="row">
                <div className="layout-col col1 layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-info-shim container-fluid roundable block info-shim">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="more-info-line">
                                            <div className="more-info-icon">
                                                <span className="icon icomoon ucws-info-shim-info light-grey"
                                                    aria-hidden="true"></span> Did you know Baba Ahmed University Admission system is always open?
                                                <a href="https://portal.bazeuniversity.edu.ng/admission"
                                                    target="_blank" rel="noopener"> Apply here</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </>
}


export default IntercomDirectory;
