import React, {Component} from "react";
import CampusImage from "../../../images/general/campus_images.png";
import Banner from "../../utils/banner/banner";
import OurCampusImg from "../../../images/general/bau_banner.png";
import Googlemap from "../../utils/googlemap";

class OurCampus extends Component {
    render() {
        return <>
            <Banner image={OurCampusImg} title="Our Campus" caption="Baba Ahmed University delivers quality through experienced international staff, superb teaching equipment, overseas external examiners, and first-rate buildings to guarantee standards. Baba Ahmed University aims to provide university education to British standards in Nigeria at about half the cost of sending a student to study abroad"/>

            <div className="container-fluid layout col2-50-50" style={{margin: "10px"}}>
                <div className="row">
                    <div className="container">
                        <div className="row">


                            <div className="layout-col col2-50 layout-6 col-md-6">

                                <div className="layout-blocks-ucws-image container-fluid roundable block image">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="image-wrapper">
                                                        <div className="rounded-image">
                                                            <picture>
                                                                {/*<img*/}
                                                                {/*    loading="lazy"*/}
                                                                {/*    height={'550px'}*/}
                                                                {/*    src={CampusImage}*/}
                                                                {/*    title="Baba Ahmed University Campus"*/}
                                                                {/*    alt="Baba Ahmed University Campus"/>*/}
                                                            </picture>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="layout-col col2-50 layout-6 col-md-6">


                                <div className="layout-blocks-ucws-thumbnail container-fluid roundable block thumbnails">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12 media">
                                                    <div className="media-body">
                                                        <h3>About our campus</h3>
                                                        <p className="text-justify">The main campus of the university is located phase 1, No 306, Sharada, Kano 700234, Kano. The campus has a collection of state-of-the-art infrastructure and facilities that help provide an adequate and conducive environment for learning. With a multitude of school blocks and comfortable classes, studios, and laboratories designed to improve interactivity and foster all forms of cognitive learning. Beyond academia, the school has facilities such as the mall, and cafeteria to ensure students receive adequate care and provisions without leaving the campus grounds.</p>
                                                        <h3>Address</h3>
                                                        <span>phase 1, No 306, Sharada, Kano 700234,</span> <br/>
                                                        <span>Kano,</span> <br/>
                                                        <span>Nigeria.</span>
                                                        <p>Phone: 234 7032352575</p>

                                                    </div>
                                                </div>
                                                <div className="clearfix hidden-xs"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="container-fluid layout col2-50-50">*/}
            {/*    <Googlemap height={600} />*/}
            {/*</div>*/}


        </>;
    }
}

export default OurCampus;
