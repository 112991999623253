import React from 'react'
import Banner from '../../utils/banner/banner'
import { isMobile } from 'react-device-detect'
import './big-data.css';
import { Link } from 'react-router-dom';
import { MembersData } from './big-data-members';

export default function BigData() {
    return (
        <div>
            <Banner image={require("../images/big_data_1.png")} title="CCECC" caption="" disableTitle />

            <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right mt-5" style={{ marginTop: "30px" }}>
                <div className="row">
                    <div className="col-sm-12 ">
                        <div className={isMobile ? "col-md-12" : "col-md-9"}>
                            <p>
                            Baba Ahmed University Centre for Big Data Analytics, Innovation and eGovernance is a specialist Centre for knowledge transfer (training), research and consultancy. We aim to build capacity in the critical fields of Big Data Analytics e-Governance and Innovation.  The inspiration for the Baba Ahmed University Centre Centre for Big Data Analytics, Innovation and eGovernance came from the launch of the Nigerian National Cluster Groups on Big Data Analytics which held its inaugural meeting and conference at Baba Ahmed University in November 2014.
                            </p>
                            <p>
                            Baba Ahmed University is a founding member of the national cluster groups and houses the Big Data, ICT & E-Government cluster. The university also hosted the 2nd conference of the cluster groups in November 2015, 2016, 2018, 2019, 2020 and 2021. The mandates of this centre include areas of training and capacity-building, consultancy and research, and enterprise. 
                            </p>
                            <p>The centre belongs to multiple academic disciplines which are: Data Analytics IT, Innovation and Entrepreneurship
                            </p>
                            <p>The centre has 7 Permanent Research Fellows, 11 Research Personnel, 3 Technical Staff and 6 Administrative Staff. The head of the center is Dr. Rislan Kanya who has a PhD and is a senior lecturer and DVC IT, Research and Innovation</p>
                        </div>
                        {
                            !isMobile &&
                            <div className="col-md-3">
                                <img
                                    style={{ borderRadius: '10px', maxHeight: "300px" }}
                                    className="img-responsive-top"
                                    loading="lazy"
                                    src={require('./itri_vc.jpeg')}
                                    alt={'Dr. Rislan Kanya'}
                                    title={'Dr. Rislan Kanya'}
                                    typeof="foaf:Image" />
                                <div className='row text-center'>
                                    <h4>Dr. Rislan A. Kanya</h4>
                                    <h5>DVC IT, Research and Innovation (The head of the center)</h5>
                                </div>
                            </div>
                        }
                        <div className='col-md-12'>
                            <iframe width="100%" height="450"
                                src="https://www.youtube.com/embed/bY6ZzQmtOzk">
                            </iframe>
                        </div>

                        <div className="col-lg-12 mt-5" style={{ marginTop: "30px" }}>
                            <div className="layout-col col1 layout-12 col-sm-12">
                                <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                    <h3 className='text-left'>Other Staff of the Centre</h3>

                                    <div className="table-responsive" style={{ marginTop: "30px" }}>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <td>Name</td>
                                                    <td>Rank</td>
                                                    <td>Highest Qualification</td>
                                                    <td>Area of Specialization</td>
                                                    <td>Profile</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    MembersData.length > 0 &&
                                                    MembersData.map((x, y) => {
                                                        return (
                                                            <tr>
                                                                <td>{x.name}</td>
                                                                <td>{x.rank}</td>
                                                                <td>{x.highest}</td>
                                                                <td>{x.area}</td>
                                                                <td><a href={x.profile} target='_blank'>{x.profile}</a></td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className='col-md-12' style={{ marginTop: "40px" }}>
                            <div className='row'>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-10 text-center">
                                            <h3><i className='fa fa-envelope-o' />&nbsp;Email</h3>
                                            <p>bigdata@bazeuniversity.edu.ng</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-10 text-center">
                                            <h3><i className='fa fa-mobile' />&nbsp;Telephone</h3>
                                            <p>08035502270</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-10 text-center">
                                            <h3>MOU</h3>
                                            <a href='http://www.conference.bigdatanigeria.org'>conference.bigdatanigeria.org</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
