import React, { useEffect, useState } from "react";
import '../faculties/faculty/faculty.css'
import { connect } from "react-redux";
import { LibraryStaff } from "./libraryStaff";
import '../resources/staff-list/stafflist.css'
import {
    setEventsDetails,
    setFacultyDetails,
    setFacultyListDetails,
    setNewsDetails,
    setNewsPageDetails, setStaffListDetails
} from "../../actions/setactiondetailsection.js";
import Loader from "react-loader-spinner";
import axios from "axios";
import { serverLink } from "../../resources/url";
import NewsSection from "../news/newssection";
import EventSection from "../events/eventsection/eventsection";
import Banner from "../utils/banner/banner";
import HRLine from "../utils/hrline";
import Librarybanner from './libraryBanner.jpg'

function Library(props) {

    const { slug } = "useParams();"


    const [libraryStaff, setlibraryStaff] = useState(
        props.staffList.length > 0 ?
            props.staffList.filter(x => x.DepartmentID === "11")
            : LibraryStaff);

    const [staffList, setStaffList] = useState(props.staffList);
    const getFacultyData = async () => {
        await axios
            .get(`${serverLink}faculty/list/academics`)
            .then((response) => {
                const data = response.data;
                const faculty_data = data.filter((item) => item.Slug === slug);
                props.setOnFacultyListDetails(response.data);

            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios.get(`${serverLink}news/list/active`)
            .then(result => {
                props.setOnNewsDetails(result.data);
            })
            .catch(e => {
                console.log('NEWS FETCH NETWORK ERROR')
            });

        await axios.get(`${serverLink}event/list/active`)
            .then(result => {
                props.setOnEventDetails(result.data);
            })
            .catch(e => {
                console.log('NEWS FETCH NETWORK ERROR')
            })
    };

    useEffect(() => {
        getFacultyData();
    }, []);

    const handleSearch = (e) => {
        const val = e.target.value
        let current_data = LibraryStaff.filter(item => item.name.toLowerCase().includes(val.toLowerCase()) ||
            item.email.toLowerCase().includes(val.toLowerCase()))
        setlibraryStaff(current_data)
    }

    return libraryStaff.length === 1 ?
        <Loader
            type="ThreeDots"
            color="#63656A"
            height={200}
            width={200}
            className="text-center"
        /> : (
            <>
                <Banner image={Librarybanner} title={'University Librarian'} caption="Welcome to the Baba Ahmed University Library" ></Banner>

                <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                    <div className="row">
                        {/*<div className="layout-col col1 layout-12 col-sm-12">*/}
                        {/*    <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">*/}
                        {/*        <div className="row">*/}
                        {/*            <div className="container text-justify">*/}
                        {/*                <div className="row header">*/}
                        {/*                    <div className="col-sm-4">*/}
                        {/*                        <img width={'100%'} src={require("./librarian.png")}*/}
                        {/*                            alt={'Baze library'} />*/}
                        {/*                    </div>*/}
                        {/*                    <div className="col-sm-8">*/}
                        {/*                        <span>Welcome to Baba Ahmed University Library, the repository of human knowledge, where you can conductively learn to live. Baba Ahmed University Library collection is designed to support your study, learning, research, and leisure. It is well stocked and well-equipped with current books and journals that has given the University a competitive edge among equals. It is the responsibility of the University Library to select, acquire, process, organize, disseminate, and preserve relevant materials to support the Curricula, and the programmes accredited by the National Universities’ Commission. Similarly, the Library ensures it creates a favourable environment conducive for learning, teaching, research, and leisure. In order to succeed in a standard-quality setting like Baba Ahmed University, you must determine to apply yourself seriously to your studies and use wisely, the enormous Library resources assembled to facilitate your education here at Baba Ahmed University. The Library staff will assist you to locate, select and obtain the germane Information you need to enhance your studies.<br />*/}
                        {/*                            Baba Ahmed University Library resources wholly support Baba Ahmed University Vision and Mission to establish and maintain a most suitable academic environment for imparting knowledge to modernize Nigeria and the world at large. Please take maximum advantage of the Library, to develop your intellect. Again, welcome to Baba Ahmed University, the citadel of knowledge where you will learn to live.</span>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>

                <div className="information-boxes module">
                    <div className="newadmission-info col-md-12 col-sm-12">
                        <div className="courses-info col-md-4 col-sm-12">
                            <div className="">
                                <h4 style={{ color: "#fff" }}>
                                    <i className="fa fa-book" aria-hidden="true" />Mission statement
                                </h4>
                                <ul className="no-bullet">
                                    <li>The vision of the Baba Ahmed University Library is to create and develop an excellent world-class library resource, second to none in support of the curricula and programmes offered by the University.
                                    </li>
                                </ul>
                                <div className="clearfix" />
                            </div>
                        </div>

                        <div className="newadmission-info col-md-4 col-sm-12">
                            <div className="">
                                <h4 style={{ color: "#fff" }}><i className="fa fa-eye" aria-hidden="true" /> Vision Statement</h4>
                                <ul className="no-bullet text-center">
                                    <li className="text-center">The Mission of Baba Ahmed University Library is to build and develop a current, relevant and quality collection of books and journals in print and e-formats deployed for use via the latest Information technologies of all times and skilled professionals.
                                        <ul>
                                            <li>To participate in collaborative and cooperative endeavours of exchange networks designed for cheaper access to latest library resources at national and international levels</li>
                                            <li>To encourage innovation and capitalize on appropriate Information Technologies to forge effective partnerships</li>
                                        </ul>
                                    </li>
                                </ul>
                                <div className="clearfix" />
                            </div>
                        </div>
                        <div className="faculty-info col-md-4 col-sm-12">
                            <div className="">
                                <h4 style={{ color: "#fff" }}><i className="fa fa-clock-o fa-lg" aria-hidden="true" />&nbsp;Opening Hours</h4>
                                <ul className="no-bullet">

                                    <li><i className="fa fa-check-square-o" aria-hidden="true" />
                                        Justice M. L. Uwais Law Library: (Main Library)
                                        <ul>
                                            <li>Modays - Friday (8:00am - 10:00pm daily)</li>
                                            <li>Saturdays (10:00am to 8:00pm)</li>
                                            <li>Sundaay (12:00pm to 6:00pm)</li>
                                        </ul>
                                    </li>
                                    <hr style={{ color: 'white', height: '2px', backgroundColor: 'white', borderBlockColor: 'white' }} />
                                    <div className="clearfix" />
                                    <li><i className="fa fa-check-square-o" aria-hidden="true" />
                                        All other libraries
                                        <ul>
                                            <li>Modays - Friday (8:00am - 10:00pm daily)</li>
                                        </ul>
                                    </li>
                                    <a target={'_blank'} href="https://www.library.babaahmeduniversity.edu.ng/infores.php">
                                        <button className="btn btn-secondary">
                                            Browse our catalogue
                                        </button>
                                    </a>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>

                <div className="container-fluid layout col1">
                    <div className="row">
                        <div className="layout-col col1 layout-12 col-sm-12">
                            <div className="layout-blocks-ucws-accordion container-fluid roundable block accordion border">
                                <div className="row">
                                    <div className="container">
                                        <div className="row" style={{ marginTop: '30px' }}>
                                            <div className="col-sm-12">
                                                <HRLine />
                                                <div className="panel-group" id="accordion1585270170" role="tablist"
                                                    aria-multiselectable="true">
                                                    <div className="panel panel-default">
                                                        <div className="panel-heading" role="tab" id="heading733025994">
                                                            <h4 className="panel-title">
                                                                <a className="collapsed" role="button" data-toggle="collapse"
                                                                    href="#collapseApplicationBasic">Organizational Structure
                                                                    <span aria-hidden="true"><span className="ucws-icon ucws-icon-chevron"></span></span>
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id="collapseApplicationBasic" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading733025994" aria-expanded="false" style={{ height: '0px' }}>
                                                            <div className="panel-body">
                                                                <div className="text-justify">
                                                                    The University Librarian is the academic and administrative head of the University Library. He is assisted by other academic Librarians, para-professionals and non-academic staff to effect the mandate of the University Library as set in the University Statutes. As a Principal Officer of the University, the University Librarian is responsible for the overall development of the University Library, and reports to the Vice Chancellor of the University.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="panel panel-default">
                                                        <div className="panel-heading " role="tab" id="teaching">
                                                            <h4 className="panel-title">
                                                                <a className="collapsed" role="button" data-toggle="collapse"
                                                                    href="#teachingR" aria-expanded="true"
                                                                    aria-controls="teachingR"
                                                                    data-ucws-analytics-toggle=""
                                                                    data-ucws-analytics-category="Accordion"
                                                                    data-ucws-analytics-label="teach outside Alberta"
                                                                    data-ucws-analytics-off-class="collapsed">Brief History<span aria-hidden="true"><span className="ucws-icon ucws-icon-chevron"></span></span>
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id="teachingR" className="panel-collapse collapse " role="tabpanel" aria-labelledby="teaching">
                                                            <div className="panel-body">
                                                                <div className="text-justify">
                                                                    The history of Baba Ahmed University Library and its collection development parallels the history of Baba Ahmed University itself. The Library was established along with the University in 2022. As the institution grew and the Faculties came on board in accordance with its strategic plan, the scope and nature of the Library’s collections up scaled, became more physically distributed, and expanded in complexity and richness according to disciplines in variant formats. In addition to acquiring and organization of books, journals, research reports, preprints and a variety of electronic materials, the Library has provided academic leadership in attaining the full life cycle of these intellectual resources through the selection, acquisition, processing, maintenance, analysis, preservation, and dissemination of the cumulative intellectual repositories of human knowledge to its users. From the commencement, Baba Ahmed University Management had given priority to building an ultra-modern world-class Library, bearing in mind that a university is as good as its Library – ‘the Library being the heart-beat of any academic institution’.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="panel panel-default">
                                                        <div className="panel-heading " role="tab" id="heading614380467">
                                                            <h4 className="panel-title">
                                                                <a className="collapsed" role="button" data-toggle="collapse"
                                                                    href="#collapseApplicationProcess" aria-expanded="true"
                                                                    aria-controls="collapseApplicationProcess"
                                                                    data-ucws-analytics-toggle=""
                                                                    data-ucws-analytics-category="Accordion"
                                                                    data-ucws-analytics-label="Studied outside Alberta"
                                                                    data-ucws-analytics-off-class="collapsed">Accessing Library Resources<span aria-hidden="true"><span className="ucws-icon ucws-icon-chevron"></span></span>
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id="collapseApplicationProcess" className="panel-collapse collapse " role="tabpanel" aria-labelledby="heading614380467">
                                                            <div className="panel-body">
                                                                <div className="text-justify">
                                                                    Students and Staff with a valid Baba Ahmed University Identity Card must register with the library in order to gain online access to the University Library resources for loan and other privileges. External visitors, researchers, students from other institutions interested in consulting the Library collections may apply for access by contacting the University Librarian Monday to Friday between the hours of 9 a.m. to 5 p.m.<br />

                                                                    The six Faculty based Libraries in the complex are:

                                                                    <ul style={{ listStyle: 'circle' }}>
                                                                        <li>Justice M.L Uwais Law Library (Main Library)</li>
                                                                        <li>U.A. Mutallab Library of Management and Social Sciences</li>
                                                                        <li>Hassan Tukur Computing and Applied Sciences Library</li>
                                                                        <li>Attahiru Jega Engineering Library</li>
                                                                        <li>Brig Abba Kyari (rod.) Basic and Medical Sciences Library</li>
                                                                        <li>Aliyu Mohammed Environmental Sciences Library</li>
                                                                    </ul>
                                                                    Each of these Libraries is manned by professional Librarians on a two-shift system basis for effective and smooth operation. The Law Library serves as the main or Central Library for now. The Law Library is well equipped and opens for 52 hours a week.<br /> The Library complex has a unified Online Public Access Catalogue (OPAC) with the following service Divisions and sections:
                                                                    <ul style={{ listStyle: 'circle' }}>
                                                                        <li>Reference Services</li>
                                                                        <li>Reader Services</li>
                                                                        <li>Collection Development</li>
                                                                        <li>Serials and Periodicals</li>
                                                                        <li>E-Library</li>
                                                                        <li>Special Collections</li>
                                                                        <li>Reprography</li>
                                                                        <li>Printing and Bindery</li>
                                                                        <li>Indexing and Abstracting Services</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="panel panel-default">
                                                        <div className="panel-heading " role="tab" id="heading6143804675">
                                                            <h4 className="panel-title">
                                                                <a className="collapsed" role="button" data-toggle="collapse"
                                                                    href="#collapseApplicationProcess5" aria-expanded="true"
                                                                    aria-controls="collapseApplicationProcess5"
                                                                    data-ucws-analytics-toggle=""
                                                                    data-ucws-analytics-category="Accordion"
                                                                    data-ucws-analytics-label="Studied"
                                                                    data-ucws-analytics-off-class="collapsed">Library Services<span aria-hidden="true"><span className="ucws-icon ucws-icon-chevron"></span></span>
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id="collapseApplicationProcess5" className="panel-collapse collapse " role="tabpanel" aria-labelledby="heading6143804675">
                                                            <div className="panel-body">
                                                                <div className="text-justify">
                                                                    Reference and Information assistance is available in person at the information desk or by phone. The Library offers Reference and Circulation services, Digital databases Collection Development, Online searches, Research Counseling, Bibliographies, dedicated e-library workstations, institutional wi-fi facilities for printing, reprography and scanning of bibliographic materials, and document-delivery services. The Library also offers Current Awareness Services (CAS) Selective Dissemination of Information (SDI), Indexing and abstracting services, book review, bindery services amongst others.
                                                                    <h3>Digital Library Resources</h3>
                                                                    The e-library of the University is a major resource of the library with over 60 dedicated computers for students and staff use. The University Library website provides links to these numerous digital resources both paid and Open Access, which include, but not limited to: E-Granary, Lexis Nexis, Hine Online, Law Pavilion, Legalpedia, Elsevier, Library Genesis, OECD, Science Direct, IEEE, Z-Library, IJSER, Tailor & Frances, Bold scholar, EBSCOHOST, AGORA, ebrary, DOAJ, CORE, DOAB, African Journals Online (AJOL), PDF, Google Scholar, Scopus, NUC Virtual Library, Library on a Flash (LOAF) etc., You can access and download from these databases hundreds of current and back-issues of many peer-reviewed e-Journals and e-books of Nigerian and international sources for your research.
                                                                    <h3>Online Public Access Catalogue (OPAC)</h3>
                                                                    <span>The Library OPAC contains all the print collections of the library and it is hosted on KOHA, an online Integrated Library System. KOHA provides you the opportunity to browse the library collections online campus-wide, make reservations, and manage your user account. You must register with the Library with your University I.D. to gain access. The Library staff can only give loans to users who have registered and activated their membership account with the Library. Our online catalogue is searchable from the library website: http://library@bazeuniversity.edu.ng. Licensed electronic resources can only be accessed from the Baba Ahmed University Intranet. New books are displayed regularly on new arrival shelves and shown on the Library website. Reprography and Guided Tours: The Library is equipped with a laser printer and a multifunction printer. Printing and scanning are enabled for all Library users. Library Tour and Orientation and guided tours of the Library are available upon request.</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid layout col1">
                    <div className="row">
                        <div className="layout-col col1 layout-12 col-sm-12">

                            {/*<div className="layout-blocks-ucws-profile-list container-fluid roundable block profile-list views-block keyword-filters dropdown-filters">*/}
                            {/*    <div className="row">*/}
                            {/*        <div className="container">*/}
                            {/*            <div className="row header">*/}
                            {/*                <div className="col-sm-12">*/}
                            {/*                    <div className="equal-height">*/}
                            {/*                        <div className="text">*/}
                            {/*                            <h2 style={{ color: `grey` }}>Library Staff</h2>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            <div className="row divider">*/}
                            {/*                <div className="col-sm-12">*/}
                            {/*                    <hr />*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div className="row profile view-output hide_profile_photos hide_research_interests hide_unit_department">*/}

                            {/*                <div className="view-output-form-results"*/}
                            {/*                    data-once="ajax-pager">*/}
                            {/*                    <div className="row filters">*/}
                            {/*                        <div className="col-sm-12">*/}
                            {/*                            <form className="views-exposed-form bef-exposed-form">*/}
                            {/*                                <div className="js-form-item form-item js-form-type-textfield form-item-search-api-fulltext js-form-item-search-api-fulltext">*/}
                            {/*                                    <label htmlFor="edit-search-api-fulltext">Keywords</label>*/}
                            {/*                                    <input className="textBox"*/}
                            {/*                                        type="text" id="search" onChange={handleSearch}*/}
                            {/*                                        maxLength="128" />*/}
                            {/*                                </div>*/}
                            {/*                            </form>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}

                            {/*                    <div className="row divider">*/}
                            {/*                        <div className="col-sm-12">*/}
                            {/*                            <hr />*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="profile-view-container">*/}
                            {/*                        <ol className="profile-items-list no-bullet-display">*/}
                            {/*                            {*/}
                            {/*                                libraryStaff.length > 0 ?*/}
                            {/*                                    libraryStaff.map((staff, index) => {*/}
                            {/*                                        return <li key={index} className="profile">*/}
                            {/*                                            <p className="title" style={{ color: 'grey' }}>*/}
                            {/*                                                {staff.staff_name}*/}
                            {/*                                            </p>*/}
                            {/*                                            <div className="">*/}
                            {/*                                                <div className="" style={{ fontWeight: 'bold' }}>*/}
                            {/*                                                    <div className="job-title">{staff.Position}</div>*/}
                            {/*                                                </div>*/}
                            {/*                                            </div>*/}
                            {/*                                            <a style={{ fontSize: '12px' }} href={`mailto:${staff.email}`}>{staff.email}</a>*/}
                            {/*                                        </li>*/}
                            {/*                                    }) : <div className="col-md-12">*/}
                            {/*                                        No Record Found*/}
                            {/*                                    </div>*/}
                            {/*                            }*/}


                            {/*                        </ol>*/}
                            {/*                    </div>*/}

                            {/*                </div>*/}

                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}


                        </div>
                    </div>
                </div>



                {
                    props.newsListData.length > 0 && <NewsSection color='grey' news={props.newsListData.filter(item => item.Title.toLowerCase().includes('library'))} />
                }

                {
                    props.eventsData.length > 0 && <EventSection color={'grey'} page="home" events={props.eventsData.filter(item => item.Title.toLowerCase().includes('library'))} />
                }



                <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                    <div className="row">
                        <div className="layout-col col1 layout-12 col-sm-12">
                            <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                <div className="row">
                                    <div className="container text-justify">
                                        <div className="row header">
                                            <div className="col-sm-12">
                                                <div className="row" style={{ backgroundColor: `grey`, color: "#fff", transition: "background-color 500ms ease" }}>
                                                    <div className="container">
                                                        <div className="row cta-wrapper">
                                                            <div className="cta-content text-center">
                                                                <h2>Contact Us</h2>
                                                                <div className="btn-wrapper">
                                                                    <a className="btn btn-default btn-cta btn-ghost" href={`mailto:library@bazeuniversity.edu.ng`} role="button"><i className="fa fa-envelope-o" /></a>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
}

const mapStateToProps = (state) => {
    return {
        facultyList: state.BazeFacultyListDetails,
        facultyData: state.BazeFacultyDetails,
        newsListData: state.BazeNewsDetails,
        eventsData: state.BazeEventDetails,
        courseList: state.BazeCourseListDetails,
        staffList: state.BazeStaffListDetails
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setOnFacultyListDetails: (p) => {
            dispatch(setFacultyListDetails(p));
        },
        setOnFacultyDataDetails: (p) => {
            dispatch(setFacultyDetails(p))
        },
        setOnNewsSingleDetails: (p) => {
            dispatch(setNewsPageDetails(p));
        },
        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
        setOnEventDetails: (p) => {
            dispatch(setEventsDetails(p));
        },
        setOnStaffListDetails: (p) => {
            dispatch(setStaffListDetails(p));
        },
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Library)
