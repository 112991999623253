import React from "react";
import Banner from "../../utils/banner/banner";
import education from "../../../images/general/Publications.jpeg";
import {serverLink} from "../../../resources/url";
import "../style.css"
import {Link} from "react-router-dom";

function BazeEducation () {
    return <>
        <Banner image={education} title="Baba Ahmed University Education" caption=""/>
        <div className="container-fluid layout col1" style={{margin: "20px"}}>
            <div className="row">
                <div className="layout-col layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-text container-fluid roundable block text">
                        <div className="row">
                            <div className="container">
                                <div className="row left">
                                     <div className="col-sm-9 ">
                                        <h2>Our liberal education expands minds</h2>
                                        <p style={{textAlign: 'justify'}}>Baba Ahmed University core curriculum consists of courses that help students develop the ethics, innovativeness, critical thinking and communication skills necessary for a lifetime career of leadership. By teaching students to explore the connections between different information, separate the relevant from the irrelevant, question assumptions and analyse alternatives, the liberal core serves as a foundation for success and influencing the world.</p>
                                        <p style={{textAlign: 'justify'}}>Baba Ahmed University delivers quality by having experienced international staff, superb teaching equipment, overseas external examiners, and first-rate buildings to guarantee standards. Baba Ahmed University aims to provide university education to British standards in Nigeria at about half the cost of sending a student to study abroad.</p>

                                         <h3>Small class sizes, amazing professors</h3>
                                        <p style={{textAlign: 'justify'}}>Class sizes at Baba Ahmed University are kept small, because we believe the best learning takes place when students are able to have deep conversations with their classmates and professors in and out of the classroom. Our classes are full of energy, and students are encouraged to speak up, share ideas and ask critical questions.</p>
                                        <p style={{textAlign: 'justify'}}>Our professors care deeply about the success of the students in their classrooms. As leading voices in their areas of expertise, students will find that professors bring strong inspiration to Baba Ahmed University classes.</p>

                                         <h1>Degrees</h1>
                                         <hr/>
                                         <h3>Applied Science</h3>
                                        <p style={{textAlign: 'justify'}}>Applied Science is a new degree offering skills and practical experience in subjects including biology, chemistry, laboratory practice and instrumentation and focusses on recent advances in a range of scientific fields. At Baba Ahmed University, you will learn a range of laboratory techniques used in disciplines such as Microbiology, Biochemistry, Chemistry and Molecular Biology. On completion of the degree you will have gained experience and skills required for employment in industries such as biotechnology, food and drink, microbiology, environmental protection and scientific research</p>

                                         <h3>Business Administration</h3>
                                        <p style={{textAlign: 'justify'}}>Baba Ahmed University business programme depends on the foundation set by the core liberal curriculum, and prepares students to be leaders who can recognise opportunities in the needs of society and build businesses to help solve them. The business programme takes students through global business practices, with a focus on Africa’s dynamic economy. Class projects are done in partnership with local businesses in order to help students learn practical skills through solving real-world problems.</p>

                                         <h3>Computer Science and IT</h3>
                                        <p style={{textAlign: 'justify'}}>Baba Ahmed University Computer Science related programmes focuses not only on software design and programming, but also on empowering young people to be at the forefront of African growth by harnessing the power of technology. Students in the Computer Science programme are taken through robust foundational courses that prepare them to engage new technologies, and create software tools for African needs.</p>

                                    </div>
                                    <div className="col-md-3">
                                        <div style={{ background: '#f4f4f4', padding: '8px', borderRadius: '5px' }}>
                                            <h3 className="nav-top">Academics</h3>
                                            <ul className="tabs">
                                                <Link to={"/academics/baze-education"}>
                                                    <li>
                                                        The Baba Ahmed University Education
                                                        <i className="fa fa-angle-right pull-right icons" />
                                                    </li>
                                                </Link>
                                                <Link to={"/academics/learning-goals"}>
                                                    <li>
                                                        Learning Goals
                                                        <i className="fa fa-angle-right pull-right icons" />
                                                    </li>
                                                </Link>
                                                <Link to={"/academics/academic-registry"}>
                                                    <li>
                                                        Academic Registry
                                                        <i className="fa fa-angle-right pull-right icons" />
                                                    </li>
                                                </Link>
                                                <Link to={"/academics/academic-calender"}>
                                                    <li>
                                                        Academic Calendar
                                                        <i className="fa fa-angle-right pull-right icons" />
                                                    </li>
                                                </Link>
                                                {/*<Link to={"/librarian-office"}>*/}
                                                {/*    <li>*/}
                                                {/*        Library*/}
                                                {/*        <i className="fa fa-angle-right pull-right icons" />*/}
                                                {/*    </li>*/}
                                                {/*</Link>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
}

export default BazeEducation;
