import React from "react";
import Banner from "../../utils/banner/banner";
import "../../admission/application-guide/application-guide.css"
import 'react-data-table-component-extensions/dist/index.css';
import { serverLink } from "../../../resources/url";
import axios from "axios";
import { connect } from "react-redux";
import { setStaffProfileDetails } from '../../../actions/setactiondetailsection'
import { useState } from "react";
import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import './stafflist.css'
import Loader from "react-loader-spinner";
import StaffBanner from './Staff-banner-1.png'
import 'react-data-table-component-extensions/dist/index.css';
import { checkImage, checkImageURL, shuffle, toTitleCase } from "../../../resources/constants";
import Avater from './avater.jpg'
import * as DOMPurify from 'dompurify';
import ReactPaginate from 'react-paginate';
import { ConsoleView } from "react-device-detect";



const StaffListAcadAndNonAcad = (props) => {
    const [isLoading, setIsloading] = useState(props.staffList.length > 0 ? false : true)
    const [staffList, setStaffList] = useState(props.staffList.length > 0 ? props.staffList : []);
    const [_data, setData] = useState(props.staffList.length > 0 ? props.staffList : [])
    const [byFaculty, setbyFaculty] = useState([]);
    const [depts, setDeps] = useState([])

    useEffect(async () => {
        await axios.get(`${serverLink}staff/website/staff_list_by_faculty`)
            .then(result => {
                const byFaculty = result.data;
                setbyFaculty(byFaculty);

            })
        await axios.get(`${serverLink}staff/website/staff_list`)
            .then(result => {
                if (result.data.length > 0) {
                    const pagedata = result.data;
                    setData(pagedata);
                    setStaffList(pagedata)
                    setIsloading(false)
                    const detss = [... new Set(result.data.map((x => x.DepartmentName)))]
                    setDeps(detss)
                }
            })
    }, [])


    const handleSearch = (e) => {
        const filtered = _data.filter(x =>
            x.staff_name.toString().trim().toLowerCase().includes(e.target.value.toString().trim().toLowerCase()) ||
            x.Gender.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
            x.designation.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.DepartmentName.toLowerCase().includes(e.target.value.toLowerCase()
            )
        )
        setStaffList(shuffle(filtered));
    }


    function PaginatedItems({ itemsPerPage }) {
        const ev = shuffle(staffList)
        const [itemOffset, setItemOffset] = useState(0);

        const endOffset = itemOffset + itemsPerPage;
        const currentItems = ev.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(ev.length / itemsPerPage);

        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % ev.length;
            setItemOffset(newOffset);
        };

        const ImgeUrl = (url) => {
            if (url === null || url === "") {
                return Avater;
            } else if (url.includes("simplefileupload")) {
                return checkImageURL(url) ? url : Avater
            }
            else {
                return `${checkImageURL(`${serverLink}public/uploads/news_uploads/${url}`) ? `${serverLink}public/uploads/news_uploads/${url}` : Avater} `
            }
        }

        return (
            <>
                <div className="row">
                    <Items currentItems={currentItems} />
                </div>
                <div className="row col-md-12">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        containerClassName="pg_container"
                        pageLinkClassName="pg_page-link"
                        previousLinkClassName="pg_page-link"
                        nextLinkClassName="pg_page-link"
                        activeClassName="pg_active"
                    />
                </div>
            </>
        );
    }


    function Items({ currentItems }) {
        return (
            <>
                {
                    currentItems.length > 0 ?
                        currentItems.map((x, i) => {

                            return (
                                <div className="col-md-6" style={{ marginBottom: '30px' }} key={i} >
                                    <div className="fv_container">
                                        <div className="fv_product-details">
                                            <h5>
                                                <NavLink to={`/academics/staff/${x.Slug}`}>
                                                    {toTitleCase(x.staff_name.toString().trim())}
                                                </NavLink>
                                            </h5>
                                            <p className="fv_information">
                                                <div><strong>Position : </strong> {x.designation}</div>
                                                <div><strong>Department : </strong> {x.DepartmentName === 'Office of DVC Information Technology, Research and Innovation' ? "ITR & I" : x.DepartmentName}</div>

                                                <div><small> <a className="" href={`mailto: ${x.email}`} target="_blank" >
                                                    Email {toTitleCase(x.staff_name.toString().trim().split(" ")[0])}
                                                </a></small></div>
                                            </p>
                                            <div className="fv_control">
                                                <Link to={`/academics/staff/${x.Slug}`} className="fv_btn">
                                                    <span className="fv_buy">Read More</span>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="fv_product-image">

                                            <img src={
                                                x.image === null || x.image === '' || x.image === undefined || x.image === "undefined" ?
                                                    Avater
                                                    :
                                                    x.image.includes("simplefileupload") ?
                                                        x.image :
                                                        x.image.includes("portal.babaahmeduniversity") ?
                                                            x.image
                                                            : `https://portal.babaahmeduniversity.edu.ng/staff/${x.image}`
                                            }

                                                alt={x.staff_name} />
                                            <div className="fv_info" style={{ padding: '20px' }}>
                                                <span dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(x.biography) !== null ?
                                                        DOMPurify.sanitize(x.biography).length > 100 ? DOMPurify.sanitize(x.biography).substr(0, 100) + "..." : "" : ""
                                                }} />
                                                {
                                                    DOMPurify.sanitize(x.biography) !== null &&
                                                    DOMPurify.sanitize(x.biography).length > 100 &&
                                                    <NavLink to={`/academics/staff/${x.Slug}`} >
                                                        Read More
                                                    </NavLink>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )

                        })
                        : <div className="col-md-12">Record not found</div>
                }
            </>
        );
    }


    
    return isLoading === true ?
        <>
            <Loader
                type="ThreeDots"
                color="#63656A"
                height={200}
                width={200}
                className="text-center"
            />
        </> : <>
            <Banner image={StaffBanner} title="Staff Directory" caption="" />

            <div className="container-fluid layout col1" style={{ margin: "10px" }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="layout-blocks-ucws-checklist container-fluid roundable block checklist">
                            <div className="row">

                                <div className="col-lg-12">
                                    <div className="layout-col col1 layout-12 col-sm-12">
                                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                            <div style={{ padding: '8px', borderRadius: '5px' }}>
                                                <input
                                                    name="search"
                                                    placeholder="search staff"
                                                    style={{ width: '100%' }}
                                                    className="textBox"
                                                    onChange={handleSearch} />
                                            </div>

                                            <div className="row">



                                                <div className="col-md-9">
                                                    <PaginatedItems itemsPerPage={20} />

                                                    {/* <DataTableExtensions
                                                        {...tableData}
                                                    >
                                                        <DataTable
                                                            noHeader
                                                            defaultSortField="Name"
                                                            defaultSortAsc={false}
                                                            pagination
                                                            paginationPerPage={100}
                                                            highlightOnHover />
                                                    </DataTableExtensions> */}

                                                </div>
                                                <div className="col-md-3">

                                                    <div style={{ background: '#eeeeee', padding: '8px', borderRadius: '5px', paddingLeft: '15px' }}>
                                                        <ul>
                                                            <li>
                                                                All Staff (<span><strong>{_data.length}</strong></span>)
                                                            </li>

                                                            <li style={{ textDecoration: 'underline', cursor: 'pointer' }}

                                                                onClick={() => {
                                                                    setStaffList(_data.length > 0 ? _data.filter(item => item.academic === "1") : "0"
                                                                    );
                                                                    // setData2(
                                                                    //     _data.length > 0 ? _data.filter(item => item.academic === "1") : "0"
                                                                    // )
                                                                }}
                                                            >
                                                                Academic Staff (<span>
                                                                    <strong>
                                                                        {_data.length > 0 ? _data.filter(item => item.academic === "1").length : "0"}
                                                                    </strong>
                                                                </span>)
                                                            </li>
                                                            <li style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    setStaffList(_data.length > 0 ? _data.filter(item => item.academic === "0") : "0"
                                                                    );
                                                                    // setData2(
                                                                    //     _data.length > 0 ? _data.filter(item => item.academic === "0") : "0"
                                                                    // )
                                                                }}
                                                            >
                                                                Non-Academic Staff (<span>
                                                                    <strong>
                                                                        {_data.length > 0 ? _data.filter(item => item.academic === "0").length : "0"}
                                                                    </strong>
                                                                </span>)
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div style={{ background: '#eeeeee', padding: '8px', borderRadius: '5px', paddingLeft: '15px' }}>
                                                        {byFaculty.length > 0 &&
                                                            <>
                                                                <ul>
                                                                    {byFaculty.map((x, y) => {
                                                                        return (
                                                                            <li key={y} onClick={() => {
                                                                                setStaffList(
                                                                                    _data.filter(
                                                                                        item => item.FacultyCode.toLocaleLowerCase().includes(x.FacultyCode.toLocaleLowerCase())
                                                                                    )
                                                                                );
                                                                                // setData2(_data.filter(
                                                                                //     item => item.FacultyCode.toLocaleLowerCase().includes(x.FacultyCode.toLocaleLowerCase())
                                                                                // ))
                                                                            }} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                                                {x.FacultyName}&nbsp;
                                                                                (<span>
                                                                                    <strong>
                                                                                        {_data.filter(
                                                                                            item => item.FacultyCode.toLocaleLowerCase() === (x.FacultyCode.toLocaleLowerCase())
                                                                                        ).length}
                                                                                    </strong>
                                                                                </span>)
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </>
                                                        }
                                                    </div>


                                                    <div style={{ background: '#eeeeee', padding: '8px', borderRadius: '5px', paddingLeft: '15px', marginTop: '40px' }}>
                                                        {depts.length > 0 ?
                                                            <>
                                                                <ul>
                                                                    {depts.sort().map((x, y) => {
                                                                        return (
                                                                            <li key={y} onClick={() => {

                                                                                setStaffList(
                                                                                    _data.filter(
                                                                                        item => item.DepartmentName !== null && item.DepartmentName.toLocaleLowerCase().includes(x.toLocaleLowerCase())
                                                                                    )
                                                                                );
                                                                                // setData2(_data.filter(
                                                                                //     item => item.DepartmentName !== null && item.DepartmentName.toLocaleLowerCase().includes(x.toLocaleLowerCase())
                                                                                // ))
                                                                                window.scrollTo(0, 0)
                                                                            }} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                                                {x}&nbsp;
                                                                                (<span>
                                                                                    <strong>
                                                                                        {_data.filter(
                                                                                            item => item.DepartmentName !== null && item.DepartmentName.toLocaleLowerCase() === (x.toLocaleLowerCase())
                                                                                        ).length}
                                                                                    </strong>
                                                                                </span>)
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </>
                                                            :
                                                            <div className="text-center">
                                                                Loading categories...

                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                        <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                            <div className="row">
                                <div className="">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <hr className="solid light-grey" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="container-fluid layout col1">
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-info-shim container-fluid roundable block info-shim">
                            <div className="row">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="more-info-line">
                                                <div className="more-info-icon">
                                                    <span className="icon icomoon ucws-info-shim-info light-grey"
                                                        aria-hidden="true"></span> Did you know Baba Ahmed University Admission system is always open?
                                                    <a href="https://portal.babaahmeduniversity.edu.ng/admission"
                                                        target="_blank" rel="noopener"> Apply here</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
}
const mapStateToProps = (state) => {
    return {
        staffList: state.BazeStaffListDetails,
        facultyList: state.BazeFacultyListDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnStaffProfileDetails: (p) => {
            dispatch(setStaffProfileDetails(p));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffListAcadAndNonAcad);
