import React from 'react'

export default function Address(props) {
    return (
        <div className="container-fluid layout col2-50-50" style={{ margin: "10px" }}>
            <div className="row">
                <div className="container">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-home-and-building-desk-drawer-line"
                                                        aria-hidden="true" />
                                                </div>
                                                {
                                                    props.isItri ?
                                                        <div className="col-md-10">
                                                            <h3>Address</h3>
                                                            <p>Block A, Room A21 <br />
                                                                Baba Ahmed University, Kano</p>
                                                        </div>
                                                        :
                                                        <div className="col-md-10">
                                                            <h3>Address</h3>
                                                            <p>phase 1, No 306, Sharada, Kano 700234,<br />
                                                                Kano, Nigeria<br />
                                                                </p>
                                                        </div>
                                                }
                                            </div>

                                        </div>

                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-user-interface-email-83-line"
                                                        aria-hidden="true" />
                                                </div>
                                                <div className="col-md-10">
                                                    <h3>Contact</h3>
                                                    <p>
                                                        {
                                                            props.isItri ?
                                                                <>
                                                                    <i className="fa fa-envelope-o" /> <a style={{ backgroundImage: "none" }} href="mailto:itri@bazeuniversity.edu.ng">itri@bazeuniversity.edu.ng</a><br />
                                                                    <i className="fa fa-mobile fa-mobile-phone" /> &nbsp;&nbsp;
                                                                    <a style={{ backgroundImage: "none" }} href="tel:1035">1152 (Intercom)</a><br />
                                                                </>
                                                                :
                                                                <>
                                                                    <i className="fa fa-phone" /> <b>UG Contact:</b><a style={{ backgroundImage: "none" }} href="tel:+2347032352575">+234 703 235 2575</a><br />
                                                                    {/*<i className="fa fa-phone" /> <b>PG Contact:</b><a style={{ backgroundImage: "none" }} href="tel:+2349062011194">+234 906 201 1194</a><br />*/}

                                                                    <i className="fa fa-envelope-o" /> <a style={{ backgroundImage: "none" }} href="mailto:admissions@bazeuniversity.edu.ng">admissions@babaahmeduniversity.edu.ng</a> <br />
                                                                    {/*<i className="fa fa-envelope-o" /> <a style={{ backgroundImage: "none" }} href="mailto:pgsadmissions@bazeuniversity.edu.ng">pgsadmissions@babaahmeduniversity.edu.ng</a> <br />*/}
                                                                    <i className="fa fa-envelope-o" /> <a style={{ backgroundImage: "none" }} href="mailto: bursar@bazeuniversity.edu.ng">bursar@babaahmeduniversity.edu.ng</a> <br />
                                                                    <i className="fa fa-envelope-o" /> <a style={{ backgroundImage: "none" }} href="mailto:inquiries@bazeuniversity.edu.ng">inquiries@babaahmeduniversity.edu.ng</a>
                                                                    <small>
                                                                        <small>
                                                                            &nbsp; (please do not send admission enquiries)
                                                                        </small>
                                                                    </small>
                                                                    <br />
                                                                </>
                                                        }
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
