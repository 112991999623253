import React, { Component } from "react";
import Banner from "../utils/banner/banner";
import ResearchBanner from "./img/research2.jpeg";
import HRLine from "../utils/hrline";
import axios from "axios";
import { serverLink } from "../../resources/url";
import { Link } from "react-router-dom";


class ResearchCentres extends Component {
    constructor(props) {
        super(props);

        this.state = {
            members: []
        }
    }

    componentDidMount() {
        this.getMembers();
    }

    async getMembers() {
        await axios.get(`${serverLink}staff/website/itri_staff/list`)
            .then(result => {
                this.setState({ members: result.data })
            })
            .catch(error => {
                console.log("UNABLE TO RETRIEVE THE MEMBERS LIST");
            })
    }

    render() {
        return <>
            <Banner image={ResearchBanner} title="Research Centres" caption="" disableTitle />

            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <span>
                                                <h3>Research Centres</h3>
                                                <p style={{ marginTop: '20px' }} >
                                                    Our experts cover a range of different disciplines, giving you a wide choice of specific topics from single subject areas.
                                                    Discover the innovative research projects taking place at Baba Ahmed University.
                                                </p>
                                                <p style={{ marginTop: '30px', marginBottom: '20px' }} >
                                                    <h3 style={{ marginBottom: '20px' }}>Key Features of Our Office:</h3>
                                                    <ol>
                                                        <li>
                                                            Centre for Big Data Analytics, eGovernance and Innovation
                                                        </li>

                                                        <li>
                                                            Centre for Foundation and Interdisciplinary Studies
                                                        </li>

                                                        <li>
                                                            Artificial Intelligence and Blockchain Technology Centre
                                                        </li>

                                                        <li>
                                                            Center for Clean Energy and Climate Change
                                                        </li>
                                                        <li>
                                                            Peace and Anti-Conflict Journalism Research Center
                                                        </li>

                                                    </ol>

                                                </p>

                                            </span>
                                        </div>
                                    </div>

                                    <HRLine />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>;
    }
}

export default ResearchCentres;
