import React from "react";
import ApplyNow from './application process.png'
import Banner from "../../utils/banner/banner";
import "./application-guide.css"
import { Link } from "react-router-dom";

const ApplicationGuide = () => {
    return <>
        <Banner image={ApplyNow} title="" caption="" />
        {/* <Banner image={ApplyNow} title="Application Guide" caption="Read the application procedures carefully..." /> */}
        <div className="container-fluid layout col1" style={{ margin: "10px" }}>
            <div className="row">
                <div className="layout-col col1 layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-checklist container-fluid roundable block checklist">
                        <div className="row">
                            <div className="container">
                                <div className="row header">
                                    <div className="col-sm-12">
                                        <h2>Before you apply</h2>
                                        <p>You should apply as early as possible to be considered for admissions</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="media-left"  >
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-checklist-check-square light-grey"
                                                        aria-hidden="true"></span>
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <h3>Select your Program</h3>
                                                <p>Browse our courses, either A-Z or by department, to see if Baba Ahmed university
                                                    offers a course you’re interested in.</p>
                                                <p>
                                                    <Link to={"/admission/course-finder"} className="read-more">
                                                        Explore programs
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="media-left"  >
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-checklist-check-square light-grey"
                                                        aria-hidden="true"></span>
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <h3>Check your admission requirements</h3>
                                                <p>Check the entry requirements on the course page to make sure that you have the right qualifications..</p>
                                                <p>
                                                    <Link to={"/admission/course-finder"} className="read-more">
                                                        View our admissions requirements
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <hr className="solid light-grey" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <div className="container-fluid layout col1">
            <div className="row">
                <div className="layout-col col1 layout-12 col-sm-12">


                    <div className="layout-blocks-ucws-accordion container-fluid roundable block accordion border">
                        <div className="row">
                            <div className="container">
                                <div className="row header">
                                    <div className="col-sm-12">
                                        <h2>Application Guide</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="panel-group" id="accordion1585270170" role="tablist"
                                            aria-multiselectable="true">
                                            <div className="panel panel-default">
                                                <div className="panel-heading" role="tab" id="heading733025994">
                                                    <h4 className="panel-title">
                                                        <a className="collapsed" role="button" data-toggle="collapse"
                                                            href="#collapseApplicationBasic">Application Key Topics
                                                            <span aria-hidden="true"><span className="ucws-icon ucws-icon-chevron"></span></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseApplicationBasic" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading733025994" aria-expanded="false" style={{ height: '0px' }}>
                                                    <div className="panel-body">
                                                        <div className="jumbotron">
                                                            <h3>APPLICATION BASICS: KEY TOPICS</h3>

                                                            <li className="page-scroll" style={{ listStyleType: 'none' }}>
                                                                <span className="fa fa-chevron-right"></span> <a
                                                                    href="#apply"> How to apply</a>
                                                            </li>
                                                            <li className="page-scroll" style={{ listStyleType: 'none' }}>
                                                                <span className="fa fa-chevron-right"></span> <a
                                                                    href="#when_to_apply"> When to apply</a>
                                                            </li>
                                                            <li className="page-scroll" style={{ listStyleType: 'none' }}>
                                                                <span className="fa fa-chevron-right"></span> <a
                                                                    href="#more_than_one"> Applying to more than one course</a>
                                                            </li>
                                                        </div>
                                                        <p>Before you apply, read this Application Guide and the information on your course page to ensure that you provide the correct documents and information. Our <Link to={"/itri-office/directorate"}>Technical Help</Link>, which you can also access from your application, provides step-by-step help on how to use the online form.</p>

                                                        <h4>Important note on published information</h4>

                                                        <p>This Application Guide and the Undergraduate Admissions webpages are definitive and contain the most current and comprehensive information about the admissions process and requirements. These may be subject to change and you should consider the version currently available online to be the definitive source of information at any given time.</p>

                                                        <h3 id={"apply"}>How to Apply</h3>
                                                        <p>To bring about ease and simplicity to our application process, we have made provisions for two methods of application that can be carried out in the comfort of your home.</p>

                                                        <h4>Applying Online</h4>
                                                        <p>Click the button below to apply. Details on the entry requirements can be found on the "Documents" link on the top of this page.</p>
                                                        <a href="https://portal.babaahmeduniversity.edu.ng/admission" className={"btn btn-primary"}>Apply Now</a>

                                                        <h3 id={"when_to_apply"}>When to Apply</h3>
                                                        <p>Baba Ahmed University does not use application deadlines. This means that you can apply for admission all the time. Before you start your application, you need to ensure that your application and all supporting documents are ready.</p>

                                                        <h3 id={"more_than_one"}>Applying to more than one course</h3>
                                                        <p>You can submit multiple applications to different courses, but please do not accept any offer until you have received a decision from all the programmes you have applied for. When you have received all decisions from the university please make sure you accept only one offer as soon as possible.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading " role="tab" id="heading614380467">
                                                    <h4 className="panel-title">
                                                        <a className="collapsed" role="button" data-toggle="collapse"
                                                            href="#collapseApplicationProcess" aria-expanded="true"
                                                            aria-controls="collapseApplicationProcess"
                                                            data-ucws-analytics-toggle=""
                                                            data-ucws-analytics-category="Accordion"
                                                            data-ucws-analytics-label="Studied outside Alberta"
                                                            data-ucws-analytics-off-class="collapsed">Application Process <span aria-hidden="true"><span className="ucws-icon ucws-icon-chevron"></span></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseApplicationProcess" className="panel-collapse collapse " role="tabpanel" aria-labelledby="heading614380467">
                                                    <div className="panel-body">
                                                        <div className="jumbotron">
                                                            <h3>APPLICATION BASICS: KEY TOPICS</h3>

                                                            <li className="page-scroll" style={{ listStyleType: 'none' }}>
                                                                <span className="fa fa-chevron-right"></span> <a
                                                                    href="#choosing_your_course"> Choosing your course</a>
                                                            </li>
                                                            <li className="page-scroll" style={{ listStyleType: 'none' }}>
                                                                <span className="fa fa-chevron-right"></span> <a
                                                                    href="#completing_your_application"> Completing your application</a>
                                                            </li>
                                                            <li className="page-scroll" style={{ listStyleType: 'none' }}>
                                                                <span className="fa fa-chevron-right"></span> <a
                                                                    href="#submitting_your_application"> Submitting your application</a>
                                                            </li>
                                                            <li className="page-scroll" style={{ listStyleType: 'none' }}>
                                                                <span className="fa fa-chevron-right"></span> <a
                                                                    href="#after_submitting_your_application"> After submitting your application</a>
                                                            </li>
                                                        </div>
                                                        <p>This guide covers key points to consider when completing your application. Our <Link to={"/itri-office/directorate"}>Technical Help</Link>, which you can also access from your application, provides step-by-step help on how to use the online form.</p>

                                                        <h3 id={"choosing_your_course"}>Choosing your course</h3>
                                                        <p>In choosing your course, read the course page carefully to make sure that you meet the entry requirements, as admissions to Baba Ahmed University are highly competitive, and that you are familiar with the structure and content of the course (for transfer students). The course pages will show whether your preferred course is currently accepting applications for your selected entry.</p>

                                                        <div className="jumbotron">
                                                            Make sure that you select your preferred course when applying as you will not be able to change this after you start completing the application form or after you apply.
                                                        </div>

                                                        <h4>Studying at more than one institution</h4>
                                                        <p>You cannot undertake full-time study at Baba Ahmed University whilst registered in another course at Baba Ahmed University or at another institution, either in Nigeria or worldwide. If you are registered at another institution when you apply to Baba Ahmed University for full-time study, as a condition of your offer you would be required to suspend or terminate your registration whilst studying at Baba Ahmed University, unless the registration is part of a formal collaborative agreement between Baba Ahmed University and the other institution.</p>

                                                        <h3 id={"completing_your_application"}>Completing your application</h3>
                                                        <h4>Data protection and nominated third parties</h4>
                                                        <p>We will handle your application in accordance with the Nigeria Data Protection Act of 1998 and the declaration that you are required to sign before submitting your application will give you information on any third parties with whom we may need to share your data as part of the processing and assessment of your application. In your application you may nominate a third party, such as a relative, for the purposes of handling your application. We will not discuss your application with anyone else unless you nominate a representative in this section. Anyone you appoint will be able to request information and give us instructions relating to your application.</p>

                                                        <h4>Accommodation</h4>
                                                        <p>While many of Baba Ahmed University students are housed in the wide range of accommodation provided by Baba Ahmed University Accommodation Office, applicant can choose not to stay in the hostel.</p>

                                                        <h3 id={"submitting_your_application"}>Submitting your application</h3>
                                                        <p>When you submit your application you will be required to sign a declaration confirming that all information provided is - to the best of your knowledge - true, accurate, current and complete, and that you will promptly notify the University should any information change. You will also confirm that the information contained in your application, including all supporting work, is entirely your own original work, except where clearly indicated, and does not contain any plagiarised elements.</p>

                                                        <h3 id={"after_submitting_your_application"}>After submitting your application</h3>
                                                        <p>As soon as you submit your application you will be sent an automated confirmation via e-mail. If you do not receive this, please log in to your application to check that you have successfully submitted it and can no longer edit it. This email only confirms that your application was submitted.</p>

                                                        <h4>Assessment of your application</h4>
                                                        <p>You will receive an email notification from the Undergraduate Admissions office once your application has been sent to the department for assessment. Usually this notification is sent within a few working days. Whilst the department will start to assess your application once you have completed and submitted the application form, you may be requested to submit additional documents for your application to be fully assessed and a final decision to be made. You will be notified of the outcome of your application by the department, usually around one to two weeks from your submission date.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="layout-blocks-ucws-info-shim container-fluid roundable block info-shim">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="more-info-line">
                                            <div className="more-info-icon">
                                                <span className="icon icomoon ucws-info-shim-info light-grey"
                                                    aria-hidden="true"></span> Did you know Baba Ahmed University Admission system is always open?
                                                <a href="https://portal.babaahmeduniversity.edu.ng/admission"
                                                    target="_blank" rel="noopener"> Apply here</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

    </>
}

export default ApplicationGuide;