import React, { Component } from "react";
import Banner from "../utils/banner/banner";
import BabaAhmedSquare from "../../images/faculties/square.jpg";
import HRLine from "../utils/hrline";
import axios from "axios";
import { serverLink } from "../../resources/url";
import { Link } from "react-router-dom";
import Address from "../about/contact-us/address";


class ColaborateWithUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            members: []
        }
    }

    componentDidMount() {
        this.getMembers();
    }

    async getMembers() {
        await axios.get(`${serverLink}staff/website/itri_staff/list`)
            .then(result => {
                this.setState({ members: result.data })
            })
            .catch(error => {
                console.log("UNABLE TO RETRIEVE THE MEMBERS LIST");
            })
    }

    render() {
        return <>
            <Banner image={require("./img/research2.jpeg")} title="Collaborate with us" caption="" disableTitle />

            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <span>
                                                <h3>Collaborate with us</h3>
                                                <p style={{ marginTop: '20px' }} >
                                                    We invite researchers from all backgrounds and disciplines to join our dynamic research community at the IT, Research, and Innovation Department.
                                                    Whether you are passionate about artificial intelligence, medicine, arts, anatomy, cybersecurity, data science, biology, or any other field, you will find a welcoming and intellectually stimulating environment to pursue your research aspirations.
                                                </p>
                                                <p style={{ marginTop: '30px', marginBottom: '20px' }} >
                                                    Come and be a part of our community of researchers, where your ideas can thrive, your discoveries can reshape the future, and your innovative solutions can make a difference.
                                                    Together, let us unlock new frontiers of knowledge and transform the world through research and innovation.
                                                </p>

                                                <p style={{ marginTop: '30px', marginBottom: '20px' }} >
                                                    We look forward to welcoming you to the IT, Research, and Innovation Office at Baba Ahmed University.
                                                    Let's embark on an extraordinary journey of discovery, collaboration, and impact!

                                                </p>

                                            </span>
                                        </div>
                                    </div>

                                    <HRLine />
                                    <Address isItri />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>;
    }
}

export default ColaborateWithUs;
