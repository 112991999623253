import image_path from './images/dayo.png';
import image_path1 from './images/avater.jpg';
import image_path2 from './images/martha.png'
import image_path3 from './images/ernest.png'
import image_path4 from './images/maryam.png'
import image_path5 from './images/sunday.png'
import image_path6 from './images/tosin.png'
import image_path7 from './images/jessica.png'
import image_path8 from './images/comm.jpeg'
import gallery3 from './images/galley (3).jpg'
import gallery4 from './images/galley (4).jpg'
import gallery5 from './images/galley (5).jpg'
import gallery6 from './images/galley (6).jpg'
import gallery7 from './images/galley (7).jpg'
import gallery8 from './images/galley (8).jpg'
import gallery9 from './images/galley (9).jpg'
export const LawClinicData = [

    {
        name: "Prof. Ernest Ojukwu, SAN",
        position: "Chairman, Baba Ahmed University Law Clinic Committee",
        body: `<div>
        <p>Prof. Ernest is a Senior Advocate of Nigeria; Professor of Law Baba Ahmed University; Professor of Law Madonna University Nigeria (2014-2016); Associate Professor of Law and Dean, Faculty of Law Abia State University Uturu, (1995 to 2000); Deputy Director-General and Head Quality Assurance Nigerian Law School (2013-2014), Deputy Director-General and Head of Augustine Nnamani Campus Enugu, Nigerian Law School from 2001 to 2013; Director NBA Institute of Continuing Legal Education (2007-2010 & 2012-2014); External Examiner Kenyatta University School of Law Nairobi 2013-2017.
        </p> 
        <p>Ernest is President of the Network of University Legal Aid Institutions (NULAI Nigeria). NULAI is an organization committed to promoting clinical legal education, legal education reform, legal aid, access to justice and social justice. It has over forty 40 University based Law Clinics membership. US Department of State has just awarded NULAI a grant to pilot a Young personâ€™s peace and conflict transformation project. The project will use street law approach to reach 200 pupils from 10 secondary schools. The pilot clinics for the Peace and conflict project are University of Abuja Law Clinic, Ahmadu Bello University Law Clinic and Nasarawa State University Law Clinic. NULAI is also currently implementing the Reform Kuje Prison project in conjunction with Partners West Africa Nigeria (PWA-N), New-Rule LLC, Baba Ahmed University Law Clinic, Nile University Law Clinic and University of Abuja Law Clinic.</p>
        <p>He is a contributor to two International books on clinical legal education: Making a real change; legal Education in Nigeria- partly re-imagined, Grimes, R, ed, Rethinking Legal Education Under the Civil and Common Law: A Road Map for Constructive Change, Routledge, pages 192-201; and Clinical Legal Education in Africa: Legal Education and Community Services, The Global Clinical Movement- Educating Lawyers for Social Justice (Oxford University Press) pages 23-26 edited by Frank S. Bloch.
 
        He is the Author of Legal Education in Nigeria: A Chronicle of Reforms and Transformation under Tahir Mamman;
         
        Co-Author of:
</p>        
        <ol type="a">
            <li>Clinical Legal Education: Curriculum Lessons and Materials;</li>
            <li>Handbook on Prison Pre-trial Detainee Law Clinic;</li>
            <li>Manual on Prison Pre-trial Detainee Law Clinic;</li>
            <li>Impact Assessment of Clinical Legal Education in Nigeria:</li>
            <li>Street Law: Child Rights Manual</li>
            <li>Nigerian Law School Students Externship Handbook</li>
            <li>Nigerian Law School Externship Handbook for Supervisors</li>
            <li>Freedom of Information Handbook for Law Clinics</li>
            <li>Street Law Freedom of Information Manual</li>
        </ol>
        <p>Editor-in-Chief, African Journal of Clinical Legal Education and Access to Justice</p>
        <p>Ernest Ojukwu established the Nigerian Bar Association (NBA) Institute of Continuing Legal Education in 2007 and designed the Rules and Guidelines that drives the mandatory continuing legal education programme for the legal profession. Was Alternate Chairman NBA Summit on the Future of Legal Education in Nigeria 2006; Chairman NBA Legal Education Committee (2006-2008), Chairman NBA Academic Forum (2003-2004; 2006-2008 & 2012-2014); Editor-in-Chief Nigerian Bar Journal of the NBA (2002-2004; 2006-2008).</p>
        <p>He served as the Secretary of the Federal Government Committee on the Reform of Legal Education in Nigeria 2006-2007; Secretary Council of Legal Education Committee on the Review of the LLB Curriculum and Programmes of the Universities, 2013-2015.</p>
        </div>
        `,
        image: image_path3,

    },
    {
        name: "Tosin Yemi OKE",
        position: "Co-ordinator and Senior Lecturer",
        body: `<p>Tosin Yemi OKE has years of experience in legal practice, the academia and the community development sector. Currently she is a lecturer at the Faculty of Law Baba Ahmed University where she teaches the Law of Contract, the Law of Evidence, Social Justice and Public Interest Law under the clinical legal education program among other courses. She is also a supervisor at Baze Law Clinic which specializes in pro bono human rights protection . She annexes the classroom to groom lawyers who are conscious of the need to give back to the society.She was a counsel with the law firm of Dikko & Mahmoud Solicitors and advocates where she managed the Corporate and Commercial Law department. Tosin holds a first in Law from Madonna University Nigeria in 2006, was called to the Nigerian Bar in 2008 and obtained a masters degree in Corporate Governance and Law from the University of Portsmouth in 2011. She is a member of the Nigerian Bar Association, International Bar Association, Nigerian Association of Law Teachers and a Graduate member of the Institute of Chartered Secretaries and Administrators.
        </p>`,
        image: image_path6
    },
    
    {
        name: "Martha B. Omem, LL.B, B.L, LL.M",
        position: "Clinical Supervisor and Lecturer",
        body: `<p>Martha is one of the clinical law teachers and she supervises the social justice law clinic. Her major interests are environmental law and right to development centred on justice and equity. She has vast experience in law practice, research and legal education.
        </p>`,
        image: image_path2,

    },
    
    {
        name: "Sunday Kenechukwu Agwu",
        position: "Clinical Law Administrator &  Lecturer",
        body: `<p>Prior to his appointment as clinical law administrator, Kenechukwu was a Project Officer at the Network of University Legal Aid Institutions (NULAI)- Nigeria. In that role, He provided technical assistance to over 43 University based Law Clinics in Nigeria under the NULAI Network. Kenechukwu Worked with the team in developing and supervising projects with particular emphasis on pre-trial detention, access to justice, street law, student law practice, legal aid, and community justice education. Kenechukwu was the head of Ebonyi State University Law clinic in 2015.
        </p> 
        <p>He has received training in Clinical Legal Education (CLE) nationally and internationally. He is also a certified Arbitrator in Nigeria and the United Kingdom. He co-anchors the #SabiLaw on the Beat 97.9 FM Abuja, a free legal radio show of the SabiLaw Foundation. He is a member of the Nigerian Bar Association (NBA); the Global Alliance for Justice Education (GAJE); the Nigerian Institute of Management (NIM); and the Chartered Institute of Arbitrators (ACIArb.)UK. 
        <p>In his role as clinical administrator, he oversees the clinical programs and projects of the clinic and is in charge of the daily administration of the law clinic. He teaches Ethics and Professional Responsibility, Law of Evidence, and Social Justice and Public Interest Lawyering. He supervises the Social Justice Law Clinic and the Ethics and Anti-Corruption Law Clinic. 
        </p>
        </p>`,
        image: image_path5
    },
    {
        name: "Maryam Idris Abdulkadir",
        position: "Clinical Secretary and Lecturer",
        body: `<p>Maryam Idris Abdulkadir started her legal carrier at AY. Musa and co. Chambers immediately after her Law School program where she was engaged in legal practice, legal drafting, legal counselling /advice, Mediation, Negotiation, Arbitration, and being a member of the Institute of Debt Recovery Practioners of Nigeria, she was also tasked with debt recovery at the chamber.
        </p> 
        <p>She went on to become a legal administrative assistant at Kuchi and Associates Consulting were her primary job entailed; administrative law, legal consultation, corporate law and legal drafting
        She got her feet wet in the corporate world when she became company secretary of several companies. She mastered her skills in corporate law by servicing the board, taking minutes of minute, giving legal advice, convening both Annual General meetings (AGM) and Special meetings and handling corporate legal documents
        .</p>
        <p>She is also a law clinic supervisor. She is involved with projects like the Kuje Reform Prison Project where access to justice is provided for pre-trial detainees. She pioneered the International Humanitarian Law Clinic that also provides access to justice for victims of armed conflict. She also pioneered the Migration and Trafficked Persons Law Clinic at the same institution were students collaborate with stakeholders to create awareness of the plight of  Refugees, Migrants and trafficked persons, advocate for their rights and give legal advice under supervision.</p>
        <p>
        Maryam Idris Abdulkadir is presently a lecturer II at the Faculty of Law Baba Ahmed University, Kano where she lectures courses like; Equity, Legal Method, Oil and Gas, International Humanitarian Law, Human Rights Law, Public International Law, Clinical Moot court and Practice and Environmental Law.
        <p>In addition to her first degree and Law School certificate, she has a Master degree in Law (LLM) from Ahmadu Bello University and is currently doing her Ph.D. program in the same institution.
        Email: Maryam.abdulkadir@bazeuniversity.edu.ng, miaabdulkadir@gmail.com<br/>                                                             
        Twitter: @maryamabdulkad1<br/> 
        Instagram: maryamabdulkadir<br/>
        Facebook: Maryam Idris Abdulkadir<br/>
        LinkedIn: Maryam abdulkadir
        
        </p>`,
        image: image_path4
    },
    {
        name: "Saifuddeen Coomassie",
        position: "Clinical Supervisor and Lecturer",
        body: `<p>Saifuddeen Coomassie is a member of the Baba Ahmed University Law clinic.
        </p> 
        <p>He concentrates in mentoring and guiding students in developing skills in Legal research, drafting, advisory and general Legal practice.  Before joining the clinic, he was a Law Lecturer and Associate at Salama Chambers. Saifuddeen has interned and worked at various Law firms in Asia and United Kingdom. He obtained his LLB and LLM from the University of Hertfordshire United Kingdom with a specialization in commercial and Maritime Law. While in the United Kingdom, he interned at the Employment Law Tribunal, London. 
        He is also a Member of the Nigerian Bar Association.        
        .
        </p>`,
        image: image_path8
    },
    
    
    {
        name: "Imuekemhe Emike Jessica",
        position: "Clinical Supervisor and Lecturer",
        body: `<p>Imuekemhe Emike Jessica MCIArb began her legal practice fresh out of the Nigerian Law School as an associate at Rickey Tarfa (SAN) & Co. Prior to this appointment, she served as an intern directly under the tutelage of Omoruyi Omonuwa SAN in his chambers O. A Omonuwa (SAN) & CO. She later on in her career, went on to join the prestigious law firm of Chief Chris Uche (SAN) & CO where she developed a keen interest in Criminal Justice Litigation and Practice.
        </p> 
        <p>Her interest in human rights advocacy got her appointed as a Legal Programme Attorney at Access to Justice (A2Justice) Nigeria where she was engaged in litigation, legal drafting, coordination of A2Justice’s programmes/projects on law enforcement brutality and extra-judicial killings, publishing media statements on criminal justice and human right related issues, carrying out criminal justice campaigns and advocacy on behalf of the organization and delivering justice and human rights related talks and seminars on behalf of the Executive Director of the organization.
        </p>
        <p>Jessica obtained her LLB from Kwame Nkrumah University of Science and Technology (KNUST) Ghana, Law School Certificate from the Nigerian Law School Abuja, MSc from Durham University (UK) and LLM from NSUK Nigeria. She is currently pursuing her Doctoral Degree and a Post-graduate Degree in Education.</p>
        <p>Given her interest in academia, Jessica joined Edo State University Uzairue in 2017 as a pioneer lecturer of the University’s Faculty of Law Programme. The following year, she joined the Faculty of Law Baba Ahmed University as a lecturer where in accordance with her thematic area of research, she currently teaches Criminal Law and Criminal Justice Administration. She has also been involved in teaching other courses taught in various levels of the Baba Ahmed University law programme such as Tort Law, Information Communication and Technology (ICT) Law, Family Law and Legal Methods. She recently joined the Baba Ahmed University Law Clinic as a Clinical Supervisor and the Coordinator of the Alternative Dispute Resolution (Unit) of the Clinic.</p>
        <p>She is a member of several professional bodies such as the Nigerian Bar Association (NBA), International Bar Association (IBA), Durham Centre for Research into Violence and Abuse  (UK) and Young Member Group of Chartered Institute of Arbitrators (Abuja Chapter). She is a certified arbitrator and member of the Chartered Institute of Arbitrators (CIARB UK) and a Certified Associate Member of the Institute of Chartered Mediators and Conciliators (ICMC) Nigeria.</p>
        <p>Email: emike.imuekemhe@bazeuniversity.edu.ng, jessicaemike@gmail.com <br/> LinkedIn: Emike Jessica <br/> Facebook: Emike Jessica
<br/> Twitter:  @mimik1414
        </p>
        `,
        image: image_path7,
    },
    {
        name: "Dayo Godwin Ashonibare",
        position: "Clinical Supervisor and Lecturer",
        body: `<p>Dayo G. Ashonibare started his career at Uwensuyi-Edosomwan SAN & Co as an associate counsel before his appointment as Moot Court-Law Clinic Manager at Baba Ahmed University and subsequently a full time lecturer at the prestigious Faculty of Law of the University. 
        Dayo joined Baba Ahmed University in April 2015 having made a Second-Class honour (Upper Division) from the University of Jos and the Nigerian Law School respectively. Dayo holds an LLM from the University of Jos where he reviewed offences under the Cyber-crime prevention & prohibition Act, 2015 in his thesis and currently running his PHD in the same institution.
        At Baba Ahmed University, He has gathered myriad of experiences in teaching different areas such as Alternative Dispute Resolution, Public Interest Law & Social Justice, Information and Communication Technology Law & Clinical & Moot Court Practice using the Clinical Legal Education method in teaching and mentoring law students at the University.  
        He is the pioneer and former Secretary of Baba Ahmed University Law Clinic and served as a member/supervisor  on the team working on Reforming Pretrial Detention in Kuje Prison (Reform Kuje project) being implemented by PartnersGlobal, Partners West Africa Nigeria (PWA-N), the Network of University Law Institutions (NULAI), and New-Rule LLC (New-Rule) Washington DC to address gaps in the implementation of pre-trial detention provisions in the ACJA. 
        He is currently the Managing Partner (Levite Solicitors & Arbitrators) and currently leads the firms Litigation and dispute resolution team
        . Dayo has participated in many conferences and workshops. 
        Areas of Interest: Public interest law, Alternative Dispute resolution and Information and Communication Technology Law.
        
        </p>
        
        <ol>
            <li>Member, National Association of Law Teachers (NALT)</li>
            <li>Member, Global Alliance on Justice Education (GAJE)</li>
            <li>Member, Network of University on Legal Aid Institution (NULAI)</li>
            <li>Member, European Network for Clinical Legal Education (ENCLE)</li>
            <li>International Forum on Teaching Legal Ethics and Professionalism</li>
            <li>Member, Nigerian Bar Association</li>
            <li>Member, Association of Young Arbitrators</li>
            <li>Member, Law Ethics & Practice (LEAP)</li>
            <li>Associate Member, Chartered Institute of Arbitrators UK and Nigerian Chapter</li>
            <li>Member, Section on Business Law of the Nigerian Bar Association</li>
            <li>Member, Section on Legal Practice of the Nigerian Bar Association
            </li>
        </0l>`,
        image: image_path,
    },
]

export const gallery_images_data = [
    { images: gallery3 },
    { images: gallery4 },
    { images: gallery5 },
    { images: gallery6 },
    { images: gallery7 },
    { images: gallery8 },
    { images: gallery9 },
];