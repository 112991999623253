import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./menubar.css"
import Logo from "../../images/logo.png";
import Bronchure from '../footer/baze_brochure.pdf'


const Menubar = ({ faculty_list }) => {
    const [isActive, setActive] = useState(false);
    const location = useLocation()
    const current_location = location.pathname;

    const handleToggle = () => {
        setActive(!isActive);
        document.getElementById('hamburger').click();
    };

    useEffect(() => {
        handleToggle();
    }, [current_location]);

    return <div id="navigation" className="row">
        <div className="uc-logo-container">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="uc-logo">
                            <Link to="/">
                                <img src={Logo} className="baze-logo" alt="Baba Ahmed University Logo" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="uc-nav-container">
            <div className="container">
                <div className="row menus">
                    <div className="col-sm-12">
                        <div id="uc-menu">
                            <button type="button" id="hamburger" className="hamburger hamburger-htx">
                                <span className="sr-only">Toggle Navigation</span>
                                <span aria-hidden="true" />
                            </button>
                            <div className="uc-menu-wrapper">

                                <ul className="level1 uc-menu">

                                    <li id="menu-item-2" className="uc-main-menu children">
                                        <a href="#"> About</a>

                                        <ul className="level2 uc-submenu tabbed menu-block">

                                            <li className="uc-back">
                                                <a href="#">About</a>
                                            </li>
                                            <li id="menu-item-2" className="overview">
                                                <Link to="/">
                                                    About Baba Ahmed University
                                                </Link>
                                            </li>

                                            {/*<li className="menu-block-item">*/}
                                            {/*    <div className="uc-menu-advert">*/}
                                            {/*        <p>Get to know the Baba Ahmed University by taking a tour.</p>*/}

                                            {/*        <p>*/}
                                            {/*            <a className="btn btn-ghost"*/}
                                            {/*                href="https://portal.bazeuniversity.edu.ng/tour/index.html"*/}
                                            {/*                target="_blank">Campus tours</a>*/}
                                            {/*        </p>*/}

                                            {/*    </div>*/}
                                            {/*</li>*/}

                                            <li id="menu-item-1-1" className="uc-first-tab children">
                                                <a href="#">About</a>
                                                <ul className="level3 uc-submenu overview-link">

                                                    <li className="uc-back">
                                                        <a href="#">About</a>
                                                    </li>
                                                    <li id="menu-item-1-1" className="overview">
                                                        <a href="">About</a>
                                                    </li>


                                                    <li>
                                                        <Link to="/about/about-baze">
                                                            About
                                                        </Link>
                                                    </li>

                                                    {/*<li>*/}
                                                    {/*    <Link to="/about/facts-and-figures">*/}
                                                    {/*        Facts and Figures*/}
                                                    {/*    </Link>*/}
                                                    {/*</li>*/}

                                                    <li>
                                                        <Link to="/gallery">
                                                            Gallery
                                                        </Link>
                                                    </li>

                                                    {/*<li>*/}
                                                    {/*    <Link to="/vice-chancellor-office">*/}
                                                    {/*        Office of the Vice Chancellor*/}
                                                    {/*    </Link>*/}
                                                    {/*</li>*/}

                                                    {/*<li>*/}
                                                    {/*    <Link to="/dvc-admin-office">*/}
                                                    {/*        Office of the DVC (Admin)*/}
                                                    {/*    </Link>*/}
                                                    {/*</li>*/}

                                                    {/*<li>*/}
                                                    {/*    <Link to="/itri-office/directorate">*/}
                                                    {/*        Office of the DVC (ITR & I)*/}
                                                    {/*    </Link>*/}
                                                    {/*</li>*/}

                                                    {/*<li>*/}
                                                    {/*    <Link to="/registrar-office">*/}
                                                    {/*        Office of the Registrar*/}
                                                    {/*    </Link>*/}
                                                    {/*</li>*/}

                                                    {/*<li>*/}
                                                    {/*    <Link to="/human-resources-office">*/}
                                                    {/*        Human Resources Office*/}
                                                    {/*    </Link>*/}
                                                    {/*</li>*/}

                                                    <li>
                                                        <Link to="/about/our-campus">
                                                            Our Campus
                                                        </Link>
                                                    </li>

                                                    {/* <li>
                                                        <Link to="/itri-office/directorate">
                                                            IT R&I Directorate
                                                        </Link>
                                                    </li> */}

                                                    <li>
                                                        <Link to="/contact/contact-us">
                                                            Map and Contact Information
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/about/email-unsubscribe">
                                                            Email Unsubscribe
                                                        </Link>
                                                    </li>



                                                </ul>
                                            </li>

                                            <li id="menu-item-1-2" className="children">
                                                <a href="#">Leadership</a>


                                                <ul className="level3 uc-submenu overview-link">

                                                    <li className="uc-back">
                                                        <a href="#">Leadership</a>
                                                    </li>
                                                    <li id="menu-item-1-2" className="overview">
                                                        <a href="">Leadership</a>
                                                    </li>


                                                    <li>
                                                        <Link to="/leadership/board">
                                                            Board
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/leadership/management">
                                                            Management
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/leadership/senate">
                                                            The Senate
                                                        </Link>
                                                    </li>

                                                </ul>

                                            </li>

                                            <li id="menu-item-1-3" className="children">
                                                <a href="#">Media and Engagement</a>

                                                <ul className="level3 uc-submenu overview-link">

                                                    <li className="uc-back">
                                                        <a href="#">Media and Engagement</a>
                                                    </li>
                                                    <li id="menu-item-1-3" className="overview">
                                                        <a href="">Media and Engagement</a>
                                                    </li>

                                                    <li>
                                                        <Link to="/news/baze-in-the-press">
                                                            Baba Ahmed University in the Press
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/events">
                                                            Events
                                                        </Link>
                                                    </li>

                                                    {/*<li>*/}
                                                    {/*    <a href="https://issuu.com/bazeuniversity/docs/baze_focus_2018" target="_blank">Focus Magazine</a>*/}
                                                    {/*</li>*/}

                                                    {/*<li>*/}
                                                    {/*    <a href="https://issuu.com/bazeuniversity/docs/baze_inaugural_lecture" target="_blank">Inaugural Lectures</a>*/}
                                                    {/*</li>*/}

                                                    <li>
                                                        <Link to="/news">
                                                            News
                                                        </Link>
                                                    </li>

                                                    {/*<li>*/}
                                                    {/*    <a href="https://www.issuu.com/bazeuniversity" target="_blank">Weekly Panorama</a>*/}
                                                    {/*</li>*/}
                                                    {/*<li>*/}
                                                    {/*    <a className="btn-social" href={`https://www.youtube.com/@bazeuniversitysocialmedia5022`} target="_blank" >*/}
                                                    {/*        YouTube*/}
                                                    {/*    </a>*/}
                                                    {/*</li>*/}
                                                    {/*<li>*/}
                                                    {/*    <a className="btn-social" href={`https://docs.google.com/document/d/1sU4_36MzDu1UXLrxfgEdqIi0DBc91eomWlKlbQoijuc/edit`} target="_blank">*/}
                                                    {/*        MOUs & Partnerships*/}
                                                    {/*    </a>*/}
                                                    {/*</li>*/}

                                                </ul>

                                            </li>
                                        </ul>
                                    </li>

                                    <li id="menu-item-2" className="uc-main-menu children">
                                        <a href="#"> Admissions </a>

                                        <ul className="level2 uc-submenu tabbed menu-block">

                                            <li className="uc-back">
                                                <a href="#">Admissions</a>
                                            </li>
                                            <li id="menu-item-2">

                                            </li>

                                            <li className="menu-block-item">
                                                <div className="uc-menu-advert">
                                                    <p>
                                                        <a className="btn btn-ghost"
                                                            href="https://portal.babaahmeduniversity.edu.ng/admission"
                                                            target="_blank">Apply now</a>
                                                    </p>

                                                </div>
                                            </li>

                                            <li id="menu-item-1-1" className="uc-first-tab children">
                                                <a href="#">Admission</a>
                                                <ul className="level3 uc-submenu overview-link">

                                                    <li className="uc-back">
                                                        <a href="#">Admission</a>
                                                    </li>
                                                    <li id="menu-item-1-1" className="overview" >
                                                        <a href="">Admission</a>
                                                    </li>


                                                    <li>
                                                        <Link to="/admission/application-guide">
                                                            Application Guide
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/admission/course-finder">
                                                            Course Finder
                                                        </Link>
                                                    </li>
                                                    <li >
                                                        <a href="https://portal.babaahmeduniversity.edu.ng/admission/" target={"_blank"}>
                                                            Admissions
                                                        </a>
                                                    </li>


                                                    {/* <li>
                                                        <Link to="/admission/tuition-fees">
                                                            Tuition Fees
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/admission/accommodation">
                                                            Accommodation
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/admission/feeding">
                                                            Feeding
                                                        </Link>
                                                    </li> */}

                                                </ul>
                                            </li>

                                            <li id="menu-item-1-2" className="children">
                                                <a href="#">Student Life</a>


                                                <ul className="level3 uc-submenu overview-link">

                                                    <li className="uc-back">
                                                        <a href="#">Student Life</a>
                                                    </li>
                                                    <li id="menu-item-1-2" className="overview">
                                                        <a href="">Student Life</a>
                                                    </li>

                                                    <li>
                                                        <Link to="/admission/tuition-fees">
                                                            Tuition Fees
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/admission/accommodation">
                                                            Accommodation
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/admission/feeding">
                                                            Feeding
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="btn-social" to="/student-support">
                                                            Student Support Unit
                                                        </Link>
                                                    </li>
                                                    {/* <li>
                                                        <Link className="btn-social" to="/clubs-and-societies">
                                                            Clubs and Societies
                                                        </Link>
                                                    </li> */}
                                                    {/*<li>*/}
                                                    {/*    <Link className="btn-social" to="/student-industrial-work-experience">*/}
                                                    {/*        SIWES*/}
                                                    {/*    </Link>*/}
                                                    {/*</li>*/}

                                                </ul>

                                            </li>


                                        </ul>
                                    </li>

                                    <li id="menu-item-3" className="uc-main-menu children">
                                        <a href="#">Academics</a>

                                        <ul className="level2 uc-submenu tabbed">

                                            <li className="uc-back">
                                                <a href="#"> Academics </a>
                                            </li>
                                            <li id="menu-item-3" className="overview">
                                                <a href="">Academics</a>
                                            </li>

                                            <li id="menu-item-3-2" className="uc-main-menu children">
                                                <a href="#">Study Overview</a>


                                                <ul className="level3 uc-submenu overview-link">

                                                    <li className="uc-back">
                                                        <a href="#">Study Overview</a>
                                                    </li>
                                                    <li id="menu-item-3-2" className="overview">
                                                        <a href="">Study</a>
                                                    </li>

                                                    <li>
                                                        <Link to="/academics/academic-calender">
                                                            Academic Calender
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/academics/academic-registry">
                                                            Academic Registry
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/academics/baze-education">
                                                            Education
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/academics/learning-goals">
                                                            Learning Goals
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/academics/staff-list">
                                                            Staff (Academic & Admin)
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/resources/staff-publication">
                                                            Staff Publications
                                                        </Link>
                                                    </li>

                                                </ul>

                                            </li>

                                            <li id="menu-item-3-1" className="uc-first-tab children">
                                                <a href="#">Undegraduate Study</a>
                                                <ul className="level3 uc-submenu overview-link">

                                                    <li className="uc-back">
                                                        <a href="#">Undegraduate Study</a>
                                                    </li>
                                                    <li id="menu-item-2-1" className="overview">
                                                        <a href="">Undegraduate Study</a>
                                                    </li>
                                                    {
                                                        faculty_list.length > 0 ?
                                                            faculty_list.filter(x => x.Slug !== 'postgraduate-school').map((faculty, index) => {
                                                                return <li key={index}>
                                                                    <Link to={`faculty/${faculty.Slug}`}>
                                                                        {faculty.FacultyName}
                                                                    </Link>
                                                                </li>

                                                            }) : ''

                                                    }

                                                </ul>

                                            </li>
                                            {/*<li id="menu-item-3-2" className="children">*/}
                                            {/*    <a href="#">Postgraduate Study</a>*/}


                                            {/*    <ul className="level3 uc-submenu overview-link">*/}

                                            {/*        <li className="uc-back">*/}
                                            {/*            <a href="#">Postgraduate Study</a>*/}
                                            {/*        </li>*/}
                                            {/*        <li id="menu-item-3-2" className="overview">*/}
                                            {/*            <a href="">Postgraduate Study</a>*/}
                                            {/*        </li>*/}

                                            {/*        /!* <li>*/}
                                            {/*            <Link className="btn-social" to="/student-support">*/}
                                            {/*                Dean, PG School*/}
                                            {/*            </Link>*/}
                                            {/*        </li> *!/*/}

                                            {/*        {*/}
                                            {/*            faculty_list.length > 0 ?*/}
                                            {/*                faculty_list.filter(x => x.Slug === 'postgraduate-school').map((faculty, index) => {*/}
                                            {/*                    return <li key={index}>*/}
                                            {/*                        <Link to={`faculty/${faculty.Slug}`}>*/}
                                            {/*                            The {faculty.FacultyName}*/}
                                            {/*                        </Link>*/}
                                            {/*                    </li>*/}

                                            {/*                }) : ''*/}

                                            {/*        }*/}

                                            {/*        <li>*/}
                                            {/*            <Link className="btn-social" to="/admission/course-finder?courses=phd">*/}
                                            {/*                Ph.D Courses*/}
                                            {/*            </Link>*/}
                                            {/*        </li>*/}
                                            {/*        <li>*/}
                                            {/*            <Link className="btn-social" to="/admission/course-finder?courses=msc">*/}
                                            {/*                MS.c Courses*/}
                                            {/*            </Link>*/}
                                            {/*        </li>*/}

                                            {/*        <li>*/}
                                            {/*            <Link className="btn-social" to="/admission/course-finder?courses=pgd">*/}
                                            {/*                PGD. Courses*/}
                                            {/*            </Link>*/}
                                            {/*        </li>*/}


                                            {/*    </ul>*/}

                                            {/*</li>*/}
                                            {/* <li id="menu-item-3-3" className="children">
                                                <a href="#">Short Courses</a>


                                                <ul className="level3 uc-submenu overview-link">

                                                    <li className="uc-back">
                                                        <a href="#">Short Courses</a>
                                                    </li>
                                                    <li id="menu-item-3-3" className="overview">
                                                        <a href="">Short Courses</a>
                                                    </li>

                                                    <li>
                                                        <Link to="/academics/short-courses">
                                                            Cou                                                                                                                                                                                                                  rse Finder
                                                        </Link>
                                                    </li>
                                                </ul>

                                            </li> */}


                                        </ul>

                                    </li>
                                    
                                    {/*<li id="menu-item-5" className="uc-main-menu children">*/}
                                    {/*    <a href="#" >Research</a>*/}
                                    
                                    {/*    <ul className="level2 uc-submenu tabbed menu-block">*/}
                                    {/*        <li className="uc-back">*/}
                                    {/*            <a href="#">Research</a>*/}
                                    {/*        </li>*/}
                                    {/*        <li id="menu-item-5" className="overview">*/}
                                    {/*            <a href="">Research</a>*/}
                                    {/*        </li>*/}
                                    
                                    
                                    {/*        <li id="menu-item-5-1" className="uc-first-tab children">*/}
                                    {/*            <a href="#" >Research</a>*/}
                                    
                                    {/*            <ul className="level3 uc-submenu overview-link">*/}
                                    
                                    {/*                <li className="uc-back">*/}
                                    {/*                    <a href="#">Research</a>*/}
                                    {/*                </li>*/}
                                    
                                    {/*                <li id="menu-item-5-1" className="overview">*/}
                                    {/*                    <a href="">Research</a>*/}
                                    {/*                </li>*/}
                                    
                                    {/*                /!*<li>*!/*/}
                                    {/*                /!*    <Link to="/research-at-baze">*!/*/}
                                    {/*                /!*        Research at Baba Ahmed University*!/*/}
                                    {/*                /!*    </Link>*!/*/}
                                    {/*                /!*</li>*!/*/}
                                    
                                    {/*                <li>*/}
                                    {/*                    <Link to="/research-areas">*/}
                                    {/*                        Research Areas*/}
                                    {/*                    </Link>*/}
                                    {/*                </li>*/}
                                    
                                    {/*                <li>*/}
                                    {/*                    <Link to="/researchers">*/}
                                    {/*                        Researchers*/}
                                    {/*                    </Link>*/}
                                    {/*                </li>*/}
                                    
                                    {/*                <li>*/}
                                    {/*                    <Link to="/research-facility">*/}
                                    {/*                        Research Facilities*/}
                                    {/*                    </Link>*/}
                                    {/*                </li>*/}
                                    
                                    {/*                <li>*/}
                                    {/*                    <Link to="/research-news">*/}
                                    {/*                        Research News*/}
                                    {/*                    </Link>*/}
                                    {/*                </li>*/}
                                    
                                    {/*                /!*<li>*!/*/}
                                    {/*                /!*    <Link to="/itri-office">*!/*/}
                                    {/*                /!*        IT, Research and Innovation Office*!/*/}
                                    {/*                /!*    </Link>*!/*/}
                                    {/*                /!*</li>*!/*/}
                                    
                                    {/*                /!*<li>*!/*/}
                                    {/*                /!*    <a className="btn-social" href={`https://drive.google.com/drive/folders/1g61sO-tlLRJc7iXzE9XMSLUnZ7MRRDzU`} target="_blank" >*!/*/}
                                    {/*                /!*        IT Research and Innovation Newsletter*!/*/}
                                    {/*                /!*    </a>*!/*/}
                                    {/*                /!*</li>*!/*/}
                                    
                                    {/*            </ul>*/}
                                    
                                    {/*        </li>*/}
                                    
                                    {/*        <li id="menu-item-5-1" className="children">*/}
                                    {/*            <a href="#">Research Publications and Output </a>*/}
                                    {/*            <ul className="level3 uc-submenu overview-link">*/}
                                    
                                    {/*                <li className="uc-back">*/}
                                    {/*                    <a href="#">Research Publications and Output</a>*/}
                                    {/*                </li>*/}
                                    
                                    {/*                <li id="menu-item-5-1" className="overview">*/}
                                    {/*                    <a href="">Research Publications and Output</a>*/}
                                    {/*                </li>*/}
                                    
                                    {/*                <li>*/}
                                    {/*                    <Link to="/resources/student-thesis">*/}
                                    {/*                        Students thesis*/}
                                    {/*                    </Link>*/}
                                    {/*                </li>*/}
                                    
                                    {/*                <li>*/}
                                    {/*                    <Link to="/resources/staff-publication">*/}
                                    {/*                        Staff Publications*/}
                                    {/*                    </Link>*/}
                                    {/*                </li>*/}
                                    
                                    {/*                <li>*/}
                                    {/*                    <Link to="*">*/}
                                    {/*                        Human Interest Story*/}
                                    {/*                    </Link>*/}
                                    {/*                </li>*/}
                                    
                                    {/*            </ul>*/}
                                    
                                    {/*        </li>*/}
                                    
                                    {/*        /!*<li id="menu-item-5-1" className="children">*!/*/}
                                    {/*        /!*    <a href="#">Support for Researchers</a>*!/*/}
                                    {/*        /!*    <ul className="level3 uc-submenu overview-link">*!/*/}
                                    
                                    {/*        /!*        <li className="uc-back">*!/*/}
                                    {/*        /!*            <a href="#">Support for Researchers</a>*!/*/}
                                    {/*        /!*        </li>*!/*/}
                                    
                                    {/*        /!*        <li id="menu-item-5-1" className="overview">*!/*/}
                                    {/*        /!*            <a href="">Support for Researchers</a>*!/*/}
                                    {/*        /!*        </li>*!/*/}
                                    
                                    {/*        /!*        <li>*!/*/}
                                    {/*        /!*            <Link to="/research-funding">*!/*/}
                                    {/*        /!*                Funding*!/*/}
                                    {/*        /!*            </Link>*!/*/}
                                    {/*        /!*        </li>*!/*/}
                                    
                                    {/*        /!*        <li>*!/*/}
                                    {/*        /!*            <Link to="/research/career-development">*!/*/}
                                    {/*        /!*                Career Development*!/*/}
                                    {/*        /!*            </Link>*!/*/}
                                    {/*        /!*        </li>*!/*/}
                                    
                                    {/*        /!*        <li>*!/*/}
                                    {/*        /!*            <Link to="/colaborate-with-us">*!/*/}
                                    {/*        /!*                Collaborate with us*!/*/}
                                    {/*        /!*            </Link>*!/*/}
                                    {/*        /!*        </li>*!/*/}
                                    
                                    {/*        /!*    </ul>*!/*/}
                                    
                                    {/*        /!*</li>*!/*/}
                                    
                                    {/*         /!*Research Centres*!/*/}
                                    
                                    {/*        /!*<li id="menu-item-5-1" className="children">*!/*/}
                                    {/*        /!*    <a href="#">Research Centres</a>*!/*/}
                                    {/*        /!*    <ul className="level3 uc-submenu overview-link">*!/*/}
                                    
                                    {/*        /!*        <li className="uc-back">*!/*/}
                                    {/*        /!*            <a href="#">Research Centres</a>*!/*/}
                                    {/*        /!*        </li>*!/*/}
                                    
                                    {/*        /!*        <li id="menu-item-5-1" className="overview">*!/*/}
                                    {/*        /!*            <a href="">Research Centres</a>*!/*/}
                                    {/*        /!*        </li>*!/*/}
                                    
                                    {/*        /!*        <li>*!/*/}
                                    {/*        /!*            <Link to="/center-for-clean-energy-and-climate-change">*!/*/}
                                    {/*        /!*                Center for Clean Energy & Climate Change*!/*/}
                                    {/*        /!*            </Link>*!/*/}
                                    {/*        /!*        </li>*!/*/}
                                    
                                    {/*        /!*        /!*<li>*!/*!/*/}
                                    {/*        /!*        /!*    <Link to="/artificial-intelligence-and-blockchain-technology-centre">*!/*!/*/}
                                    {/*        /!*        /!*        Artificial Intelligence and Blockchain Technology Centre*!/*!/*/}
                                    {/*        /!*        /!*    </Link>*!/*!/*/}
                                    {/*        /!*        /!*</li>*!/*!/*/}
                                    
                                    {/*        /!*        /!*<li>*!/*!/*/}
                                    {/*        /!*        /!*    <Link to="/center-for-big-data-analysis-egovernance-innovation">*!/*!/*/}
                                    {/*        /!*        /!*        Centre for Big Data Analytics, eGovernance and Innovation*!/*!/*/}
                                    {/*        /!*        /!*    </Link>*!/*!/*/}
                                    {/*        /!*        /!*</li>*!/*!/*/}
                                    
                                    {/*        /!*        <li>*!/*/}
                                    {/*        /!*            <Link to="/centre-for-foundation-and-interdisciplinary-studies">*!/*/}
                                    {/*        /!*                Centre for Foundation and Interdisciplinary Studies*!/*/}
                                    {/*        /!*            </Link>*!/*/}
                                    {/*        /!*        </li>*!/*/}
                                    
                                    {/*        /!*        <li>*!/*/}
                                    {/*        /!*            <Link to="/center-for-peace-and-anti-conflict-journalism">*!/*/}
                                    {/*        /!*                Peace and Anti-Conflict Journalism Research Center*!/*/}
                                    {/*        /!*            </Link>*!/*/}
                                    {/*        /!*        </li>*!/*/}
                                    
                                    {/*        /!*        /!*<li>*!/*!/*/}
                                    {/*        /!*        /!*    <Link to="/law-clinic">*!/*!/*/}
                                    {/*        /!*        /!*        Baze Law Clinic*!/*!/*/}
                                    {/*        /!*        /!*    </Link>*!/*!/*/}
                                    {/*        /!*        /!*</li>*!/*!/*/}
                                    {/*        /!*    </ul>*!/*/}
                                    
                                    {/*        /!*</li>*!/*/}
                                    {/*    </ul>*/}
                                    
                                    {/*</li>*/}

                                    <li id="menu-item-4" className="uc-main-menu children">
                                        <a href="#" >Resources</a>

                                        <ul className="level2 uc-submenu tabbed">

                                            <li className="uc-back">
                                                <a href="#">Resources</a>
                                            </li>

                                            <li id="menu-item-4" className="overview">
                                                <a href="">Resources</a>
                                            </li>


                                            <li id="menu-item-4-1" className="uc-first-tab children">
                                                <a href="#">Resources</a>


                                                <ul className="level3 uc-submenu overview-link">

                                                    <li className="uc-back">
                                                        <a href="#">Resources</a>
                                                    </li>
                                                    <li id="menu-item-3-1" className="overview">
                                                        <a href="">Resources</a>
                                                    </li>

                                                    <li>
                                                        <Link to="/academics/academic-calender">
                                                            Academic Calender
                                                        </Link>
                                                    </li>

                                                    {/*<li>*/}
                                                    {/*    <Link to="/resources/documents-and-handbooks">*/}
                                                    {/*        Program Handbooks*/}
                                                    {/*    </Link>*/}
                                                    {/*</li>*/}

                                                    <li>
                                                        <Link to="/resources/intercom-directory">
                                                            Intercom Directory
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <a href="https://ocw.mit.edu/index.htm" target="_blank">MIT Open Course Ware</a>
                                                    </li>

                                                    <li>
                                                        <Link to="/academics/staff-list">
                                                            Staff (Academic & Admin)
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/resources/student-thesis">
                                                            Student Thesis
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/resources/staff-publication">
                                                            Staff Publications
                                                        </Link>
                                                    </li>
                                                    {/*<li>*/}
                                                    {/*    <a href="https://portal.bazeuniversity.edu.ng/jobs/" target={"_blank"} className="btn-social" >*/}
                                                    {/*        Careers*/}
                                                    {/*    </a>*/}
                                                    {/*</li>*/}


                                                    {/*Open Access Journal*/}

                                                    {/*<li>*/}
                                                    {/*    <a href="https://doaj.org/" target="_blank">Open Access Journal</a>*/}
                                                    {/*</li>*/}

                                                    {/*<li>*/}
                                                    {/*    <a className="btn-social" href={`https://www.youtube.com/@bazeuniversitysocialmedia5022`} target="_blank" >*/}
                                                    {/*        YouTube*/}
                                                    {/*    </a>*/}
                                                    {/*</li>*/}

                                                    {/*<li>*/}
                                                    {/*    <a className="btn-social" href={Bronchure} target="_blank" >*/}
                                                    {/*        Baze Brochure*/}
                                                    {/*    </a>*/}
                                                    {/*</li>*/}

                                                </ul>


                                            </li>

                                            
                                            {/*<li id="menu-item-4-3" className="children">*/}
                                            {/*    <a href="#">Library</a>*/}


                                            {/*    <ul className="level3 uc-submenu overview-link">*/}

                                            {/*        <li className="uc-back">*/}
                                            {/*            <a href="#"> Library</a>*/}
                                            {/*        </li>*/}
                                            {/*        <li id="menu-item-4-3" className="overview">*/}
                                            {/*            <a href="">Library</a>*/}
                                            {/*        </li>*/}

                                            {/*        <li>*/}
                                            {/*            <Link to="/librarian-office">*/}
                                            {/*                Office of the Librarian*/}
                                            {/*            </Link>*/}
                                            {/*        </li>*/}

                                            {/*        <li>*/}
                                            {/*            <a href="https://calibre.bazeuniversity.edu.ng/" target="_blank">Calibre/Ebooks</a>*/}
                                            {/*        </li>*/}

                                            {/*        <li>*/}
                                            {/*            <a href="https://koha.bazeuniversity.edu.ng/" target="_blank">Catalogue (Koha)</a>*/}
                                            {/*        </li>*/}

                                            {/*        <li>*/}
                                            {/*            <a href="https://dspace.bazeuniversity.edu.ng/jspui/" target="_blank">DSpace/OER</a>*/}
                                            {/*        </li>*/}

                                            {/*        /!*<li>*!/*/}
                                            {/*        /!*    <a href="https://library.bazeuniversity.edu.ng/" target="_blank">Main Library</a>*!/*/}
                                            {/*        /!*</li>*!/*/}



                                            {/*        /!* <li>*/}
                                            {/*            <Link to="/resources/past-exam-questions">*/}
                                            {/*                Past Exam Questions*/}
                                            {/*            </Link>*/}
                                            {/*        </li> *!/*/}

                                            {/*    </ul>*/}


                                            {/*</li>*/}


                                            {/*<li id="menu-item-4-4" className="children">*/}
                                            {/*    <a href="#">Journals</a>*/}


                                            {/*    <ul className="level3 uc-submenu overview-link">*/}

                                            {/*        <li className="uc-back">*/}
                                            {/*            <a href="#"> Journals</a>*/}
                                            {/*        </li>*/}
                                            {/*        <li id="menu-item-4-4" className="overview">*/}
                                            {/*            <a href="">Journals</a>*/}
                                            {/*        </li>*/}

                                            {/*        /!*<li>*!/*/}
                                            {/*        /!*    <a target={"_blank"} href="https://journal.bazeuniversity.edu.ng/index.php/bujeis" >*!/*/}

                                            {/*        /!*        Baba Ahmed University Journal of Entrepreneurship and Interdisciplinary Studies*!/*/}
                                            {/*        /!*    </a>*!/*/}
                                            {/*        /!*</li>*!/*/}

                                            {/*        /!*<li>*!/*/}
                                            {/*        /!*    <a target={"_blank"} href="https://journal.bazeuniversity.edu.ng/index.php/jirbdai" >*!/*/}
                                            {/*        /!*        Journal of Institutional Research, Big Data Analytics and Innovation*!/*/}
                                            {/*        /!*    </a>*!/*/}
                                            {/*        /!*</li>*!/*/}

                                            {/*        /!*<li>*!/*/}
                                            {/*        /!*    <a target={"_blank"} href="https://journal.bazeuniversity.edu.ng/index.php/bualj" >*!/*/}

                                            {/*        /!*        Baba Ahmed University Law Journal*!/*/}
                                            {/*        /!*    </a>*!/*/}
                                            {/*        /!*</li>*!/*/}

                                            {/*        /!*<li>*!/*/}
                                            {/*        /!*    <a target={"_blank"} href="https://journal.bazeuniversity.edu.ng/index.php/bujmss" >*!/*/}

                                            {/*        /!*        Baba Ahmed University Journal of Management and Social Sciences*!/*/}
                                            {/*        /!*    </a>*!/*/}
                                            {/*        /!*</li>*!/*/}


                                            {/*        /!*<div className="row col-md-12" style={{ float: 'right', textAlign: 'right', marginTop: '40px' }} >*!/*/}
                                            {/*        /!*    <a href="https://journal.bazeuniversity.edu.ng/" target={"_blank"}>More</a>*!/*/}
                                            {/*        /!*</div>*!/*/}


                                            {/*    </ul>*/}




                                            {/*</li>*/}

                                            {/*<li id="menu-item-4-4" className="children">*/}
                                            {/*    <a href="#">Policies</a>*/}


                                            {/*    <ul className="level3 uc-submenu overview-link">*/}

                                            {/*        <li className="uc-back">*/}
                                            {/*            <a href="#"> Policies</a>*/}
                                            {/*        </li>*/}
                                            {/*        <li id="menu-item-4-4" className="overview">*/}
                                            {/*            <a href="">Policies</a>*/}
                                            {/*        </li>*/}

                                            {/*        /!*<li>*!/*/}
                                            {/*        /!*    <a href="https://drive.google.com/drive/folders/13yUBzfKLmYqyeA67RXlws-tDgeMeLNC7" target={"_blank"}>Reasearch and Innovation Policy</a>*!/*/}
                                            {/*        /!*</li>*!/*/}

                                            {/*        <li>*/}
                                            {/*            <a href="https://drive.google.com/drive/folders/12coaj68OowyZYqPm_LHNciCEfGaZGWjC" target={"_blank"}>Digital Learning Policy</a>*/}
                                            {/*        </li>*/}

                                            {/*        <li>*/}
                                            {/*            <a href="https://drive.google.com/drive/folders/1pi9zLFCEtoBvnLo7v57VDdJkoW_ZhkZq" target={"_blank"}>ICT Acceptable Use Policy</a>*/}
                                            {/*        </li>*/}

                                            {/*        <li>*/}
                                            {/*            <a href="https://drive.google.com/drive/folders/1z6VvJ5pGxCg7_QoYkYiRjl877lqeXq-Z" target={"_blank"}>Intellectual Property (IP) Policy</a>*/}
                                            {/*        </li>*/}
                                            {/*        <li>*/}
                                            {/*            <a href="https://drive.google.com/drive/folders/1vE4M05gGNHVSRGqys1SQTMxOQS1fQma3" target={"_blank"}>*/}
                                            {/*                Online Teaching, Learning and Assessment Policy*/}
                                            {/*            </a>*/}
                                            {/*        </li>*/}
                                            {/*        <li>*/}
                                            {/*            <a href="https://drive.google.com/drive/folders/1z6VvJ5pGxCg7_QoYkYiRjl877lqeXq-Z" target={"_blank"}>*/}
                                            {/*                Staff Policy*/}
                                            {/*            </a>*/}
                                            {/*        </li>*/}

                                            {/*        <li>*/}
                                            {/*            <a href="https://drive.google.com/file/d/1_8uT4qgbJuGWgmDbzH2zkp9ltluvBmCs" target={"_blank"}>*/}
                                            {/*                Attendance, Participation & Examination Eligibility Guidelines*/}
                                            {/*            </a>*/}
                                            {/*        </li>*/}

                                            {/*        <div className="row col-md-12" style={{ float: 'right', textAlign: 'right', marginTop: '40px' }} >*/}
                                            {/*            <a href="https://drive.google.com/drive/folders/1vE4M05gGNHVSRGqys1SQTMxOQS1fQma3" target={"_blank"}>More</a>*/}
                                            {/*        </div>*/}



                                            {/*    </ul>*/}


                                            {/*</li>*/}


                                        </ul>

                                    </li>

                                    <li id="menu-item-5" className="uc-main-menu children">
                                        <a href="#" >Portals</a>

                                        <ul className="level2 uc-submenu tabbed">
                                            <li className="uc-back">
                                                <a href="#">Portals</a>
                                            </li>
                                            <li id="menu-item-5" className="overview">
                                                <a href="">Portals</a>
                                            </li>


                                            <li id="menu-item-5-1" className="uc-first-tab children">
                                                <a href="#" >Portals</a>


                                                <ul className="level3 uc-submenu overview-link">

                                                    <li className="uc-back">
                                                        <a href="#">Portals</a>
                                                    </li>
                                                    <li id="menu-item-5-1" className="overview">
                                                        <a href="">Portals</a>
                                                    </li>

                                                    <li>
                                                        <a href="https://portal.babaahmeduniversity.edu.ng/admission" target="_blank">Admission Portal</a>
                                                    </li>

                                                    {/*<li>*/}
                                                    {/*    <a href="https://alumni.bazeuniversity.edu.ng" target="_blank">Alumni Portal</a>*/}
                                                    {/*</li>*/}

                                                    {/* <li>
                                                        <a href="https://portal.bazeuniversity.edu.ng/hostel" target="_blank">Hostel Portal</a>
                                                    </li> */}

                                                    <li>
                                                        <a href="https://portal.babaahmeduniversity.edu.ng/help_desk" target="_blank">IT Help Desk</a>
                                                    </li>

                                                    {/*<li>*/}
                                                    {/*    <a href="https://library.bazeuniversity.edu.ng" target="_blank">Library</a>*/}
                                                    {/*</li>*/}

                                                    <li>
                                                        <a href="https://portal.babaahmeduniversity.edu.ng/enrolment" target="_blank">New Student's Enrolment Portal</a>
                                                    </li>

                                                    {/* <li>
                                                        <a href="https://portal.bazeuniversity.edu.ng/parent" target="_blank">Parent Portal</a>
                                                    </li> */}

                                                    <li>
                                                        <a href="https://portal.babaahmeduniversity.edu.ng/payment" target="_blank">Payment Portal</a>
                                                    </li>

                                                    <li>
                                                        <a href="https://portal.babaahmeduniversity.edu.ng/student" target="_blank">Student Portal</a>
                                                    </li>

                                                    <li>
                                                        <a href="https://portal.babaahmeduniversity.edu.ng/staff" target="_blank">Staff Portal</a>
                                                    </li>

                                                    {/*<li>*/}
                                                    {/*    <a href="https://portal.bazeuniversity.edu.ng/transcript" target="_blank">Transcript Portal</a>*/}
                                                    {/*</li>*/}

                                                    <li>
                                                        <a href="https://mail.google.com/" target="_blank">Web Mail</a>
                                                    </li>

                                                    {/*<li>*/}
                                                    {/*    <a href="https://verify.bazeuniversity.edu.ng" target="_blank">Certificate Verifier</a>*/}
                                                    {/*</li>*/}


                                                </ul>


                                            </li>

                                        </ul>

                                    </li>

                                    <li id="menu-item-5" className="uc-main-menu children">
                                        <a href="#" >Contact</a>

                                        <ul className="level2 uc-submenu tabbed menu-block">
                                            <li className="uc-back">
                                                <a href="#">Contact</a>
                                            </li>
                                            <li id="menu-item-5" className="overview">
                                                <a href="">Contact</a>
                                            </li>

                                            {/*<li className="menu-block-item">*/}
                                            {/*    <div className="uc-menu-advert">*/}
                                            {/*        <p>Get in touch: </p>*/}
                                            {/*        <p><a href="mailto:admissions@bazeuniversity.edu.ng"><i className="fa fa-envelope" /> Admission: admissions@bazeuniversity.edu.ng</a></p>*/}
                                            {/*        <p><a href="mailto:inquiries@bazeuniversity.edu.ng"><i className="fa fa-envelope" /> General: inquiries@bazeuniversity.edu.ng</a></p>*/}
                                            {/*        <p><a href="tel:+2349062011188"><i className="fa fa-phone" />+234 906 201 1188</a></p>*/}
                                            {/*        <p><b>OR</b></p>*/}
                                            {/*        <p><a href="tel:+2349062011194"><i className="fa fa-phone" />+234 906 201 1194</a></p>*/}


                                            {/*    </div>*/}
                                            {/*</li>*/}

                                            <li id="menu-item-5-1" className="uc-first-tab children">
                                                <a href="#" >Contact</a>


                                                <ul className="level3 uc-submenu overview-link">

                                                    <li className="uc-back">
                                                        <a href="#">Contact</a>
                                                    </li>
                                                    <li id="menu-item-5-1" className="overview">
                                                        <a href="">Contact</a>
                                                    </li>

                                                    <li>
                                                        <Link to="/contact/contact-us">
                                                            Map and Contact Information
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/about/our-campus">
                                                            Our Campus
                                                        </Link>
                                                    </li>

                                                    {/*<li>*/}
                                                    {/*    <Link to="/gallery">*/}
                                                    {/*        Gallery*/}
                                                    {/*    </Link>*/}
                                                    {/*</li>*/}

                                                    <li>
                                                        <Link to="/contact-telephone">
                                                            Contact Us
                                                        </Link>
                                                    </li>

                                                </ul>


                                            </li>

                                        </ul>

                                    </li>



                                </ul>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </div >
}

export default Menubar;
