import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import BabaAhmedSquare from "../../../images/faculties/square.jpg";
import HRLine from "../../utils/hrline";
import ITRIDVC from "../itridvc";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { Link } from "react-router-dom";
import VCImage from './registrar.png'

class RegistrarOffice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            members: []
        }
    }
    render() {
        return <>
            <Banner image={require("../images/registrar.png")} title="Office Of The Registrar" caption="" disableTitle />

            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <span>
                                                <h3>Dr. Jamila Shu'ara</h3>
                                                <h5><strong>Registrar</strong></h5>
                                                <p style={{ marginTop: '20px' }} >
                                                    Dr Jamila Shu'ara is an alumnus of Howard University Washington DC, USA and Bayero University Kano, Nigeria with a speciality in Consumer Sciences. At Howard University, she was recognized severally on the Dean's List and the Dean's Honours Roll and was awarded a tuition-free scholarship for her studies. She is a Teacher by Profession, a Fellow of the Nigerian Institute of Management, and a Fellow of the Home Economics Professionals Association of Nigeria (HEPAN).
                                                    She commenced public service as a Lecturer and rose through academic ranks to the terminal post of Chief Lecturer in the Nigerian Colleges of Education system, and she held administrative positions of Head of Department, Dean, Deputy Provost, and Provost of a Federal College of Education. She participated as a Resource person on several NCCE Accreditation Teams to many Colleges of Education.  She has several Books, Journal Articles, and Technical Reports to her credit.
                                                    She commenced public service as a Lecturer and rose through academic ranks to the terminal post of Chief Lecturer in the Nigerian Colleges of Education system, and she held administrative positions of Head of Department, Dean, Deputy Provost, and Provost of a Federal College of Education. She participated as a Resource person on several NCCE Accreditation Teams to many Colleges of Education.  She has several Books, Journal Articles, and Technical Reports to her credit.
                                                    She transferred her service to the Federal Civil Service Commission on the Education Officers cadre and was posted to the Federal Ministry of Education (FME), Headquarters Abuja. She had a rich career in the federal bureaucracy and was deployed in various capacities, notable of these were:
                                                    <ul style={{listStyle : "initial"}}>
                                                        <li>Pioneer Director at the Teachers Registration Council in charge of Administration & Finance where she was directly in charge of the take-off of the organization and worked with colleagues to develop the Template for the recognition and registration of qualified Teachers in Nigeria;</li>
                                                        <li>National Coordinator of the FME National Project Coordinating Unit to manage the FME/World Bank/ Universal Basic Education Project (UBEP) II which had a USD $100 million draw-down components in basic education institutions for capacity building and infrastructure development in eleven states and FME. The successful completion of this intervention birthed STEP-B;</li>
                                                        <li>Director of the Department of Tertiary Education at the Federal Ministry of Education for over six years and her job schedule was high-level policy formulation and the management of the entire tertiary education sector including all Nigerian Universities, Polytechnics, Monotechnics, Innovative Enterprise Institutions, Colleges of Education, vibrant labour unions, supervision of Regulatory Agencies as the Tertiary Education Trust Fund, the National Universities Commission, the National Board for Technical Education, the National Commission for Colleges of Education, the National Teachers Institute, the Joint Admissions & Matriculation Board, the National Mathematical Centre, among others.</li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    In 2012, Dr Jamila Shu'ara, having passed a set of rigorous written and oral examinations, was appointed a Federal Permanent Secretary; the only Education Officer in the history of the Federal Ministry of Education to have achieved this feat. She was subsequently deployed to six highly challenging and sensitive areas of governance including the Office of Secretary to the Government of the Federation (OSGF); the Office of the Head of the Civil Service of the Federation (OHCSF), the Ministry of Aviation; Ministry of Petroleum Resources; Ministry of Special Duties & Intergovernmental Affairs; and the Federal Ministry of Education. In all these deployments, she proved her mettle.
                                                     <ul style={{listStyle : "initial"}}>
                                                         <li>At the Office of Secretary to the Government of the Federation (OSGF), her dogged leadership enabled the Special Duties Office to produce a Compendium of all the Recipients of the National Honours Award from its inception in 1963 to 2012; the absence of good documentation had culminated in needless embarrassment from double nominations;</li>
                                                         <li>At the Office of the Head of the Civil Service of the Federation (OHCSF), her leadership at the Career Management Office improved the conduct and management of promotion examinations for civil servants. She also developed parameters to upgrade the academic status of Federal Training Centers from mere in-service arrangements to accredited Monotechnics that award academically recognized Certificates and equivalents;</li>
                                                         <li>At the Federal Ministry of Aviation, she galvanized the Parastatals (FAAN, NCAA, NIMET, NAMA, NCAT, AIB) into the Team that ensured the re-certification of Nigeria's Airport facilities as Category One (CAT.I) by the US National Transport and Safety Board (NTSB), this was particularly important for Nigeria's Open Skies efforts in tourism and business. Under her guidance, the Ministry continued the Airport re-modelling projects; escalated the need to quickly address the repair of the failed sections of the only Runway of Abuja Airport and the urgent need to build the 2nd Runway; proposed and implemented capacity sustainability and succession plans for Aviation Bureaucrats, etc;</li>
                                                         <li>At the Ministry of Special Duties and Intergovernmental Affairs, she harnessed the competencies, capacities, and expertise of staff to develop assessment instruments to monitor and evaluate the federal government's programmes and projects in order to determine impact;</li>
                                                         <li>At the Ministry of Petroleum Resources, she brought a wealth of experience to reduce shutdowns and restiveness of the vibrant trade unions instituted real-time tracking devices for the movement of petroleum products; put in place effective sanctions where the petroleum products distribution channels were deliberately thwarted; implemented rich policies on institutional memory, respect for due process; environmental sustainability, reduction in pollution and gas flare, robust community engagement; tackled debt and budget deficits with oil majors; and the produced the draft Petroleum Industry Governance Bill (PIGB) now under legislative processing, etc;</li>
                                                         <li>At the Federal Ministry of Education, she advocated the responsible use of Bilateral Education Agreements (BEA) Scholarships, sustained fiscal commitments to international development partners advocated quality assurance for educational institutions, and promoted transparency in the use of public funds, etc.</li>
                                                     </ul>
                                                </p>
                                                <p>
                                                    During her career in 1993, she initiated, formed, and nurtured the National Association of Women in Colleges of Education (WICE) to assist women to fill career capacity gaps in a sector that greatly needed a hammer to shatter the glass ceiling. Today, women in more than one hundred COEs have a strong network under WICE, with over fifty thousand active members from every geographical zone of Nigeria. Furthermore, arising from the several affirmative actions on women's career development, which she championed; there are many highly qualified professional women occupying top academic and management positions in the COEs. She is a Life Member of WICE in recognition of her contributions to that sector. She was a former Vice President of the Forum of African Women Educationalists (FAWE) Nigeria - a group recognized and funded by the African Union for Women advocacy and improved quality of life through education. She is also the Chairperson of the Board of Trustees of the Inspire1Network - a non-governmental organization that inspires girls and women for a better life.
                                                </p>
                                                <p>
                                                    Dr Jamila Shu'ara has a very robust experience in public institutions governance and high-level private sector management; at various times, she was a member of the Governing Council of many Federal Universities, Polytechnics, and Colleges. She has served as Chairperson or Member on more than fifty (50) Federal Government Committees on various domestic matters; education, health, public sector financing, homeland security, etc. She also served on multi-lateral Boards; notably, she was also a member Board of Directors, of Bonny Gas Transport Ltd (BGT); Nigeria Liquefied Natural Gas Ltd (NLNG) Bonny Island; Nigeria's Governor to the Organization of Petroleum Exporting Countries (OPEC) in Vienna,  Head of Delegation of Nigeria to the 167th OPEC Conference, Vienna and the President, Committee of Experts, African Petroleum Producers Association (APPA).
                                                </p>
                                                <p>
                                                    Dr Jamila Shu'ara FNIM, FHEPAN, who was the Director, of Academic Planning, Strategy & Special Duties, has been recently appointed as the REGISTRAR at Baba Ahmed University Abuja. She brings on board robust expertise in the governance of Tertiary Education Institutions (TEIs), Public Administration, and Bureaucracy. She is also an Associate Professor of Consumer Sciences in the Department of Marketing and her schedule in the University includes teaching; academic planning, resource verification, academic and professional accreditation of programmes; brand visibility; media and social media publicity; etc.  She has authored many academic publications and is a Peer Reviewer for many Published Journals, some of which are listed on the Web of Science. She is also the Director of Academic Planning Strategy & Special Duties.
                                                </p>

                                            </span>
                                        </div>
                                    </div>

                                    <HRLine />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">

                                                <div className="col-md-2">
                                                    <div className="media-left">
                                                        <span
                                                            style={{ fontSize: "60px" }}
                                                            className="icon icomoon ucws-business-and-finance-cockade-line"
                                                            aria-hidden="true" />
                                                    </div>

                                                </div>
                                                <div className="col-md-10">
                                                    <h3>Office of the Registrar</h3>
                                                    <p>
                                                        The Office of the registrar supports the Vice-Chancellor to ensure effective and efficient administration of the University.  The Registrar shall be Head of the Department of Administration (i.e registry) of the university and will be responsible to the Vice-Chancellor for the day-to-day administration of the university.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="media more-info-item">
                                                <div className="media-body">
                                                    <img src={VCImage} width="500px" height="500px" style={{ borderRadius: '7px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <HRLine />

                                    <div className="row header">
                                        <div className="col-sm-12 text-justify">
                                            <h3>Our Responsibilities:</h3>
                                            <p>In line with the vision and Mission statement of the University the Office of the Registrar has the following specific responsibilities: </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="more-info-items two-items">
                                                <div className="media more-info-item">
                                                    <div className="media-body">
                                                        <p>
                                                            <ul style={{listStyle : "initial"}}>
                                                                <li>The Registrar shall be the custodian of the records, seal, other legal documents and traditions of the university. </li>
                                                                <li>The Registrar shall be the secretary to all statutory organs of the university, and will sit in advisory capacity at all committees of the board and senate as well as other administrative committees to guide on rules, regulations and university procedure.</li>
                                                                <li> The Registrar will be responsible for conveying information on the decisions of various organs of the institution to the relevant departments and units for effective implementation.</li>
                                                            </ul>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="media more-info-item">
                                                    <p>
                                                        <ul style={{listStyle : "initial"}}>

                                                            <li>
                                                                A Statute to be proved in any court shall bear or have affixed to it a certificate signed by the Registrar to the effect that a copy is a true copy of a statute of the university.
                                                            </li>
                                                            <li>
                                                                EThe Registrar shall be the custodian of the seal and other legal documents of the university except as regards matters for which the Bursar is responsible.
                                                            </li>
                                                            <li>
                                                                Ensure that organisational Units in the University remain focused on the vision, mission and strategic goals of the founder of the University.
                                                            </li>
                                                        </ul>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>;
    }
}

export default RegistrarOffice;
