import React, { Component } from "react";
import "./calltoaction.css";
import { Link, useNavigate } from "react-router-dom";
import { serverLink } from "../../../../resources/url";
import axios from "axios";
import { useState } from "react";
import { connect } from "react-redux";
import CourseFinder from "../../../admission/course-finder/course-finder";

const CallToAction = (props) => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("Loading result. Please wait!");
    const [courses, setCourses] = useState(props.courseList.length > 0 ? props.courseList : []);
    const [coursesListData] = useState(props.courseList.length > 0 ? props.courseList : []);

    const [fdata, setfData] = useState({
        courseName: "",
        courseLevel: ""
    })

    const onEdit = (e) => {
        if (e.target.value !== "") {
            navigate('/admission/course-finder')
        } else {

        }
    }

    return (
        <div>

            <div className="information-boxes module">
                <div className="courses-info col-md-6 col-sm-12">
                    <div className="">
                        <h4 style={{ color: "#fff" }}>
                            <i className="fa fa-book" aria-hidden="true" /> Pre Degree
                            Admission
                        </h4>
                        <ul className="no-bullet">
                            {/*<li><i className="fa fa-check-square-o" aria-hidden="true" /> Pick up form at the*/}
                            {/*    University's Admissions Office OR*/}
                            {/*</li>*/}
                            <li><i className="fa fa-check-square-o" aria-hidden="true" /> Fill the online
                                Application Form
                            </li>
                            <li><i className="fa fa-check-square-o" aria-hidden="true" />Await a confirmation
                                email of approval
                            </li>
                        </ul>
                        <div className="clearfix" />
                        <Link  to="/admission/application-guide">
                            <button className="btn btn-secondary">
                                Apply Now
                            </button>
                        </Link>
                    </div>
                </div>

                <div className="faculty-info col-md-6 col-sm-12">
                    <div className="">
                        <h4 style={{ color: "#fff" }}><i className="fa fa-users" aria-hidden="true" /> Undergraduate
                            Admission </h4>
                        <ul className="no-bullet">
                            <li><i className="fa fa-check-square-o" aria-hidden="true" /> Obtain JAMB Form and
                                meet the cut-off point
                            </li>
                            <li><i className="fa fa-check-square-o" aria-hidden="true" /> Must have at least 5
                                credit in O'level (WAEC/NECO/NABTEB)
                            </li>
                            {/*<li><i className="fa fa-check-square-o" aria-hidden="true" /> Apply on Baba Ahmed University*/}
                            {/*    website*/}
                            {/*</li>*/}
                        </ul>
                        <div className="clearfix" />
                        <Link to="/admission/application-guide">
                            <button className="btn btn-secondary">
                                Apply Now
                            </button>
                        </Link>
                    </div>
                </div>

                {/*<div className="newadmission-info col-md-4 col-sm-12">*/}
                {/*    <div className="">*/}
                {/*        <h4 style={{ color: "#fff" }}><i className="fa fa-sign-in" aria-hidden="true" /> Postgraduate*/}
                {/*            Admission </h4>*/}
                {/*        <ul className="no-bullet">*/}
                {/*            /!*<li><i className="fa fa-check-square-o" aria-hidden="true" /> Pick up form at the*!/*/}
                {/*            /!*    University's Admissions Office OR*!/*/}
                {/*            /!*</li>*!/*/}
                {/*            <li><i className="fa fa-check-square-o" aria-hidden="true" /> Fill the online*/}
                {/*                Application Form*/}
                {/*            </li>*/}
                {/*            <li><i className="fa fa-check-square-o" aria-hidden="true" />Await a confirmation*/}
                {/*                email of approval*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*        <div className="clearfix" />*/}
                {/*        <Link to="/faculty/postgraduate-school">*/}
                {/*            <button className="btn btn-secondary">*/}
                {/*                Apply Now*/}
                {/*            </button>*/}
                {/*        </Link>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="clearfix" />


            </div>

            <div className="container-fluid roundable block" style={{ paddingTop: "20px" }}>
                <div className="row">
                    <div className="container">

                        <div className="row news-items light-grey-accents">
                            <div className="coursecard finder_bg">
                                <div className="col-sm-12">
                                    <div className="white-text" ><h2>Find Your Course</h2></div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <select className="form-control form-select" id="courseLevel" onChange={onEdit} value={fdata.courseLevel} >
                                                <option value={""}>-select level-</option>
                                                <option value={"ug"}>Undergraduate</option>
                                                <option value={"pg"}>Postgraduate</option>
                                            </select>
                                        </div>
                                        {/* <div className="col-md-8">
                                            <input type={"text"} className="textBox" id="courseName" onChange={onEdit} value={fdata.courseName} />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        facultyList: state.BazeFacultyListDetails,
        courseList: state.BazeCourseListDetails
    };
};


export default connect(mapStateToProps, null)(CallToAction);

