import React from "react";
import Banner from "../../utils/banner/banner";
import education from "../../../images/general/academics.jpeg";
import {Link} from "react-router-dom";

function AcademicRegistry () {
    return <>
        <Banner image={education} title="Academic Registry" caption=""/>
        <div className="container-fluid layout col1" style={{margin: "20px"}}>
            <div className="row">
                <div className="layout-col layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-text container-fluid roundable block text">
                        <div className="row">
                            <div className="container">
                                <div className="row left">
                                    <div className="col-sm-9 one-col ">
                                        <h2>About the Academic Registry</h2>
                                        <p style={{textAlign: 'justify', marginBottom: '30px'}}>The Academic Registry acts as the custodian for student records and academic information at the university. This includes interacting with both students and faculty to provide services in the following areas:</p>

                                        <ul style={{listStyleType: 'none'}}>
                                            <li> <i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/>  Academic calenders</li>
                                            <li> <i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/>  Academic history</li>
                                            <li> <i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/>  Campus enrollment support</li>
                                            <li> <i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/>  Classroom scheduling</li>
                                            <li> <i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/>  Enforcement of academic policies</li>
                                            <li> <i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/>  Faculty/staff support</li>
                                            <li> <i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/>  Graduation planning and certification</li>
                                            <li> <i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/>  Enrollment verification</li>
                                            <li> <i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/>  Transcripts requests</li>
                                            <li> <i className="fa fa-angle-right" style={{fontWeight: 'bold', fontSize: '18px'}}/>  Compilation of student data reports</li>
                                        </ul>
                                        <p style={{textAlign: 'justify', marginTop: '20px'}}> Whether you are a parent seeking advice on how to stay connected with your ward’s academic work, a current student with questions about the registration process or an alumni looking to get academic documents, our goal is for your interaction with the Academic Registry to be both efficient and helpful.</p>
                                    </div>
                                    <div className="col-md-3">
                                        <div style={{ background: '#f4f4f4', padding: '8px', borderRadius: '5px' }}>
                                            <h3 className="nav-top">Academics</h3>
                                            <ul className="tabs">
                                                <Link to={"/academics/baze-education"}>
                                                    <li>
                                                        The Baba Ahmed University Education
                                                        <i className="fa fa-angle-right pull-right icons" />
                                                    </li>
                                                </Link>
                                                <Link to={"/academics/learning-goals"}>
                                                    <li>
                                                        Learning Goals
                                                        <i className="fa fa-angle-right pull-right icons" />
                                                    </li>
                                                </Link>
                                                <Link to={"/academics/academic-registry"}>
                                                    <li>
                                                        Academic Registry
                                                        <i className="fa fa-angle-right pull-right icons" />
                                                    </li>
                                                </Link>
                                                <Link to={"/academics/academic-calender"}>
                                                    <li>
                                                        Academic Calendar
                                                        <i className="fa fa-angle-right pull-right icons" />
                                                    </li>
                                                </Link>
                                                {/*<Link to={"/librarian-office"}>*/}
                                                {/*    <li>*/}
                                                {/*        Library*/}
                                                {/*        <i className="fa fa-angle-right pull-right icons" />*/}
                                                {/*    </li>*/}
                                                {/*</Link>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
}

export default AcademicRegistry;
