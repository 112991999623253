import React, {Component} from "react";

class Googlemap extends Component {
    render() {
        return <iframe width="100%" height={this.props.height} style={{ border:"0" }} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=phase%201,%20No%20306,%20Sharada,%20Kano%20700234,%20%20Kano,%20Nigeria+(Baba%20Ahmed%20University%20Kano)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps vehicle tracker</a></iframe>
            ;
    }
}

export default Googlemap;
