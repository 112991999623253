import React from "react";
import AppGuide from "../../images/faculties/square.jpg"
import { Link } from "react-router-dom";
import Banner from "../utils/banner/banner";
import { useState } from "react";
import Loader from "react-loader-spinner";
import { useEffect } from "react";
import axios from "axios";
import { serverLink } from "../../resources/url";
import './short-courses.css'
import { currencyConverter, formatDate } from "../../resources/constants";
import ShortCoursesImage from './short-courses.jpg'


const ShortCourses = () => {
    const [isLoading, setIsloading] = useState(false);
    const [courseList, setCourseList] = useState([]);

    const getData = async () => {
        await axios.get(`${serverLink}short_courses/courses/list`)
            .then((result) => {
                console.log(result.data)
                if (result.data.length > 0) {
                    setCourseList(result.data)
                }
            }).catch((e) => {
                console.log('NETWORK ERROR')
            })

    }
    useEffect(() => {
        getData();
    }, [])
    return isLoading === true ?
        <>
            <Loader />
        </>
        : <>
            <Banner image={AppGuide} title="Short Courses" caption="Advance your career with our diverse range of short courses." />


            <div className="container-fluid layout col1" style={{ marginTop: '20px' }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">


                        <div className="layout-blocks-ucws-accordion container-fluid roundable block accordion border">
                            <div className="row">
                                <div className="container">
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <h2>Application Guide</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="panel-group" id="accordion1585270170" role="tablist"
                                                aria-multiselectable="true">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading" role="tab" id="heading733025994">
                                                        <h4 className="panel-title">
                                                            <a className="collapsed" role="button" data-toggle="collapse"
                                                                href="#collapseApplicationBasic">General Information
                                                                <span aria-hidden="true"><span className="ucws-icon ucws-icon-chevron"></span></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseApplicationBasic" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading733025994" aria-expanded="true" style={{ height: '0px' }}>
                                                        <div className="panel-body">
                                                            <p>Short courses at Baba Ahmed University generally spans between 2 to 3 months, but depending on your course of choice, the duration can span upto 5 months. Our modules are well tailored to fit for career advancement of professionals and other alike. </p>
                                                            <p>To begin application, find your course of choice and submit an pplication. We strive to respond to your applications within 4 weeks. <br />
                                                                Typically, the fees for all courses are thesame with few exceptions. Read full details of course when you select a course to apply.</p>
                                                            <a onClick={() => {
                                                                document.getElementById("courses").scrollIntoView()
                                                            }} className={"btn btn-primary"}>Find a Course</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="panel panel-default">
                                                    <div className="panel-heading " role="tab" id="heading614380467">
                                                        <h4 className="panel-title">
                                                            <a className="collapsed" role="button" data-toggle="collapse"
                                                                href="#collapseApplicationProcess" aria-expanded="true"
                                                                aria-controls="collapseApplicationProcess"
                                                                data-ucws-analytics-toggle=""
                                                                data-ucws-analytics-category="Accordion"
                                                                data-ucws-analytics-label="Studied outside Alberta"
                                                                data-ucws-analytics-off-class="collapsed">Application Process <span aria-hidden="true"><span className="ucws-icon ucws-icon-chevron"></span></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseApplicationProcess" className="panel-collapse collapse " role="tabpanel" aria-labelledby="heading614380467">
                                                        <div className="panel-body">
                                                            <h3 id={"choosing_your_course"}>Choosing your course</h3>
                                                            <p>In choosing your course, read the course page carefully to make sure that you meet the entry requirements, as admissions to Baze are highly competitive, and that you are familiar with the structure and content of the course (for transfer students). The course pages will show whether your preferred course is currently accepting applications for your selected entry.</p>

                                                            <h3>Studying more than one course</h3>
                                                            <p >Our short courses are flexible and time considerate. You can apply for multiple courses at thesame time.</p>

                                                            <h3 id={"completing_your_application"}>Completing your application</h3>
                                                            <h4>Data protection and nominated third parties</h4>
                                                            <p>We will handle your application in accordance with the Nigeria Data Protection Act of 1998 and the declaration that you are required to sign before submitting your application will give you information on any third parties with whom we may need to share your data as part of the processing and assessment of your application. In your application you may nominate a third party, such as a relative, for the purposes of handling your application. We will not discuss your application with anyone else unless you nominate a representative in this section. Anyone you appoint will be able to request information and give us instructions relating to your application.</p>


                                                            <h4 id={"submitting_your_application"}>Submitting your application</h4>
                                                            <p>You would be required to create an account with a valid email which you ca use for present and subsequent applicatios. <br />When you submit your application you will be required to sign a declaration confirming that all information provided is - to the best of your knowledge - true, accurate, current and complete, and that you will promptly notify the University should any information change. You will also confirm that the information contained in your application, including all supporting work, is entirely your own original work, except where clearly indicated, and does not contain any plagiarised elements.</p>

                                                            <h4 id={"after_submitting_your_application"}>After submitting your application</h4>
                                                            <p>As soon as you submit your application you will be sent an automated confirmation via e-mail. If you do not receive this, please log in to your application to check that you have successfully submitted it and can no longer edit it. This email only confirms that your application was submitted.</p>

                                                            <h4>Assessment of your application</h4>
                                                            <p>You will receive an email notification from the once your application has been sent to the department for assessment. Usually this notification is sent within a few working days. Whilst the department will start to assess your application once you have completed and submitted the application form, you may be requested to submit additional documents for your application to be fully assessed and a final decision to be made. You will be notified of the outcome of your application by the department, usually around two to four weeks from your submission date.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="layout-blocks-ucws-accordion container-fluid roundable block accordion border">
                            <div className="row">
                                <div className="container">
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <h2>Find a course</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {
                                                courseList.length > 0 ?
                                                    <>
                                                        {
                                                            courseList.map((x, y) => {
                                                                return (
                                                                    <div className="col-md-4" key={y}>
                                                                        <div className="card">
                                                                            <div className="card-body" style={{ marginBottom: '-30px' }}>
                                                                                <img 
                                                                                src={ x.ImagePath === undefined ?  `${serverLink}public/uploads/course_uploads/${x.ImagePath}` : ShortCoursesImage} 
                                                                                style={{ width: '100%', height: '200px' }} />
                                                                                <div style={{ margin: '10px' }}>
                                                                                    <div className="col-md-12 cardDetails">
                                                                                        <a href="#"><h4>{x.CourseName}</h4></a>
                                                                                        <p>
                                                                                            <strong>Duration</strong>: {x.Duration} {x.DurationType}<br />
                                                                                            <strong>Starts</strong>: {formatDate(x.Start)}<br />
                                                                                            <strong>Fee</strong>: {currencyConverter(x.Fee)}
                                                                                        </p>
                                                                                        <Link to={`/academics/short-courses/login`} onClick={() => {
                                                                                            window.sessionStorage.setItem("courseCode", x.CourseCode)
                                                                                        }} className={"btn btn-primary"}>Apply</Link>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                    <div>
                                                        <h4>No Course</h4>
                                                    </div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="layout-blocks-ucws-info-shim container-fluid roundable block info-shim" id="courses">
                            <div className="row">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="more-info-line">
                                                <div className="more-info-icon">
                                                    <span className="icon icomoon ucws-info-shim-info light-grey"
                                                        aria-hidden="true"></span> Did you know Baba Ahmed University Admission system is always open?
                                                    <a href="https://portal.bazeuniversity.edu.ng/admission"
                                                        target="_blank" rel="noopener"> Apply here</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </>
}

export default ShortCourses;