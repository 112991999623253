
const noimage = require("../images/noimage.png")
export const ResearchersData = [

    // {
    //     name: "Dr. Rislan A. Kanya",
    //     rank: "Co-Founder, Artificial Intelligence and Blockchain Technology Centre",
    //     area: "Data Analytics",
    //     highest: "PhD",
    //     profile: "https://bazeuniversity.edu.ng/academics/staff/rislan-abdulazeez-kanya"
    // },

    {
        name: "Mohammed Ibrahim Jega",
        rank: "Co-Founder, Artificial Intelligence and Blockchain Technology Centre",
        area: "Education & Information Systems",
        highest: "Masters",
        profile: ""

    },

    // {
    //     name: "Dr. Emeka Ene",
    //     rank: "Senior Research Fellow",
    //     area: "Entrepreneurship",
    //     highest: "PhD",
    //     profile: "https://bazeuniversity.edu.ng/academics/staff/emeka-emmanuel-ene"
    // },

    // {
    //     name: "Aisha Guba",
    //     rank: "Research Fellow",
    //     area: "Knowledge Management",
    //     highest: "Masters",
    //     profile: "https://bazeuniversity.edu.ng/academics/staff/lawan-guba-aisha"
    // },

    // {
    //     name: "Usman Labaran",
    //     rank: "Research Fellow",
    //     area: "Data Analytics",
    //     highest: "Masters",
    //     profile: "https://bazeuniversity.edu.ng/academics/staff/usman-yunusa-labaran"
    // },

    {
        name: "Chima Achu",
        rank: "Public Administration",
        area: "Public Administration",
        highest: "Masters",
        image: noimage,
        profile: ""
    },


    {
        name: "Calistos Igwilo",
        rank: "Lecturer",
        area: "Artificial Intelligence",
        highest: "Masters",
        image: noimage,
        profile: ""
    },

    // {
    //     name: "Amuwa Ajigboye",
    //     rank: "Research Fellow",
    //     area: "Data Analytics",
    //     highest: "BSc",
    //     image: noimage,
    //     profile: ""
    // },


]