import React, { useState } from "react";
import Banner from "../../utils/banner/banner";
import Square from "../../../images/faculties/bau_banner.png";
import "./course-finder.css";
import { setCourseListDetails, setCoursePageDetails } from "../../../actions/setactiondetailsection";
import { connect } from "react-redux";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Loader from "react-loader-spinner";

function CourseFinder(props) {
    const location = useLocation()
    const param = window.location.href.split("=")[1];

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("Loading result. Please wait!");
    const [courses, setCourses] = useState(props.courseList);
    const [coursesListData] = useState(props.courseList);

    function getCourse() {
        setLoading(true);
        axios.get(`${serverLink}course/list/view/all`)
            .then(result => {
                if (param !== undefined) {
                    setCourses(result.data.filter(x => x.DegreeInView.replace(".", '').toLowerCase().includes(param)))
                } else {
                    setCourses(result.data);
                }

                props.setOnCourseListDetails(result.data);
                setLoading(false);
            })
            .catch(error => {
                console.log("SERVER NETWORK ERROR");
            })

        axios.get(`${serverLink}course/content/all`)
            .then(result => {
                if (loading) {
                    props.setOnCourseDataDetails(result.data);
                }
            })
            .catch(error => {
                console.log("SERVER NETWORK ERROR");
            })
    }
    useEffect(() => {
        getCourse();

    }, [location.pathname, param])

    const facultyFilterChange = (e) => {
        const faculty_code = e.target.value;
        if (faculty_code === 'All') {
            setCourses(coursesListData);
        }
        else {
            const course_list = coursesListData.filter(item => item.FacultyCode === faculty_code);
            setCourses(course_list);
            if (course_list.length === 0) {
                setMessage("Record not found for the selected faculty")
            }
        }
    }

    const keywordFilterChange = (e) => {
        const keyword = e.target.value;

        const course_list = coursesListData.filter(item => item.Description.toLowerCase().includes(keyword.toLowerCase()));
        setCourses(course_list);
        if (course_list.length === 0) {
            setMessage("Record not found for the searched keyword(s)")
        }

    }

    return loading ? (
        <Loader
            type="ThreeDots"
            color="#63656A"
            height={200}
            width={200}
            className="text-center"
        />) : <>
        <Banner image={Square} title="Course Finder" caption="" />

        <div className="container-fluid layout col1" style={{ margin: "20px" }}>
            <div className="row">
                <div className="layout-col col1 layout-12 col-sm-12">
                    <div id="ucws-page-filter-tag-97944"
                        className="layout-blocks-ucws-page-filter container-fluid roundable block page-filter views-block keyword-filters dropdown-filters pagination-toggle">
                        <div className="row">
                            <div className="container">
                                <div className="row divider">
                                    <div className="col-sm-12">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row view-output">
                                    <div className="col-sm-12">
                                        <div className="views-element-container">
                                            <div className="view-output-form-results">
                                                <div className="row filters">
                                                    <div className="col-sm-12">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="">Faculties</label>
                                                                <select id="FacultySelect" className="form-control" defaultValue={"All"} onChange={facultyFilterChange}>
                                                                    <option value={"All"}>- Any -</option>
                                                                    {
                                                                        props.facultyList.length > 0 && props.facultyList.map((faculty, index) => {
                                                                            return <option key={index} value={faculty.FacultyCode}>{faculty.FacultyName}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <label htmlFor="">Keywords</label>
                                                            <input type="text" className="form-control" placeholder="Accounting, Computer Science" onChange={keywordFilterChange} />
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="row divider">
                                                    <div className="col-sm-12">
                                                        <hr />
                                                    </div>
                                                </div>

                                                <div className="row results">
                                                    <div className="col-sm-12">
                                                        <ul className="results">
                                                            {
                                                                courses.length > 0 ?
                                                                    courses.map((course, index) => {
                                                                        const slug = course.Slug.includes("/") ? course.Slug.replace("/", "-") : course.Slug
                                                                        const is_undergraduate = course.DurationType.includes('Year') ? 'undergraduate' : 'postgraduate';
                                                                        const is_ug = course.DurationType.includes('Year') ? 'Undergraduate' : 'Postgraduate';

                                                                        return <li key={index} className="result">
                                                                            <Link
                                                                                to={`/admission/${is_undergraduate}/${slug}`}>
                                                                                {course.Description} <br />
                                                                                <small>{is_ug}</small>
                                                                            </Link>
                                                                        </li>
                                                                    }) : <p className="col-md-12 alert alert-danger">{message}</p>
                                                            }

                                                        </ul>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </>
}

const mapStateToProps = (state) => {
    return {
        facultyList: state.BazeFacultyListDetails,
        courseList: state.BazeCourseListDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnCourseListDetails: (p) => {
            dispatch(setCourseListDetails(p));
        },
        setOnCourseDataDetails: (p) => {
            dispatch(setCoursePageDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseFinder);
