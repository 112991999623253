import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import OurCampusImg2 from "../../../images/general/bau_banner.png";
import Googlemap from "../../utils/googlemap";
import Address from "./address";

class ContactUs extends Component {
    render() {
        return <>
            <Banner image={OurCampusImg2} title="Contact Us" caption="" />

            <Address />

            <div className="container-fluid layout col2-50-50">
                <Googlemap height={600} />
            </div>

        </>;
    }
}

export default ContactUs;
