import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import HRLine from "../../utils/hrline";
import VCImage from '../../../images/vc_2.jpeg'
import { isMobile } from "react-device-detect";

class ViceChancelloroffice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            members: []
        }
    }


    render() {
        return <>
            <Banner image={require("../images/vc.png")} title="Office Of The Vice Chancellor" caption="" disableTitle />

            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <span>
                                                <h3>Prof. Kathleen Ebelechukwu Okafor, SAN</h3>
                                                <h5><strong>Acting Vice-Chancellor</strong></h5>
                                                <p style={{ marginTop: '20px' }} >
                                                    {/* Professor Tahir Mamman, OON, SAN, the second Vice-Chancellor of Baba Ahmed University brings in a diverse, all-round wealth of experience in management of tertiary institutions and general administration spanning well over three decades. He was the immediate past Director-General of the prestigious Nigerian Law School. He was in that post for 8 years. His Tenure in the Law School recorded unparalleled massive development in the programmes of the school, infrastructure, staff development, international linkages, and staff and students welfare and wellbeing. He was the pioneer Head and Deputy Director-General of the Kano Campus of the Nigerian Law School. He cut his academic and administrative teeth in the University of Maiduguri where he had become a Head of Department, Acting Dean of Law at various times, and substantive Dean of Students Affairs, catering for the welfare and social development of over 30,000 students at that time. He was for 3 years a member of the Board of International Association of Law Schools, based in Washington. He belongs to a wide range of international and local associations. He has traversed virtually all parts of the world. Professor Mamman is happily married with 9 children, with a mix of boys and girls, and is also a grandfather. */}

                                                    Prof. Kathleen E. Okafor, was born in the Eastern part of Nigeria into the noble
                                                    family of Dr. George Akabogu. She hails from Nnewi, Anambra State. Education
                                                    She had her Secondary at Queens School, Enugu, she proceeded to University of
                                                    Nigeria, Nsukka for her LL. B degree program in 1974. <br />
                                                </p>
                                                <p>
                                                    Prof. Kathleen E. Okafor, SAN attended the Nigerian Law School Lagos and was
                                                    called to the Nigerian Bar in 1979. She immediately went to University College
                                                    London, in 1981 to complete her LL.M program. She got her PHD from the
                                                    Unuversity of Lagos, in year 2000. Prof. Kathleen E. Okafor, SAN has major
                                                    executive education from the International Financial Law course by Euromoney
                                                    held at Oxford, Making Corporate Boards more effective by Harvard Business
                                                    School, International Company Secretary’s Course, Regent College London,
                                                    Advanced Management Course, Kellogg’s Management Institute.<br />
                                                </p>
                                                <p>
                                                    She was briefly in the Chambers of Lateef Adegbite & Co and also as a 2nd Counsel
                                                    at Shell Petroleum; she worked as the Legal Adviser and Company Secretary of the
                                                    Nigerian Security Printing and Minting Plc, for over 29 years.<br />
                                                </p>
                                                <p>
                                                    She is a member of numerous professional bodies, organisations and institutes, like
                                                    the Nigerian Bar Association, International Bar Association, Nigerian Institute of
                                                    International Affairs, fellow of the Chartered Institute of Arbitrators, fellow
                                                    Institute of Construction Industry Arbitrators, Nigerian Institute of Management,
                                                    International Women Lawyers Association, Body of Senior Advocates of Nigeria.
                                                    Prof. Kathleen E. Okafor, SAN was once the regional secretary African Banknotes
                                                    and Security Printers Association, 1992 – 1999, she was a consultant to NDIC and
                                                    NNPC on legal and economic matters, she was Dean faculty of Law Baze
                                                    University for years, she was also the chairperson, faculty of law welfare
                                                    committee, deputy editor Miyetti research and publication, she has written over 7
                                                    law text books and over 80 articles published in local and international journals and
                                                    newspapers, she has happily engaged herself in many pro bono and humanitarian
                                                    exercise.<br />
                                                </p>
                                                <p>
                                                    Prof. Kathleen Okafor, SAN is a renowned international scholar and she is being
                                                    referenced everyday by legal scholars around the world. She has travelled widely
                                                    across the world for several conferences and courses. She is a devout Catholic and
                                                    a Lady of the Knight of St. Mulumba. She is happily married with children and
                                                    grandchildren.
                                                </p>
                                            </span>
                                        </div>
                                    </div>

                                    <HRLine />

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="table-responsive">
                                                <img src={VCImage} width={isMobile ? "40%" : "100%"} style={{ borderRadius: '7px', }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-business-and-finance-cockade-line"
                                                        aria-hidden="true" />
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="media media-body">
                                                        <h3>Office of the Vice Chancellor</h3>
                                                        <p>
                                                            The Office of the Vice-Chancellor occupies the peak of the pyramid in the hierarchical administrative and academic structure of the University. The Office is organized in a way that it supervises all the functional areas of the institution in academic and non- academic spheres of activities towards achieving the vision and goals of the university.
                                                        </p>
                                                        <p>To assist him discharge these responsibilities and achieve institutional goals, the office of the Vice Chancellor has designed some action points and procedures as follows </p>
                                                        <ul style={{ listStyle: "initial" }}>
                                                            <li>Online tracking of Memos and other correspondences attended to by the Vice Chancellor</li>
                                                            <li>An organized filing system to safeguard documents and ease location of documents</li>
                                                            <li>People friendly approach to all visitors coming to the office for business</li>
                                                            <li>Appointments bookings and scheduling</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <HRLine />

                                    <div className="row header">
                                        <div className="col-sm-12 text-justify">
                                            <h3>Our Responsibilities:</h3>
                                            <p>In line with the vision and Mission statement of the University, the Vice- Chancellor who is the Chief Executive officer of the University and responsible for the day– to- day running of the Institution is carefully selected to discharge the following summarized responsibilities: </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="more-info-items two-items">
                                                <div className="media more-info-item">
                                                    <div className="media-body">
                                                        <p>
                                                            <ul style={{ listStyle: "initial" }}>
                                                                <li>Serving on council committees to provide valuable input in policy</li>
                                                                <li>Provides leadership to the senate for the overall development and management of the academic affairs of the university.</li>
                                                                <li>Managing students' academic affairs, students' discipline and students' issues generally.</li>
                                                                <li>Coordination of academic programs and research budgets and estimates to provide for the overall financial needs of the institution.</li>
                                                                <li>Attend to requirements of external regulatory bodies in representing approved programs and introduction of new ones.</li>
                                                                <li>Maintaining relationships with other development of academic institutions, organizations, and the broader community.</li>
                                                                <li>Attend to critical events of the university such as graduations, Matriculations, Orientations for new students, and other Public events organized by the University</li>
                                                                <li>Ensuring university standards are maintained and that academic freedom is protected.</li>
                                                                <li>The Vice-chancellor Chairs the Senate and presides over meetings of congregation and in the absence of the Chancellor, the Vice-Chancellor presides over convocation for the conferment of degrees, and other academic titles and distinctions of the university.</li>

                                                            </ul>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="media more-info-item">
                                                    <p>
                                                        <ul style={{ listStyle: "initial" }}>
                                                            <li>Maintaining relationships with other development of academic institutions, organizations, and the broader community.</li>
                                                            <li>providing leadership and direction to the university through policy formulation, development and implementation to ensure accountability and responsibility for efficient and effective compliance with the university law, statutes, rules, regulations, policies and procedures</li>
                                                            <li>The Vice-Chancellor is ensures that the provisions of the law, the statutes and regulations of the land and the university are observed by the university.</li>
                                                            <li>Allocating resources fairly</li>
                                                            <li>Secures grants within the university.</li>
                                                            <li>Supervising staff and performance issues.</li>
                                                            <li>Handling a range of administrative duties, including preparing reports to the Council and Board of Trustees.</li>
                                                            <li>Building networks with other academics and institutions such as the Committee of Vice Chancellors of Nigerian Universities.</li>
                                                            <li>undertake any other duties that may be assigned or referred to him by the Council or Board of Trustees
                                                            </li>


                                                        </ul>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>;
    }
}

export default ViceChancelloroffice;
