import React from "react";
import Banner from "../utils/banner/banner";
import 'react-data-table-component-extensions/dist/index.css';
import { serverLink } from "../../resources/url";
import axios from "axios";
import { connect } from "react-redux";
import { setStaffProfileDetails } from '../../actions/setactiondetailsection'
import { useState } from "react";
import { useEffect } from "react";
import {Link, NavLink} from "react-router-dom";
import './search.css'
import Loader from "react-loader-spinner";
import banner from '../search/img/search_banner.jpg'

import 'react-data-table-component-extensions/dist/index.css';
import {decryptData, toTitleCase} from "../../resources/constants";

const Search = (props) => {
    const [isLoading, setIsloading] = useState(true)
    const [courseList, setCourseList] = useState([]);
    const [staffList, setStaffList] = useState([]);
    const [publicationList, setPublicationList] = useState([])
    const [newsList, setNewsList] = useState([])
    const [eventList, setEventList] = useState([]);
    const [searchField, setSearchField] = useState("");
    const [locData, setLocData] = useState({
        country_code: "",
        country_name: "",
        city: "",
        postal: "",
        latitude: "",
        longitude: "",
        IPv4: "",
        state: "",
    })

    const url = window.location.href.split("/");
    const  slug  = url[url.length -1];
    if (slug === "") window.location.href = '/';


    const getRecords = async () => {
        await axios.get(`${serverLink}general/website/search/${slug}`)
            .then((result) => {
                const data = result.data;
                    setCourseList(data.course)
                    setStaffList(data.staff)
                    setPublicationList(data.publication)
                    setNewsList(data.news)
                    setEventList(data.event)
                setIsloading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const onSubmitSearch = (e) => {
        e.preventDefault();

        if (searchField.trim().toString() === ""){
            return false;
        }
        window.location.href = `/search/${searchField}`;

    }

    const onSearchChange = (e) => {
       setSearchField(e.target.value)
    }


    useEffect(()=>{
        getLocationData()
        getRecords();
    }, [""])

    const getLocationData = async () => {
        await axios.get('https://geolocation-db.com/json/').then((result)=> {
            setLocData(result.data);
        }).catch((e)=>{
            console.log(e)
        })
    }


    const updateStaffPublication = async (type, redirect_link, PubID) => {
        let sendData = {
            EntryID: PubID,
            userIP: locData.IPv4,
            redirect: redirect_link,
            lat: locData.latitude,
            lng: locData.longitude,
        }

        if (type === "view"){
            await axios.patch(`${serverLink}resources/publication/update-view-count`, sendData).then(data => {
                if (data.data.message === "success"){
                    window.open(redirect_link, '_blank');
                }
            }).catch(err => {console.log("Error Updating Count")});
        }else {
            await axios.patch(`${serverLink}resources/publication/update-download-count`, sendData).then(data => {
                if (data.data.message === "success"){
                    window.open(`https://portal.bazeuniversity.edu.ng/staff/${redirect_link}`, '_blank');
                }
            }).catch(err => {console.log("Error Updating Count")});
        }
    }


    return isLoading === true ?
        <>
            <Loader
                type="ThreeDots"
                color="#63656A"
                height={200}
                width={200}
                className="text-center"
            />
        </> : <>
            <Banner image={banner} title="Search Result" caption="" />

            <div className="container-fluid layout col1" >
                <div className="row">
                    <div className="col-sm-12">
                        <div className="layout-blocks-ucws-checklist container-fluid roundable block checklist">
                            <div className="row">

                                <div className="col-lg-12">
                                    <div className="layout-col col1 layout-12 col-md-12">
                                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                            {/*<div style={{ padding: '8px', borderRadius: '5px' }}>*/}
                                            {/*    <input name="search" placeholder="Search Course, Staff, Publication, News and Event " className="textBox" onChange={onSearchChange} onClick={onSubmitSearch} />*/}
                                            {/*    /!*<span><button className="btn btn-primary mt-1">Search</button></span>*!/*/}
                                            {/*</div>*/}
                                            <div className="row">
                                                <div className="col-md-9" style={{paddingTop: '0px'}}>
                                                    {courseList.length > 0 || staffList.length > 0 || publicationList.length > 0 || newsList.length > 0 || eventList.length > 0 ?
                                                        <>
                                                            {
                                                                courseList.length > 0 &&
                                                                <div className=" row m-0 search-container"
                                                                     style={{borderTop: '1px solid black'}}>
                                                                    <span
                                                                        className="bg-primary pull-right">Courses</span>
                                                                    {
                                                                        courseList.length > 0 && courseList.map((item, index) => {
                                                                            return (
                                                                                <Link key={index}
                                                                                      to={`/admission/undergraduate/${item.Slug}`}>
                                                                                    <div
                                                                                        className="col-md-12 search-card">
                                                                                        <h4 className="title">{item.Description}</h4>
                                                                                        <i>{item.Faculty}</i><br/>
                                                                                        <small
                                                                                            className="links">{`https://bazeuniversity.edu.ng/admission/undergraduate/${item.Slug}`}</small>
                                                                                    </div>
                                                                                </Link>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                staffList.length > 0 &&
                                                                <div className=" row m-0 search-container"
                                                                     style={{borderTop: '1px solid black'}}>
                                                                    <span className="bg-primary pull-right">Staff</span>
                                                                    {
                                                                        staffList.length > 0 && staffList.map((item, index) => {
                                                                            return (
                                                                                <Link key={index}
                                                                                      to={`/academics/staff/${item.Slug}`}>
                                                                                    <div
                                                                                        className="col-md-12 search-card">
                                                                                        <h4 className="title text-capitalize">{item.staff_name} ({item.designation})</h4>
                                                                                        <i>Department: {item.Department}</i><br/>
                                                                                        <small
                                                                                            className="links">{`/academics/staff/${item.Slug}`}</small>
                                                                                    </div>
                                                                                </Link>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                publicationList.length > 0 &&
                                                                <div className=" row m-0 search-container" style={{borderTop: '1px solid black'}}>
                                                                    <span
                                                                        className="bg-primary pull-right">Publication</span>
                                                                    {
                                                                        publicationList.length > 0 && publicationList.map((item, index) => {
                                                                            return (
                                                                                <div key={index}
                                                                                      className="link"
                                                                                      onClick={()=> {updateStaffPublication("view", decryptData(item.OnlineURL), item.EntryID);}}
                                                                                >
                                                                                    <div
                                                                                        className="col-md-12 search-card">
                                                                                        <h4 className="title text-capitalize">{item.WorkTitle}</h4>
                                                                                        <i>{item.PaperTitle}</i>
                                                                                        <p>Authors: {!item.Authors ?  item.Author : item.Authors}</p>
                                                                                        <small
                                                                                            className="links">{ decryptData(item.OnlineURL)} {!item.UploadFile ? "" : <span className="fa fa-file-pdf-o" onClick={()=> {updateStaffPublication("download", item.UploadFile, item.EntryID);}} />}</small>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                newsList.length > 0 &&
                                                                <div className=" row m-0 search-container" style={{borderTop: '1px solid black'}}>
                                                                    <span className="bg-primary pull-right">News</span>
                                                                    {
                                                                        newsList.length > 0 && newsList.map((item, index) => {
                                                                            return (
                                                                                <Link key={index}
                                                                                      to={`/news/${item.Slug}`}>
                                                                                    <div
                                                                                        className="col-md-12 search-card">
                                                                                        <h4 className="title text-capitalize">{item.Title}</h4>
                                                                                        {/*<p dangerouslySetInnerHTML={{__html: item.Description}}></p>*/}
                                                                                        <small
                                                                                            className="links">{`/news/${item.Slug}`}</small>
                                                                                    </div>
                                                                                </Link>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                eventList.length > 0 &&
                                                                <div className=" row m-0 search-container" style={{borderTop: '1px solid black'}}>
                                                                    <span className="bg-primary pull-right">Event</span>
                                                                    {
                                                                        eventList.length > 0 && eventList.map((item, index) => {
                                                                            return (
                                                                                <Link key={index}
                                                                                      to={`/event/${item.Slug}`}>
                                                                                    <div
                                                                                        className="col-md-12 search-card">
                                                                                        <h4 className="title text-capitalize">{item.Title}</h4>
                                                                                        {/*<p dangerouslySetInnerHTML={{__html: item.Description}}></p>*/}
                                                                                        <small
                                                                                            className="links">{`/event/${item.Slug}`}</small>
                                                                                    </div>
                                                                                </Link>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </>
                                                        :

                                                        <div className="alert alert-heading text-center" style={{marginTop: '30px'}}>
                                                          <h3><span className="fa fa-search"/>  No Record Found</h3>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="col-md-3">

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                        <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                            <div className="row">
                                <div className="">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <hr className="solid light-grey" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="container-fluid layout col1">
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-info-shim container-fluid roundable block info-shim">
                            <div className="row">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="more-info-line">
                                                <div className="more-info-icon">
                                                    <span className="icon icomoon ucws-info-shim-info light-grey"
                                                          aria-hidden="true"></span> Did you know Baba Ahmed University Admission system is always open?
                                                    <a href="https://portal.bazeuniversity.edu.ng/admission"
                                                       target="_blank" rel="noopener"> Apply here</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
}
const mapStateToProps = (state) => {
    return {
        staffList: state.BazeStaffListDetails,
        facultyList: state.BazeFacultyListDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnStaffProfileDetails: (p) => {
            dispatch(setStaffProfileDetails(p));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
