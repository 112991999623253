import React, {Component} from "react";
import LeadershipComponent from "./leadershipcomponent";

class LeadershipBoard extends Component {

    render() {
        return <LeadershipComponent title="Board of Trustees" caption="Leading Baba Ahmed University toward its strategic goals" category="Board" />
    }
}

export default LeadershipBoard;

