import React, { useEffect, useState } from "react";
import './faculty.css'
import { connect } from "react-redux";
import BazeIntroVideo from "../../../images/video/baze_university.mp4";

import {
    setAlumniFeedbackDetails,
    setEventsDetails,
    setFacultyDetails,
    setFacultyListDetails,
    setNewsDetails,
    setNewsPageDetails, setStaffListDetails
} from "../../../actions/setactiondetailsection";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import NewsSection from "../../news/newssection";
import EventSection from "../../events/eventsection/eventsection";
import * as DOMPurify from 'dompurify';

function Faculty(props) {
    let _isMounted = false
    const [change, setChange] = useState(0)
    const location = useLocation();
    const navigate = useNavigate();
    const { slug } = useParams();
    const [alumniSlider, setAlumniSlider] = useState([]);
    //props.alumniFeedback.length > 0 ? props.alumniFeedback[0] : 
    const [alumniSliderSelected, setalumniSliderSelected] = useState(0);
    const [alumniFeedback, setalumniFeedback] = useState([]);
    const [FacultyEvents, setFacultyEvents] = useState([]);
    const [FacultyNews, setFacultyNews] = useState([])

    if (slug === "") navigate("/");

    const intro_video =
        slug === "faculty-of-medicine" ?
            "https://drive.google.com/file/d/1pEpiRQh0QKEJrRNQ7sNpVbSgCkW0_O9r/preview" :
            slug === "management-and-social-sciences" ?
                "" :
                slug === "environmental-sciences" ?
                    "https://drive.google.com/file/d/11ynQaLMhSPsykfycFwmZDIjVaZECEVMz/preview" :
                    slug === "engineering" ?
                        "https://drive.google.com/file/d/1Ce4XQTRdvjTeXKCtDM_wVrjHweYCxZrZ/preview" :
                        slug === "computer-science-and-information-technology" ?
                            "" :
                            slug === "general-studies" ?
                                "https://drive.google.com/file/d/1-6Boj41vIwkBEeoTBoJ9QHkU5uuazljy/preview" :
                                slug === "postgraduate-school" ?
                                    "https://drive.google.com/file/d/1H-BjjjSBUDUoUrM2NINyvVMPClBdrHjb/preview" :
                                    slug === "law" ?
                                        "https://drive.google.com/file/d/1DnDxZPuL0GA4CpqE1YW1LsZ6siqlJbAm/preview"
                                        : BazeIntroVideo
    console.log(props.facultyData)
    let facultyRecord = props.facultyList.filter((item) => item.Slug === slug);
    const [facultyDetail, setFacultyDetail] = useState(
        facultyRecord.length > 0
            ? props.facultyData.filter(
                (item) => item.FacultyCode === facultyRecord[0].FacultyCode
            )
            : []
    );


    const [facultyStaff, setFacultyStaff] = useState(props.staffList.length > 0 ?
        props.staffList.filter(item => item.FacultyCode === facultyRecord[0].FacultyCode) : []);

    useEffect(() => {
        const slug_record = location.pathname.split("/")[2];
        facultyRecord.push(
            props.facultyList.filter((item) => item.Slug === slug_record)
        );
        setFacultyDetail(
            facultyRecord.length > 0
                ? props.facultyData.filter(
                    (item) => item.FacultyCode === facultyRecord[0].FacultyCode
                )
                : []
        );
    }, [location.pathname]);



    useEffect(() => {
        if (alumniSliderSelected < alumniFeedback.length - 1) {
            setalumniSliderSelected(alumniSliderSelected + 1)
            setAlumniSlider(alumniFeedback[alumniSliderSelected + 1])
        }
        else {
            setalumniSliderSelected(0)
            setAlumniSlider(alumniFeedback[0])
        }

        setTimeout(() => {
            setChange(change => change + 1)
        }, 5000);
    }, [change])

    useEffect(() => {
        getData();
    }, [location.pathname])

    const getData = async () => {
        await axios.get(`${serverLink}student/alumni/feedback`)
            .then(data => {
                if (data.data.length > 0) {
                    let dt = data.data.filter(x => x.StudentID.split("/")[2] === facultyRecord[0].FacultyCode)
                    if(dt.length > 0) {
                        setalumniFeedback(dt.map(obj => {
                            return { ...obj, InsertedDate: new Date(obj.InsertedDate) };
                        }).sort(
                            (objA, objB) => Number(objB.InsertedDate) - Number(objA.InsertedDate),
                        ))
                    }
                }
                _isMounted && props.setOnAlumniFeedbackDetails(data.data);
            })
            .catch(error => {
                console.log("ERROR FETCHING THE HOMEPAGE NEWS")
            });

    }

    const getFacultyData = async () => {
        await axios
            .get(`${serverLink}faculty/list/academics`)
            .then((response) => {
                const data = response.data;

                const faculty_data = data.filter((item) => item.Slug === slug);
                props.setOnFacultyListDetails(response.data);

                if (data.filter((item) => item.Slug === slug).length < 1) {
                    window.location.href = "/";
                } else {
                    facultyRecord.push(faculty_data);
                }
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}faculty/details`)
            .then((response) => {
                props.setOnFacultyDataDetails(response.data);
                setFacultyDetail(
                    response.data.filter(
                        (item) => item.FacultyCode === facultyRecord[0].FacultyCode
                    )
                );
                if (facultyDetail.length < 1) navigate("/");
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios.get(`${serverLink}news/list/active`)
            .then(result => {
                props.setOnNewsDetails(result.data);
            })
            .catch(e => {
                console.log('NEWS FETCH NETWORK ERROR')
            });

        await axios.get(`${serverLink}event/list/active`)
            .then(result => {
                props.setOnEventDetails(result.data);
            })
            .catch(e => {
                console.log('NEWS FETCH NETWORK ERROR')
            })
    };

    useEffect(() => {
        getFacultyData();
    }, []);

    const handleSearch = (e) => {
        let current_data = props.staffList.filter(item => item.FacultyCode === facultyRecord[0].FacultyCode && item.staff_name.toLowerCase().includes(e.target.value.toLowerCase()))
        setFacultyStaff(current_data)
    }


    useEffect(() => {
        fetchEvents();
    }, [])

    const fetchEvents = async () => {
        await axios.get(`${serverLink}event/list/active`)
            .then(result => {
                let dt = result.data.filter(x => x.FacultyCode === facultyRecord[0].FacultyCode).length > 0 ?
                    result.data.filter(x => x.FacultyCode === facultyRecord[0].FacultyCode) : result.data.slice(0, 5)
                setFacultyEvents(dt)
            })
            .catch(e => {
                console.log('NEWS FETCH NETWORK ERROR')
            })


        await axios.get(`${serverLink}news/list/active`)
            .then(result => {
                let dt = result.data.filter(x => x.FacultyCode === facultyRecord[0].FacultyCode).length > 0 ?
                    result.data.filter(x => x.FacultyCode === facultyRecord[0].FacultyCode) : result.data.slice(0, 5)
                setFacultyNews(dt)
            })
            .catch(e => {
                console.log('NEWS FETCH NETWORK ERROR')
            })
    }


    return facultyDetail.length < 1 ?
        <Loader
            type="ThreeDots"
            color="#63656A"
            height={200}
            width={200}
            className="text-center"
        /> : (
            <>
                <div className="layout-blocks-ucws-sidekick-cta container-fluid block  sidekick-cta full-width full-bleed inline-bg-image bg-image " style={{ margin: "0px" }}>
                    <div className="row red-back hleft">
                        <div className="col-image">
                            <picture>
                                <img className="hgt" src={`${serverLink}public/uploads/faculty_uploads/${facultyDetail[0].FacultyImage}`}
                                    alt={facultyRecord[0].FacultyName} />
                            </picture>
                        </div>

                        {/* <div className="col-text">
                            <div className="cta-wrapper">
                                <div className="cta-content" style={{ backgroundColor: `${facultyDetail[0].FacultyColor}` }}>
                                    <h2 className="left">{facultyRecord[0].FacultyName}</h2>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {
                    facultyDetail.length > 0 &&
                    <>

                        <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                            <div className="row">
                                <div className="layout-col col1 layout-12 col-sm-12">
                                    <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                        <div className="row">
                                            <div className="container text-justify">
                                                <div className="row header">
                                                    <div className="col-sm-12">
                                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(facultyDetail[0].FacultyDetail) }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid roundable block">
                            <div className="row">
                                <div className="container">
                                    <div className="row divider link-section-divider">
                                        <div className="col-sm-12">
                                            <hr className="light-grey" />
                                        </div>
                                    </div>
                                    <div className="row news-items light-grey-accents" style={{ backgroundColor: '#f5f5f5', padding: '30px', borderTopLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                                        <div className="col-sm-12 col-md-12 news-item news-items__main">
                                            {/* <div className="row header">
                                        <div className="col-sm-12">
                                            <span style={{ fontWeight: "normal", fontStyle: "italic", textTransform: "capitalize", fontSize: "1.5rem" }}>Get to know our faculty</span>
                                        </div>
                                    </div> */}
                                            {/* <div className="row divider link-section-divider">
                                        <div className="col-sm-12">
                                            <hr className="light-grey" />
                                        </div>
                                    </div> */}
                                            <div className="row header">
                                                <div className="col-sm-12">
                                                    <h2 style={{ color: `${facultyDetail[0].FacultyColor}` }}><i className="fa fa-comment-o" /> Welcome Note</h2>

                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className={(facultyDetail[0].WelcomeNoteByDean === null || facultyDetail[0].WelcomeNoteByDean === undefined) ? 'col-md-12' : facultyDetail[0].WelcomeNoteByDean.toString().trim().length > 10 ? 'col-md-5' : 'col-md-12'}>
                                                    <div className="news-item" style={{ marginTop: "10px" }}>
                                                        <div className="item-info discover-section">
                                                            <div className="row">
                                                                <div className="col-xs-12 col-md-12">
                                                                    <div className="video-wrapper" style={{ verticalAlign: "center" }}>
                                                                        {/* <video controls className="embed-responsive-item">
                                                                            <source src={intro_video} width="580" height="450" type="video/mp4" />
                                                                        </video> */}
                                                                        <iframe src={intro_video} width="100%" height="300px" frameborder="0" border="0" cellspacing="0" sandbox
                                                                        ></iframe>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (facultyDetail[0].WelcomeNoteByDean === null || facultyDetail[0].WelcomeNoteByDean === undefined) || facultyDetail[0].WelcomeNoteByDean === "" ?
                                                        <div className="col-md-0">

                                                        </div>
                                                        : facultyDetail[0].WelcomeNoteByDean.toString().trim().length > 10 ?
                                                            <div className="col-md-7">
                                                                <div className="news-item" style={{ marginTop: "10px" }}>
                                                                    <div className="item-info discover-section">
                                                                        <div className="row">
                                                                            <div className="col-xs-12 col-md-12">
                                                                                <div className="container text-justify">
                                                                                    <div className="row header">
                                                                                        <div className="col-sm-12">
                                                                                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(facultyDetail[0].WelcomeNoteByDean) }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="col-md-0">

                                                            </div>
                                                }
                                                <hr />


                                                {facultyDetail[0].FacultyVissionAndMission === undefined || facultyDetail[0].FacultyVissionAndMission === null ?
                                                    <div>

                                                    </div>
                                                    :
                                                    facultyDetail[0].FacultyVissionAndMission.toString().trim().length > 0 ?
                                                        <div></div>
                                                        :
                                                        <div className="col-sm-12" style={{ marginTop: '20px' }} >
                                                            <div className="more-info-items two-items">
                                                                <div className="media more-info-item">
                                                                    <div className="media-body">
                                                                        <div className="container">
                                                                            <div className="row left" >
                                                                                <div className="col-sm-12 one-col block_quate text-justify">
                                                                                    <h2
                                                                                        style={{ color: `${facultyDetail[0].FacultyColor}` }}><i className="fa fa-eye-slash " /> Vision and Mission</h2>
                                                                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(facultyDetail[0].FacultyVissionAndMission) }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row divider link-section-divider">
                                        <div className="col-sm-12">
                                            <hr className="light-grey" />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </>
                }


                {
                    props.courseList.length > 0 &&
                    props.courseList.filter(item => item.FacultyCode === facultyRecord[0].FacultyCode).length > 0 &&
                    <div className="container-fluid layout col1">
                        <div className="row">
                            <div className="layout-col col1 layout-12 col-sm-12">
                                <div className="layout-blocks-ucws-page-filter container-fluid roundable block page-filter views-block keyword-filters dropdown-filters pagination-toggle">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row header">
                                                <div className="col-sm-12">
                                                    <h2 style={{ color: `${facultyDetail[0].FacultyColor}` }}><i className="fa fa-book" /> Our Courses</h2>

                                                </div>
                                            </div>
                                            <div className="row divider">
                                                <div className="col-sm-12">
                                                    <hr />
                                                </div>
                                            </div>
                                            <div className="row view-output">
                                                <div className="col-sm-12">
                                                    <div className="views-element-container">
                                                        <div className="view-output-form-results">
                                                            <div className="row results">
                                                                <div className="col-sm-12">
                                                                    <ul className="results">

                                                                        {
                                                                            props.courseList.filter(item => item.FacultyCode === facultyRecord[0].FacultyCode).map((course, index) => {
                                                                                const is_undergraduate = course.DurationType.includes('Year') ? 'undergraduate' : 'postgraduate';
                                                                                const is_ug = course.DurationType.includes('Year') ? 'Undergraduate' : 'Postgraduate';

                                                                                return <li key={index} className="result">
                                                                                    <Link to={`/admission/${is_undergraduate}/${course.Slug}`}>
                                                                                        {course.Description} <br />
                                                                                        {is_ug}
                                                                                    </Link>
                                                                                </li>
                                                                            })
                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                }

                {
                    props.staffList.length > 0 &&
                    <div className="container-fluid layout col1">
                        <div className="row">
                            <div className="layout-col col1 layout-12 col-sm-12">

                                <div className="layout-blocks-ucws-profile-list container-fluid roundable block profile-list views-block keyword-filters dropdown-filters">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row header">
                                                <div className="col-sm-12">
                                                    <div className="equal-height">
                                                        <div className="text">
                                                            <h2 style={{ color: `${facultyDetail[0].FacultyColor}` }}>Faculty Staff Directory</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row divider">
                                                <div className="col-sm-12">
                                                    <hr />
                                                </div>
                                            </div>
                                            <div className="row profile view-output hide_profile_photos hide_research_interests hide_unit_department">

                                                <div className="view-output-form-results"
                                                    data-once="ajax-pager">
                                                    <div className="row filters">
                                                        <div className="col-sm-12">
                                                            <form className="views-exposed-form bef-exposed-form">
                                                                <div className="js-form-item form-item js-form-type-textfield form-item-search-api-fulltext js-form-item-search-api-fulltext">
                                                                    <label htmlFor="edit-search-api-fulltext">Keywords</label>
                                                                    <input className="primary-keyword form-text"
                                                                        type="text" id="search" onChange={handleSearch}
                                                                        maxLength="128" />
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>

                                                    <div className="row divider">
                                                        <div className="col-sm-12">
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    <div className="row search-bubbles"><p className="title">Results for:</p>
                                                    </div>

                                                    <div className="profile-view-container">
                                                        <ol className="profile-items-list no-bullet-display">
                                                            {
                                                                facultyStaff.length > 0 ?
                                                                    facultyStaff.map((staff, index) => {
                                                                        return <li key={index} className="profile">
                                                                            <p className="title">
                                                                                <Link to={`/academics/staff/${staff.Slug}`}>
                                                                                    {`${staff.title !== null ? staff.title : ""} ${staff.staff_name}`}</Link>
                                                                            </p>
                                                                            <div className="roles">
                                                                                <div className="role">
                                                                                    <div className="job-title">{staff.designation}</div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    }) : <div className="col-md-12">
                                                                        No Record Found
                                                                    </div>
                                                            }


                                                        </ol>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                }


                {
                    props.newsListData.length > 0 &&
                    <NewsSection color={facultyDetail[0].FacultyColor}
                        news={FacultyNews} />
                }

                {
                    props.eventsData.length > 0 &&
                    <EventSection
                        color={facultyDetail[0].FacultyColor}
                        page="home"
                        events={FacultyEvents} />
                }



                <div className="container-fluid layout col1">
                    <div className="row">
                        <div className="layout-col col1 layout-12 col-sm-12">

                            <div className="layout-blocks-ucws-profile-list container-fluid roundable block profile-list views-block keyword-filters dropdown-filters">
                                <div className="row">
                                    <div className="container">
                                        <div className="row header">
                                            <div className="col-sm-12">
                                                <div className="equal-height">
                                                    <div className="text">
                                                        <h2 style={{ color: `${facultyDetail[0].FacultyColor}` }}>Alumni</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row divider">
                                            <div className="col-sm-12">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="row profile view-output hide_profile_photos hide_research_interests hide_unit_department">

                                            <div className="view-output-form-results" data-once="ajax-pager">
                                                <div className="col-md-3">
                                                    {
                                                        alumniSlider !== undefined &&
                                                        <div>
                                                            <img loading="lazy" className="mySlides w3-animate-fading2" style={{ maxWidth: '100%', borderRadius: '10px' }}
                                                                id="PCSlider"
                                                                src={alumniSlider.ImagePath !== undefined ?
                                                                    alumniSlider.ImagePath.includes("simplefileupload") ? alumniSlider.ImagePath : `${serverLink}public/uploads/alumni/${alumniSlider.ImagePath}` : ''} alt="" />
                                                        </div>
                                                    }

                                                </div>
                                                {
                                                    alumniSlider !== undefined &&
                                                    <div className="col-md-9">
                                                        <div className="w3-content w3-section" style={{ maxWidth: "100%", fontSize: '18px' }} >
                                                            <h4 className="text-center"><i>{alumniSlider.Title}</i></h4>
                                                            <blockquote dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${alumniSlider.Description} <br/> - <b> ${alumniSlider.StudentName}</b>`) }} style={{ fontSize: 18 }} />
                                                        </div>
                                                    </div>
                                                }

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>



                <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                    <div className="row">
                        <div className="layout-col col1 layout-12 col-sm-12">
                            <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                <div className="row">
                                    <div className="container text-justify" >
                                        <div className="row header">
                                            <div className="col-sm-12" >
                                                <div className="row" style={{ backgroundColor: `${facultyDetail[0].FacultyColor}`, color: "#fff", transition: "background-color 500ms ease", borderRadius: '10px' }}>
                                                    <div className="container">
                                                        <div className="row cta-wrapper" style={{ marginBottom: '20px' }} >
                                                            <div className="col-md-6">
                                                                <div className="cta-content text-start" >
                                                                    <h2>Contact Us</h2>
                                                                    <div className="btn-wrapper">
                                                                        <a className="btn btn-default btn-cta btn-ghost" href={`tel:${facultyDetail[0].FacultyPhone}`} role="button"><i className="fa fa-phone" /></a>
                                                                        <a className="btn btn-default btn-cta btn-ghost" href={`mailto:${facultyDetail[0].FacultyEmail}`} role="button"><i className="fa fa-envelope-o" /></a>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                {
                                                                    facultyDetail[0].FacultyAddress !== null || facultyDetail[0].FacultyAddress !== '' &&
                                                                    <div style={{ float: 'right', textAlign: 'right' }}>
                                                                        <h2>Address</h2>
                                                                        <div >
                                                                            <address> {facultyDetail[0].FacultyAddress !== null && facultyDetail[0].FacultyAddress.split(",")[0]},</address>
                                                                            <address> {facultyDetail[0].FacultyAddress !== null && facultyDetail[0].FacultyAddress.split(",")[1]},</address>
                                                                            <address> Baba Ahmed University, Kano</address>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
}

const mapStateToProps = (state) => {
    return {
        facultyList: state.BazeFacultyListDetails,
        facultyData: state.BazeFacultyDetails,
        newsListData: state.BazeNewsDetails,
        eventsData: state.BazeEventDetails,
        courseList: state.BazeCourseListDetails,
        staffList: state.BazeStaffListDetails,
        alumniFeedback: state.BazeAlumniFeedbackDetails
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setOnFacultyListDetails: (p) => {
            dispatch(setFacultyListDetails(p));
        },
        setOnFacultyDataDetails: (p) => {
            dispatch(setFacultyDetails(p))
        },
        setOnNewsSingleDetails: (p) => {
            dispatch(setNewsPageDetails(p));
        },
        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
        setOnEventDetails: (p) => {
            dispatch(setEventsDetails(p));
        },
        setOnStaffListDetails: (p) => {
            dispatch(setStaffListDetails(p));
        },
        setOnAlumniFeedbackDetails: (p) => {
            dispatch(setAlumniFeedbackDetails(p));
        }
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Faculty)
