import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import BabaAhmedSquare from "../../../images/faculties/bau_banner.png";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import {
    setManagementListDetails
} from "../../../actions/setactiondetailsection";
import "./leadership.css";
import { connect } from "react-redux";
import HRLine from "../../utils/hrline";
import AlertDialog from "../../DialogBoxes/alert/alertdialog";
import { Link } from "react-router-dom";
import * as DOMPurify from 'dompurify';
import { isMobile } from "react-device-detect";

class LeadershipComponent extends Component {
    constructor(props) {
        super(props);
        let board_members = this.props.leadershipList.length > 0 && this.props.leadershipList.filter(member => member.Category === this.props.category);
        this.state = {
            leadership: this.props.leadershipList.length > 0 && this.props.leadershipList !== "" ? board_members : [],
            selectedImage: "",
            selectedTitle: "",
            selectedDescription: "",
            openAlertDialog: false,
            isCloseAlertDialog: true
        }
    }

    componentDidMount() {
        this.getLeadership();
    }

    async getLeadership() {
        await axios.get(`${serverLink}leadership/list/active`)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    this.props.setOnLeadershipDetails(data);
                    let board_members = data.filter(member => member.Category === this.props.category);
                    this.setState({ leadership: board_members })
                }
            })
            .catch(err => {
                console.log('NETWORK ERROR FETCHING ADMISSION DATELINE', err)
            });
    }

    openAlertDialog = (member) => {
        this.setState({
            selectedImage: member.ImagePath,
            selectedTitle: `${member.Title} ${member.FullName}`,
            selectedDescription: member.Description,
            openAlertDialog: true
        })
    }

    closeAlertDialog = () => {
        this.setState({
            openAlertDialog: false,
            selectedImage: "",
            selectedTitle: "",
            selectedDescription: ""
        })
    }

    render() {
        let dynamic_col = [];
        const leadership = this.state.leadership.length - 1;
        switch (leadership) {
            case 1:
                dynamic_col = [12]
                break;
            case 2:
                dynamic_col = [6, 6]
                break;
            case 3:
                dynamic_col = [4, 4, 4]
                break;
            case 4:
                dynamic_col = [6, 6, 6, 6]
                break;
            case 5:
                dynamic_col = [4, 4, 4, 4, 4]
                break;
            case 6:
                dynamic_col = [4, 4, 4, 4, 4, 4]
                break;
            case 7:
                dynamic_col = [4, 4, 4, 3, 3, 3, 3]
                break;
            case 8:
                dynamic_col = [6, 6, 4, 4, 4, 4, 4, 4]
                break;
            case 9:
                dynamic_col = [4, 4, 4, 4, 4, 4, 4, 4, 4]
                break;
            case 10:
                dynamic_col = [4, 4, 4, 4, 4, 4, 3, 3, 3, 3, 3]
                break;
            default:
                dynamic_col = [4]
                break;
        }

        return <>
            <Banner image={BabaAhmedSquare} title={this.props.title} caption={this.props.caption} />

            <AlertDialog
                openDialog={this.state.openAlertDialog}
                title={this.state.selectedTitle}
                isFullWidth={false}
                onSetOpenDialog={this.closeAlertDialog}
                isCloseBtnAppear={this.state.isCloseAlertDialog}
            >
                <div className="text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.selectedDescription) }} />

            </AlertDialog>

            <div className="container-fluid layout col1" style={{ margin: "20px" }}>

                {
                    this.state.leadership.length > 0 &&
                    <div className="container-fluid layout col2-50-50">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="layout-col col2-50 layout-6 col-md-6">

                                        <div className="layout-blocks-ucws-image container-fluid roundable block image">
                                            <div className="row">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="image-wrapper">
                                                                <div>
                                                                    <img
                                                                        style={{ borderRadius: '10px', maxHeight: isMobile ? "200px"  : "400px" }}
                                                                        className="img-responsive-top"
                                                                        loading="lazy"
                                                                        src={this.state.leadership[0].ImagePath !== undefined
                                                                            && this.state.leadership[0].ImagePath.includes("simplefileupload") ?
                                                                            this.state.leadership[0].ImagePath
                                                                            : `${serverLink}public/uploads/leaderships_uploads/${this.state.leadership[0].ImagePath}`}
                                                                        alt={`${this.state.leadership[0].Title} ${this.state.leadership[0].FullName}`}
                                                                        title={`${this.state.leadership[0].Title} ${this.state.leadership[0].FullName}`}
                                                                        typeof="foaf:Image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="layout-col col2-50 layout-6 col-md-6">

                                        <div className="layout-blocks-ucws-text container-fluid roundable block text">
                                            <div className="row">
                                                <div className="container">
                                                    <div className="row left">
                                                        <div className="col-sm-12 one-col">
                                                            <h3>{`${this.state.leadership[0].Title} ${this.state.leadership[0].FullName}`}</h3>

                                                            <p className="text-wrap"><strong>{this.state.leadership[0].Designation}</strong></p>

                                                            <div className="text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.leadership[0].Description) }} />
                                                            {/* {
                                                                this.props.category !== 'Board' &&
                                                                <Link to={`/staff/${this.state.leadership[0].Slug}`}>
                                                                    Read more
                                                                </Link>
                                                            } */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                                <HRLine />
                            </div>
                        </div>
                    </div>
                }

                <div className="container-fluid layout col3-33-33-33">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                {
                                    this.state.leadership.length > 0 &&
                                    this.state.leadership.sort(function (a, b) { return a.Position - b.Position }).map((member, index) => {
                                        if (index !== 0) {
                                            return <div key={`board-${index}`} className={`layout-col col3-33 layout-4 col-sm-${dynamic_col.length > 1 ? dynamic_col[index - 1] : dynamic_col[0]}`}>
                                                <div className="layout-blocks-ucws-image container-fluid roundable image" >
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="image-wrapper" >
                                                                <div>
                                                                    <img
                                                                        style={{ borderRadius: '10px', maxHeight: isMobile ? "200px"  : "400px"}}
                                                                        className="img-responsive"
                                                                        loading="lazy"
                                                                        src={member.ImagePath !== null && member.ImagePath.includes("simplefileupload") ? member.ImagePath : `${serverLink}public/uploads/leaderships_uploads/${member.ImagePath !== null ? member.ImagePath : 'placeit.jpg'}`}
                                                                        alt={`${member.Title} ${member.FullName}`}
                                                                        title={`${member.Title} ${member.FullName}`}
                                                                        typeof="foaf:Image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="layout-blocks-ucws-text container-fluid roundable block text" >
                                                    <div className="row">
                                                        <div className="container">
                                                            <div className="row text-center" >
                                                               <p></p>
                                                                <div className="col-sm-12 one-col" >
                                                                    {
                                                                        this.props.category !== 'Board' ?
                                                                            <Link to={`/academics/staff/${member.Slug}`} style={{ backgroundImage: "none" }}  >
                                                                                {
                                                                                    this.props.category === "Senate" ?
                                                                                        <b style={{ fontSize: "16px" }}>{`${member.Title} ${member.FullName}`}</b>
                                                                                        :
                                                                                        <h3>{`${member.Title} ${member.FullName}`}</h3>
                                                                                }
                                                                            </Link> :
                                                                            <h3>{`${member.Title} ${member.FullName}`}</h3>
                                                                    }



                                                                    <p className="text-wrap"><small>{member.Designation}</small></p>
                                                                    {
                                                                        this.props.category !== 'Senate' &&
                                                                        <>
                                                                            {
                                                                                member.Description !== "" && member.Description !== null ?
                                                                                    <p><span className="member_learn_more" onClick={() => this.openAlertDialog(member)}>Learn more</span></p>
                                                                                    : ""
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* {
                    this.state.leadership.length > 0 &&
                    <div className="container-fluid layout col3-33-33-33">
                        <div className="row">
                            <div className="container">
                                <div className="row">

                                    <div className="layout-col col3-33 layout-4 col-sm-4 col-sm-offset-4">


                                        <div className="layout-blocks-ucws-image container-fluid roundable block image">
                                            <div className="row">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="image-wrapper">
                                                                <div>
                                                                    <img
                                                                        className="img-responsive img-circle"
                                                                        loading="lazy"
                                                                        src={`${serverLink}public/uploads/leaderships_uploads/${this.state.leadership[this.state.leadership.length - 1].ImagePath}`}
                                                                        alt={`${this.state.leadership[this.state.leadership.length - 1].Title} ${this.state.leadership[this.state.leadership.length - 1].FullName}`}
                                                                        title={`${this.state.leadership[this.state.leadership.length - 1].Title} ${this.state.leadership[this.state.leadership.length - 1].FullName}`}
                                                                        typeof="foaf:Image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="layout-blocks-ucws-text container-fluid roundable block text">
                                            <div className="row">
                                                <div className="container">
                                                    <div className="row text-center">
                                                        <div className="col-sm-12 one-col">
                                                            <Link to={`/academics/staff/${this.state.leadership[this.state.leadership.length - 1].Slug}`} style={{ backgroundImage: "none" }}>
                                                                <h3><strong>{`${this.state.leadership[this.state.leadership.length - 1].Title} ${this.state.leadership[this.state.leadership.length - 1].FullName}`}</strong></h3>
                                                            </Link>


                                                            <p>{this.state.leadership[this.state.leadership.length - 1].Designation}</p>

                                                            {
                                                                this.state.leadership[this.state.leadership.length - 1].Description !== "" && this.state.leadership[this.state.leadership.length - 1].Description !== null ?
                                                                    <p><span className="member_learn_more" onClick={() => this.openAlertDialog(this.state.leadership[this.state.leadership.length - 1])}>Learn more</span></p>
                                                                    : ""
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="layout-col col3-33 layout-4 col-sm-4">

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                } */}


            </div>


        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        leadershipList: state.BazeManagementListDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLeadershipDetails: (p) => {
            dispatch(setManagementListDetails(p));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadershipComponent);

