import React from 'react'
import Banner from '../../utils/banner/banner'
import { isMobile } from 'react-device-detect'
import HRLine from '../../utils/hrline'

export default function FoundationAndInterDisciplinary() {

    const centerStaff = [
        {
            name: "Dr. Taofeek Owoseni",
            rank: "Lecturer 1",
            area: "Entrepreneurship",
            highest: "PhD",
            profile: "https://bazeuniversity.edu.ng/academics/staff/taofeek-adejare-owoseni"
        },
        {
            name: "Dr. Michael Ewuwu",
            rank: "Lecturer 1",
            area: "Entrepreneurship",
            highest: "PhD",
            profile: ""
        },
        {
            name: "Dr Murtaddha Yusuf",
            rank: "Lecturer 1",
            area: "Entrepreneurship",
            highest: "PhD",
            profile: "https://bazeuniversity.edu.ng/academics/staff/yusuf-murtadha"
        },
        {
            name: "Dr Chimezie Nwosu",
            rank: "Lecturer 2",
            area: "Entrepreneurship",
            highest: "PhD",
            profile: ""
        },
        {
            name: "Mr. Rhema Oguche",
            rank: "Lecturer 2",
            area: "English & Literature",
            highest: "Masters",
            profile: "https://bazeuniversity.edu.ng/academics/staff/nwosu--chimezie"
        },
        {
            name: "Mrs Bose Omojuyigbe",
            rank: "Assistant Lecturer",
            area: "English & Literature",
            highest: "Masters",
            profile: "https://bazeuniversity.edu.ng/academics/staff/abosede--omojuyigbe"
        },
        {
            name: "Ms Mercy Johnson",
            rank: "Lecturer 2",
            area: "History",
            highest: "Masters",
            profile: "https://bazeuniversity.edu.ng/academics/staff/johnson--mercy"
        },
        {
            name: "Mr Abdulmumin Saad",
            rank: "Assistant Lecturer",
            area: "International/Political Science",
            highest: "Masters",
            profile: "https://bazeuniversity.edu.ng/academics/staff/saad--abdulmunin"
        },
        {
            name: "Mr. Patrick Olofin",
            rank: "Assistant Lecturer",
            area: "Environmental",
            highest: "Masters",
            profile: "https://bazeuniversity.edu.ng/academics/staff/olofin--patrick"
        },
        {
            name: "Ms Shulammite Paul",
            rank: "Lecturer 2",
            area: "Entrepreneurship",
            highest: "Masters",
            profile: "https://bazeuniversity.edu.ng/academics/staff/shulammite--paul"
        },
        {
            name: "Mr. Bula Andrew",
            rank: "Assistant Lecturer",
            area: "English & Lit",
            highest: "Masters",
            profile: "https://bazeuniversity.edu.ng/academics/staff/andrew--bula"
        },
        {
            name: "Mr. Mamman Aliyu",
            rank: "Lecturer 1",
            area: "Comparative Education",
            highest: "Masters",
            profile: "https://bazeuniversity.edu.ng/academics/staff/aliyu--mamman"
        },
        {
            name: "Mr. Azifan Firmin",
            rank: "Lecturer 1",
            area: "French",
            highest: "Masters",
            profile: "https://bazeuniversity.edu.ng/academics/staff/firmin--azifan"
        },
    ]
    return (
        <div>
            <Banner image={require("../images/c_foundation.png")} title="CCECC" caption="" disableTitle />

            <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right mt-5" style={{ marginTop: "30px" }}>
                <div className="row">
                    <div className="col-sm-12 ">
                        <div className={isMobile ? "col-md-12" : "col-md-9"}>
                            <p>
                                The Centre for Foundation and Interdisciplinary Studies (CFIS) at Baba Ahmed University came out of restructuring of the General Studies Department into a full-fledged Directorate-cum-Faculty as well as Centre. It was established in the year 2018.<br />
                                The CFIS offers the following five programmes:
                                <ul>
                                    <li>
                                        <strong>General Studies:</strong> This is a requirement of NUC for fulfilment of the General studies curriculum.
                                    </li>
                                    <li>
                                        <strong>Entrepreneurship Development Centre (EDC):</strong> his programme provides entrepreneurship education, skills acquisition and the development of an entrepreneurial mindset for students of Baba Ahmed University through its entrepreneurship-oriented activities.
                                    </li>
                                    <li>
                                        <strong>Foundation Programmes:</strong> This programme serves as a bridge for candidates seeking admission into Nigerian Universities through the IJMB, UTME and WAEC. It provides coaching for interested candidates.
                                    </li>
                                    <li>
                                        <strong>Short Courses:</strong> In this programme, Baba Ahmed University collaborates with highly reputable professional organisations in providing non-NUC accredited Short Courses for the award of diplomas and certificates in diverse areas. The programme targets the general public.
                                    </li>
                                    <li>
                                        <strong>SIWES:</strong> This is an aspect of the faculty that caters for the industrial attachment of Baba Ahmed University students.
                                    </li>
                                </ul>

                                <br />
                                The centre belongs to multiple academic disciplines which are: Engineering, Management, Computing and Applied Sciences, Law, and Environmental Sciences. The centre has 12 Academic Staff and 2 Administrative staff. Lead by Professor Emeka E. Ene who is a professor of financial economics and accounting.
                                <br />
                                Some of the certification courses conducted in the centre include Digital Entrepreneurship in partnership with the Embassy of Sweden. In the course the following were taught; Digital skills for entrepreneurship, Scrum master skills (project management, business analysis skills (research and data analysis), UIUX user interface and user experience, Digital marketing - social media, SEOs, content creation and branding, Graphic design and creative design.
                                <br />
                            </p>
                        </div>
                        {
                            !isMobile &&
                            <div className="col-md-3">
                                <img
                                    style={{ borderRadius: '10px', maxHeight: "300px" }}
                                    className="img-responsive-top"
                                    loading="lazy"
                                    src={"https://api.bazeuniversity.edu.ng:5001/public/uploads/leaderships_uploads/DIRCFIS.png"}
                                    alt={'emeka'}
                                    title={'emeka'}
                                    typeof="foaf:Image" />
                                <div className='row text-center'>
                                    <h4>Prof. Emeka Emmanuel Ene</h4>
                                    <h5>Director Center for Foundation & Interdisciplinary Studies</h5>
                                </div>
                            </div>
                        }
                        <div className='col-md-12'>
                            <br />
                            <iframe width="100%" height="450"
                                src="https://www.youtube.com/embed/_g6rb4lNY-E">
                            </iframe>
                        </div>

                        <div className="col-lg-12 mt-5" style={{ marginTop: "30px" }}>
                            <div className="layout-col col1 layout-12 col-sm-12">
                                <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                    <h3 className='text-left'>Other Staff of the Centre</h3>

                                    <div className="table-responsive" style={{ marginTop: "30px" }}>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <td>Name</td>
                                                    <td>Rank</td>
                                                    <td>Highest Qualification</td>
                                                    <td>Area of Specialization</td>
                                                    <td>Profile Link</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    centerStaff.length > 0 &&
                                                    centerStaff.map((x, y) => {
                                                        return (
                                                            <tr>
                                                                <td>{x.name}</td>
                                                                <td>{x.rank}</td>
                                                                <td>{x.highest}</td>
                                                                <td>{x.area}</td>
                                                                <td><a href={x.profile} target='_blank'>{x.profile}</a></td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>






                        <div className='col-md-12' style={{ marginTop: "40px" }}>
                            <div className='row'>
                                <div className='col-md-4 text-justify'>
                                    <p>
                                        Some of the certification courses conducted in the centre include Digital Entrepreneurship in partnership with the Embassy of Sweden. In the course the following were taught; Digital skills for entrepreneurship, Scrum master skills (project management, business analysis skills (research and data analysis), UIUX user interface and user experience, Digital marketing - social media, SEOs, content creation and branding, Graphic design and creative design.

                                    </p>
                                </div>
                                <div className='col-md-8'>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-10 text-center">
                                                    <h3><i className='fa fa-envelope-o' />&nbsp;Email</h3>
                                                    <p>emeka.ene@bazeuniversity.edu.ng</p>
                                                </div>
                                            </div>
                                            <HRLine />
                                            <br />
                                            <div className="row">
                                                <div className="col-md-10 text-center">
                                                    <h3><i className='fa fa-mobile' />&nbsp;Telephone</h3>
                                                    <p>+2348133769658 </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-10 text-center">
                                                    <h3><i className='fa fa-link' />&nbsp;Website</h3>
                                                    <a href='/centre-for-foundation-and-interdisciplinary-studies'>tinyurl.com</a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
